import { get, post, patch, getpagination, getAllResponse } from "../web.request";

export const getBudgetFacebookList = async (data, id) => {
    return await getAllResponse(`/budget_calculator/facebook_budget/${id}${data}`);
}

export const getBudgetInstaList = async (data, id) => {
    return await getAllResponse(`/budget_calculator/instagram_budget/${id}${data}`);
}

export const getBudgetgoogleSEMList = async (data, id) => {
    return await getAllResponse(`/budget_calculator/SEM_budget/${id}${data}`);
}

export const getBudgetgoogleGDMList = async (data, id) => {
    return await getAllResponse(`/budget_calculator/GDN_budget/${id}${data}`);
}

export const getBudgetTikTokList = async (data, id) => {
    return await getAllResponse(`/budget_calculator/tiktok_budget/${id}${data}`);
}

export const getBudgetLinkedInList = async (data, id) => {
    return await getAllResponse(`/budget_calculator/linkedin_budget/${id}${data}`);
}

export const getBudgetgoogleYouTubeList = async (data, id) => {
    return await getAllResponse(`/budget_calculator/youtube_budget/${id}${data}`);
}

export const saveBudget = async (data, id) => {
    return await patch(`/budget_calculator/update_budget/${id}`, data);
}