import { get, post, postToken, reload } from "../web.request";


export const login = async (data) => {
    return await postToken(`/users/login`, data);
}

export const loadUser = async (data) => {
    return await reload(`/users/login_info`, data);
}
