import { get, post, patch, getpagination, getAllResponse } from "../web.request";


export const uploadLeadFile = async (data) => {
    return await post(`/leads/leads_data/`, data);
}

export const leadList = async (id, qry) => {
    return await getAllResponse(`/leads/leads_data/${id}${qry}`);
}

export const updateStatus = async (id, data) => {
    return await patch(`/leads/leads_data/${id}/`, data);
}
