import React, { useState, useEffect } from 'react'
import PageHeading from '../../../components/common/PageHeading'
import { Grid, Stack, Typography } from '@mui/material';
import TableSort from '../../../components/common/Table/TableSort';
import FacebookAds from './facebook/FacebookAds';
import DateRange from '../../../components/common/Table/DateRange';
import moment from 'moment';
import { facebookAdsData, tiktokAdsData, linkedInAdsData, instaAdsData, googleGraphSEM, googleGraphGDN, googleGraphYouTube } from '../../../Service/facebook.service';
import { useSelector } from 'react-redux';
import Notification from '../../../components/common/Notification/Notification';
// import FacebookAds from '../../../pages/admin/adschannel/facebook/FacebookAds';
import InstagramAds from '../../../pages/admin/adschannel/instagram/InstagramAds';
import GoogleAds from '../../../pages/admin/adschannel/google/GoogleAds';
import TiktokAds from '../../../pages/admin/adschannel/tiktok/TiktokAds';
import LinkedinAds from '../../../pages/admin/adschannel/linkedin/LinkedinAds';
import { facebookAdsDataList, tiktokAdsDataList, likedInTableListData, instagramTableListData, googleTableListDataSEM, googleTableListDataGDN, googleTableListDataYouTube } from '../../../Service/facebook.service';


const AdsChannels = () => {
    const [lebel1, setLebel1] = useState(['']);
    const [lebel2, setLebel2] = useState(['']);
    const [xLabels, setXLebel] = useState(['']);
    const [show, setShow] = useState({
        show: false,
        type: '',
        message: ''
    });

    const [dataFromChild, setDataFromChild] = useState('Google');
    const [fromDate, setFromDate] = useState(moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"));
    const [days, setDays] = useState(7);
    const [toDate, setToDate] = useState(moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD"));
    const [weekDayStartName, setWeekDayStartName] = useState('');
    const [monthStartName, setMonthStartName] = useState('');
    const [weekDayEndName, setWeekDayEndName] = useState('');
    const [monthEndName, setMonthEndName] = useState('');
    const [listData, setListData] = useState([]);
    const [tiktokListData, setTiktokListData] = useState([]);

    const [listDataLinkedIn, setListDataLinkedIn] = useState([]);
    const [listDataInsta, setListDataInsta] = useState([]);
    const [listDataGoogle, setListDataGoogle] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [pagePerSize, setPagePerSize] = useState(5);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const { user } = useSelector((state) => state?.root?.auth);
    const [search, setSearch] = useState('');
    const [googleChannel, setGoogleChannel] = React.useState(0);
    const [shortKey, setShortKey] = useState("");
    const [sortBy, setSortBy] = useState(1);

    const handleDataFromChild = (data) => {
        setDataFromChild(data);
    };


    useEffect(() => {
        if (dataFromChild === "Facebook" && user && user?.id) {
            let querys = `?start_date=${moment(fromDate).format('YYYY-MM-DD')}&end_date=${moment(toDate).format('YYYY-MM-DD')}&client_id=${user?.id}&search=${search}&page_size=${pagePerSize}&page=${page}&filter_field=${shortKey}&filter_type=${sortBy}`;
            getFacebookAdsDatas(querys);
        } else if (dataFromChild === "LinkedIn" && user && user?.id) {
            let querys = `?start_date=${moment(fromDate).format('YYYY-MM-DD')}&end_date=${moment(toDate).format('YYYY-MM-DD')}&client_id=${user?.id}&search=${search}&page_size=${pagePerSize}&page=${page}&filter_field=${shortKey}&filter_type=${sortBy}`;
            getLinkedInTableData(querys);
        } else if (dataFromChild === "Instagram" && user && user?.id) {
            let querys = `?start_date=${moment(fromDate).format('YYYY-MM-DD')}&end_date=${moment(toDate).format('YYYY-MM-DD')}&client_id=${user?.id}&search=${search}&page_size=${pagePerSize}&page=${page}&filter_field=${shortKey}&filter_type=${sortBy}`;
            getInstagramTableData(querys);
        } else if (dataFromChild === "Google" && user && user?.id) {
            let querys = `?page=${page}&page_size=${pagePerSize}&start_date=${moment(fromDate).format('YYYY-MM-DD')}&end_date=${moment(toDate).format('YYYY-MM-DD')}&client_id=${user?.id}&search=${search}&filter_field=${shortKey}&filter_type=${sortBy}`;
            getGoogleTableData(querys);
        }
        else if (dataFromChild == "TikTok" && user && user?.id) {
            let querys = `?start_date=${moment(fromDate).format('YYYY-MM-DD')}&end_date=${moment(toDate).format('YYYY-MM-DD')}&client_id=${user?.id}&search=${search}&page_size=${pagePerSize}&page=${page}&search=${search}&filter_field=${shortKey}&filter_type=${sortBy}`;
            getTiktokAdsDatas(querys);
        }

    }, [fromDate, toDate, user, search, dataFromChild, page, googleChannel])

    useEffect(() => {
        // if (dataFromChild === "Facebook" && user && user?.id) {
        //     let query = `?start_date=${moment(fromDate).format('YYYY-MM-DD')}&end_date=${moment(toDate).format('YYYY-MM-DD')}&client_id=${user?.id}`;
        //     getFacebookAdsData(query);
        // } else if (dataFromChild === "LinkedIn" && user && user?.id) {
        //     let query = `?start_date=${moment(fromDate).format('YYYY-MM-DD')}&end_date=${moment(toDate).format('YYYY-MM-DD')}&client_id=${user?.id}`;
        //     getLinkedInGraphdata(query);
        // } else if (dataFromChild === "Instagram" && user && user?.id) {
        //     let query = `?start_date=${moment(fromDate).format('YYYY-MM-DD')}&end_date=${moment(toDate).format('YYYY-MM-DD')}&client_id=${user?.id}`;
        //     getInstaAdsData(query);
        // }
        // else if (dataFromChild === "Google" && user && user?.id) {
        //     let query = `?start_date=${moment(fromDate).format('YYYY-MM-DD')}&end_date=${moment(toDate).format('YYYY-MM-DD')}&client_id=${user?.id}`;
        //     getGoogleGraphData(query);
        // }
        // else if (dataFromChild === "Google" && user && user?.id) {
        //     let query = `?start_date=${moment(fromDate).format('YYYY-MM-DD')}&end_date=${moment(toDate).format('YYYY-MM-DD')}&client_id=${user?.id}`;
        //     getGoogleGraphData(query);
        // }
        // else if (dataFromChild == "TikTok" && user && user?.id) {
        //     let query = `?start_date=${moment(fromDate).format('YYYY-MM-DD')}&end_date=${moment(toDate).format('YYYY-MM-DD')}&client_id=${user?.id}`;
        //     getTiktokAdsData(query);
        // }
        // else {
        //     setLebel1(['']);
        //     setLebel2(['']);
        //     setXLebel(['']);
        //     // setShow({
        //     //     show: true,
        //     //     type: 'error',
        //     //     message: 'Please Select Client'
        //     // })
        //     // let query = `?start_date=${moment(fromDate).format('YYYY-MM-DD')}&end_date=${moment(toDate).format('YYYY-MM-DD')}&client_id=${client?.clientId}`;
        //     // getFacebookAdsData(query);
        // }
    }, [fromDate, toDate, user, search, dataFromChild, googleChannel])

    const generateXLabels = (startDayOfWeek) => {
        const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const startIndex = weekdays.indexOf(startDayOfWeek);

        return [
            ...weekdays.slice(startIndex), // Add days starting from the startDayOfWeek
            ...weekdays.slice(0, startIndex) // Add days before the startDayOfWeek
        ];
    };

    const generateMonthlyDateData = (startDate, endDate) => {
        const data = [];
        let currentDate = moment(startDate);
        while (currentDate.isSameOrBefore(endDate)) {
            data.push(currentDate.format("DD/MM"));
            currentDate.add(1, "days");
        }
        return data;
    };

    const generateMonthlyData = (startDate, endDate) => {
        const data = [];
        let currentDate = moment(startDate).startOf("month");

        while (currentDate.isSameOrBefore(endDate, "month")) {
            data.push(currentDate.format("MMM"));
            currentDate.add(1, "months");
        }

        return data;
    };
    const generateDataCount = (days) => {
        days = days + 1;
        if (days <= 2) {
            return 2;
        }
        if (days <= 3) {
            return 3;
        }
        else if (days <= 7) {
            return 7;
        }
        else if (days == 30) {
            return 30;
        }
        else if (days <= 31) {
            return 31;
        }
        else if (days <= 365) {
            return 12;
        } else {
            return days;
        }
    }

    const generateQuarterlyData = (startDate, days) => {
        const data = [];
        let currentMonth = startDate.month();
        const startYear = startDate.year();

        while (days > 0) {
            const quarterStart = moment().month(currentMonth).year(startYear).startOf("month");
            const quarterEnd = moment().month(currentMonth + 2).year(startYear).endOf("month");
            const quarterDays = Math.min(days, quarterEnd.diff(quarterStart, "days") + 1);

            data.push({
                start: quarterStart.format("MMM"),
                end: quarterStart.add(quarterDays - 1, "days").format("MMM"),
            });

            currentMonth += 3;
            if (currentMonth >= 12) {
                currentMonth %= 12;
                startYear++;
            }

            days -= quarterDays;
        }

        return data;
    };

    const getLinkedInGraphdata = async (query) => {
        const res = await linkedInAdsData(query);
        if (days < 2) {
            setXLebel([weekDayStartName, weekDayEndName]);
        }

        else if (days < 7) {
            const data = generateXLabels(weekDayStartName);
            setXLebel(data);
        }
        else if (days < 31) {

            const data = generateMonthlyDateData(fromDate, toDate);
            setXLebel(data);
        }
        else if (days < 365) {
            const data = generateMonthlyData(fromDate, toDate);
            setXLebel(data);
        }

        if (res.status) {
            const extractedImpressions = res?.data?.map((item) => item?.impressions != null ? item?.impressions : 0);

            const initialItems = Math.min(generateDataCount(days), extractedImpressions.length); // Get the minimum of 20 or the available items
            const additionalItems = generateDataCount(days) - initialItems; // Calculate how many additional items are needed

            const additionalValues = Array.from({ length: additionalItems }, () => 0); // Create an array of additional values (10,000)

            const finalLebel1 = extractedImpressions.slice(0, initialItems).concat(additionalValues);
            setLebel1(finalLebel1);

            const extractedClick = res?.data?.map((item) => item?.clicks != null ? item?.clicks : 0);

            const initialItemss = Math.min(generateDataCount(days), extractedClick.length); // Get the minimum of 20 or the available items
            const additionalItemss = generateDataCount(days) - initialItemss; // Calculate how many additional items are needed

            const additionalValuess = Array.from({ length: additionalItemss }, () => 0); // Create an array of additional values (10,000)

            const finalLebel2 = extractedClick.slice(0, initialItemss).concat(additionalValuess);
            setLebel2(finalLebel2);
        } else {
            const extractedImpressions = [0];

            const initialItems = Math.min(generateDataCount(days), extractedImpressions.length); // Get the minimum of 20 or the available items
            const additionalItems = generateDataCount(days) - initialItems; // Calculate how many additional items are needed

            const additionalValues = Array.from({ length: additionalItems }, () => 0); // Create an array of additional values (10,000)

            const finalLebel1 = extractedImpressions.slice(0, initialItems).concat(additionalValues);

            setLebel1(finalLebel1);

            const extractedReach = [0];

            const initialItemss = Math.min(generateDataCount(days), extractedReach.length); // Get the minimum of 20 or the available items
            const additionalItemss = generateDataCount(days) - initialItemss; // Calculate how many additional items are needed

            const additionalValuess = Array.from({ length: additionalItemss }, () => 0); // Create an array of additional values (10,000)

            const finalLebel2 = extractedReach.slice(0, initialItemss).concat(additionalValuess);

            setLebel2(finalLebel2);
        }
    }

    const getFacebookAdsData = async (query) => {
        const res = await facebookAdsData(query);
        if (days < 2) {
            setXLebel([weekDayStartName, weekDayEndName]);
        }

        else if (days < 7) {
            const data = generateXLabels(weekDayStartName);
            setXLebel(data);
        }
        else if (days < 31) {

            const data = generateMonthlyDateData(fromDate, toDate);
            setXLebel(data);
        }
        else if (days < 365) {
            const data = generateMonthlyData(fromDate, toDate);
            setXLebel(data);
        }
        if (res.status) {
            const extractedImpressions = res?.data?.map((item) => item?.impressions != null ? item?.impressions : 0);

            const initialItems = Math.min(generateDataCount(days), extractedImpressions.length); // Get the minimum of 20 or the available items
            const additionalItems = generateDataCount(days) - initialItems; // Calculate how many additional items are needed

            const additionalValues = Array.from({ length: additionalItems }, () => 0); // Create an array of additional values (10,000)

            const finalLebel1 = extractedImpressions.slice(0, initialItems).concat(additionalValues);

            setLebel1(finalLebel1);

            const extractedReach = res?.data?.map((item) => item?.reach != null ? item?.reach : 0);

            const initialItemss = Math.min(generateDataCount(days), extractedReach.length); // Get the minimum of 20 or the available items
            const additionalItemss = generateDataCount(days) - initialItemss; // Calculate how many additional items are needed

            const additionalValuess = Array.from({ length: additionalItemss }, () => 0); // Create an array of additional values (10,000)

            const finalLebel2 = extractedReach.slice(0, initialItemss).concat(additionalValuess);

            setLebel2(finalLebel2);
        }
        else {
            // setDataFromChild('');
            const extractedImpressions = [0];

            const initialItems = Math.min(generateDataCount(days), extractedImpressions.length); // Get the minimum of 20 or the available items
            const additionalItems = generateDataCount(days) - initialItems; // Calculate how many additional items are needed

            const additionalValues = Array.from({ length: additionalItems }, () => 0); // Create an array of additional values (10,000)

            const finalLebel1 = extractedImpressions.slice(0, initialItems).concat(additionalValues);

            setLebel1(finalLebel1);

            const extractedReach = [0];

            const initialItemss = Math.min(generateDataCount(days), extractedReach.length); // Get the minimum of 20 or the available items
            const additionalItemss = generateDataCount(days) - initialItemss; // Calculate how many additional items are needed

            const additionalValuess = Array.from({ length: additionalItemss }, () => 0); // Create an array of additional values (10,000)

            const finalLebel2 = extractedReach.slice(0, initialItemss).concat(additionalValuess);

            setLebel2(finalLebel2);
        }
    }
    const getTiktokAdsData = async (query) => {
        const res = await tiktokAdsData(query);
        if (days < 2) {
            setXLebel([weekDayStartName, weekDayEndName]);
        }

        else if (days < 7) {
            const data = generateXLabels(weekDayStartName);
            setXLebel(data);
        }
        else if (days < 31) {

            const data = generateMonthlyDateData(fromDate, toDate);
            setXLebel(data);
        }
        else if (days < 365) {
            const data = generateMonthlyData(fromDate, toDate);
            setXLebel(data);
        }
        if (res.status) {
            const extractedImpressions = res?.data?.map((item) => item?.impressions != null ? item?.impressions : 0);

            const initialItems = Math.min(generateDataCount(days), extractedImpressions.length); // Get the minimum of 20 or the available items
            const additionalItems = generateDataCount(days) - initialItems; // Calculate how many additional items are needed

            const additionalValues = Array.from({ length: additionalItems }, () => 0); // Create an array of additional values (10,000)

            const finalLebel1 = extractedImpressions.slice(0, initialItems).concat(additionalValues);

            setLebel1(finalLebel1);

            const extractedReach = res?.data?.map((item) => item?.reach != null ? item?.reach : 0);

            const initialItemss = Math.min(generateDataCount(days), extractedReach.length); // Get the minimum of 20 or the available items
            const additionalItemss = generateDataCount(days) - initialItemss; // Calculate how many additional items are needed

            const additionalValuess = Array.from({ length: additionalItemss }, () => 0); // Create an array of additional values (10,000)

            const finalLebel2 = extractedReach.slice(0, initialItemss).concat(additionalValuess);

            setLebel2(finalLebel2);
        }
        else {
            // setDataFromChild('');
            const extractedImpressions = [0];

            const initialItems = Math.min(generateDataCount(days), extractedImpressions.length); // Get the minimum of 20 or the available items
            const additionalItems = generateDataCount(days) - initialItems; // Calculate how many additional items are needed

            const additionalValues = Array.from({ length: additionalItems }, () => 0); // Create an array of additional values (10,000)

            const finalLebel1 = extractedImpressions.slice(0, initialItems).concat(additionalValues);

            setLebel1(finalLebel1);

            const extractedReach = [0];

            const initialItemss = Math.min(generateDataCount(days), extractedReach.length); // Get the minimum of 20 or the available items
            const additionalItemss = generateDataCount(days) - initialItemss; // Calculate how many additional items are needed

            const additionalValuess = Array.from({ length: additionalItemss }, () => 0); // Create an array of additional values (10,000)

            const finalLebel2 = extractedReach.slice(0, initialItemss).concat(additionalValuess);

            setLebel2(finalLebel2);
        }
    }

    const getInstaAdsData = async (query) => {
        const res = await instaAdsData(query);
        if (days < 2) {
            setXLebel([weekDayStartName, weekDayEndName]);
        }

        else if (days < 7) {
            const data = generateXLabels(weekDayStartName);
            setXLebel(data);
        }
        else if (days < 31) {

            const data = generateMonthlyDateData(fromDate, toDate);
            setXLebel(data);
        }
        else if (days < 365) {
            const data = generateMonthlyData(fromDate, toDate);
            setXLebel(data);
        }
        if (res.status) {
            const extractedImpressions = res?.data?.map((item) => item?.impressions != null ? item?.impressions : 0);

            const initialItems = Math.min(generateDataCount(days), extractedImpressions.length); // Get the minimum of 20 or the available items
            const additionalItems = generateDataCount(days) - initialItems; // Calculate how many additional items are needed

            const additionalValues = Array.from({ length: additionalItems }, () => 0); // Create an array of additional values (10,000)

            const finalLebel1 = extractedImpressions.slice(0, initialItems).concat(additionalValues);

            setLebel1(finalLebel1);

            const extractedReach = res?.data?.map((item) => item?.reach != null ? item?.reach : 0);

            const initialItemss = Math.min(generateDataCount(days), extractedReach.length); // Get the minimum of 20 or the available items
            const additionalItemss = generateDataCount(days) - initialItemss; // Calculate how many additional items are needed

            const additionalValuess = Array.from({ length: additionalItemss }, () => 0); // Create an array of additional values (10,000)

            const finalLebel2 = extractedReach.slice(0, initialItemss).concat(additionalValuess);

            setLebel2(finalLebel2);
        }
        else {
            // setDataFromChild('');
            const extractedImpressions = [0];

            const initialItems = Math.min(generateDataCount(days), extractedImpressions.length); // Get the minimum of 20 or the available items
            const additionalItems = generateDataCount(days) - initialItems; // Calculate how many additional items are needed

            const additionalValues = Array.from({ length: additionalItems }, () => 0); // Create an array of additional values (10,000)

            const finalLebel1 = extractedImpressions.slice(0, initialItems).concat(additionalValues);

            setLebel1(finalLebel1);

            const extractedReach = [0];

            const initialItemss = Math.min(generateDataCount(days), extractedReach.length); // Get the minimum of 20 or the available items
            const additionalItemss = generateDataCount(days) - initialItemss; // Calculate how many additional items are needed

            const additionalValuess = Array.from({ length: additionalItemss }, () => 0); // Create an array of additional values (10,000)

            const finalLebel2 = extractedReach.slice(0, initialItemss).concat(additionalValuess);

            setLebel2(finalLebel2);
        }
    }

    const getGoogleGraphData = async (query) => {
        let res;
        if (googleChannel == 1) {
            res = await googleGraphGDN(query);
        } else if (googleChannel == 2) {
            res = await googleGraphYouTube(query);
        } else {
            res = await googleGraphSEM(query);
        }
        if (days < 2) {
            setXLebel([weekDayStartName, weekDayEndName]);
        }

        else if (days < 7) {
            const data = generateXLabels(weekDayStartName);
            setXLebel(data);
        }
        else if (days < 31) {

            const data = generateMonthlyDateData(fromDate, toDate);
            setXLebel(data);
        }
        else if (days < 365) {
            const data = generateMonthlyData(fromDate, toDate);
            setXLebel(data);
        }
        if (res.status) {
            const extractedImpressions = res?.data?.map((item) => item?.impressions != null ? item?.impressions : 0);

            const initialItems = Math.min(generateDataCount(days), extractedImpressions.length); // Get the minimum of 20 or the available items
            const additionalItems = generateDataCount(days) - initialItems; // Calculate how many additional items are needed

            const additionalValues = Array.from({ length: additionalItems }, () => 0); // Create an array of additional values (10,000)

            const finalLebel1 = extractedImpressions.slice(0, initialItems).concat(additionalValues);

            setLebel1(finalLebel1);

            const extractedReach = res?.data?.map((item) => item?.reach != null ? item?.reach : 0);

            const initialItemss = Math.min(generateDataCount(days), extractedReach.length); // Get the minimum of 20 or the available items
            const additionalItemss = generateDataCount(days) - initialItemss; // Calculate how many additional items are needed

            const additionalValuess = Array.from({ length: additionalItemss }, () => 0); // Create an array of additional values (10,000)

            const finalLebel2 = extractedReach.slice(0, initialItemss).concat(additionalValuess);

            setLebel2(finalLebel2);
        }
        else {
            // setDataFromChild('');
            const extractedImpressions = [0];

            const initialItems = Math.min(generateDataCount(days), extractedImpressions.length); // Get the minimum of 20 or the available items
            const additionalItems = generateDataCount(days) - initialItems; // Calculate how many additional items are needed

            const additionalValues = Array.from({ length: additionalItems }, () => 0); // Create an array of additional values (10,000)

            const finalLebel1 = extractedImpressions.slice(0, initialItems).concat(additionalValues);

            setLebel1(finalLebel1);

            const extractedReach = [0];

            const initialItemss = Math.min(generateDataCount(days), extractedReach.length); // Get the minimum of 20 or the available items
            const additionalItemss = generateDataCount(days) - initialItemss; // Calculate how many additional items are needed

            const additionalValuess = Array.from({ length: additionalItemss }, () => 0); // Create an array of additional values (10,000)

            const finalLebel2 = extractedReach.slice(0, initialItemss).concat(additionalValuess);

            setLebel2(finalLebel2);
        }
    }

    const getFacebookAdsDatas = async (query) => {
        const res = await facebookAdsDataList(query);
        if (res?.data?.status) {
            setListData(res?.data?.data);
            setTotalRecords(res?.data?.count);
            setTotalPages(Math.ceil(res?.data?.count / pagePerSize));
            // setListData(res?.data);
        } else {
            setListData([]);
            setTotalRecords(0);
            setTotalPages(0);
            setShow({
                show: true,
                type: 'error',
                message: res?.data?.message || 'Please select date'
            })
        }
    }

    const getTiktokAdsDatas = async (query) => {
        const res = await tiktokAdsDataList(query);

        if (res?.data?.status) {
            setTiktokListData(res?.data?.data);
            setTotalRecords(res?.data?.count);
            setTotalPages(Math.ceil(res?.data?.count / pagePerSize));
        } else {
            setTiktokListData([]);
            setTotalRecords(0);
            setTotalPages(0);
            setShow({
                show: true,
                type: 'error',
                message: res?.data?.message || 'Please select date'
            })
        }
    }

    const getLinkedInTableData = async (query) => {
        const res = await likedInTableListData(query);
        if (res?.data?.status) {
            setListDataLinkedIn(res?.data?.data);
            setTotalRecords(res?.data?.count);
            setTotalPages(Math.ceil(res?.data?.count / pagePerSize));
        } else {
            setListDataLinkedIn([]);
            setTotalRecords(0);
            setTotalPages(0);
            setShow({
                show: true,
                type: 'error',
                message: res?.data?.message || 'Please select date'
            })
        }
    }

    const getInstagramTableData = async (query) => {
        const res = await instagramTableListData(query);
        if (res?.data?.status) {
            setListDataInsta(res?.data?.data);
            setTotalRecords(res?.data?.count);
            setTotalPages(Math.ceil(res?.data?.count / pagePerSize));
        } else {
            setListDataInsta([]);
            setTotalRecords(0);
            setTotalPages(0);
            setShow({
                show: true,
                type: 'error',
                message: res?.data?.message || 'Please select date'
            })
        }
    }

    const getGoogleTableData = async (query) => {
        let res;
        if (googleChannel == 1) {
            res = await googleTableListDataGDN(query);
        } else if (googleChannel == 2) {
            res = await googleTableListDataYouTube(query);
        } else {
            res = await googleTableListDataSEM(query);
        }
        if (res?.data?.status) {
            setListDataGoogle(res?.data?.data);
            setTotalRecords(res?.data?.count);
            setTotalPages(Math.ceil(res?.data?.count / pagePerSize));
        } else {
            setListDataGoogle([]);
            setTotalRecords(0);
            setTotalPages(0);
            setShow({
                show: true,
                type: 'error',
                message: res?.data?.message || 'Please select date'
            })
        }
    }



    return (
        <>
            <PageHeading heading='Social Media Channels Mgt' />
            <Stack alignItems="flex-end" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: 1 }, flexDirection: { xs: 'column', sm: 'row' } }}>
                {/* <Typography fontWeight='600' fontSize={{ xs: '15px', sm: '18px' }} color='var(--black)' width='100%'>{dataFromChild?.type?.name?.slice(0, -3) || 'Facebook'} Ads Performance</Typography> */}
                <Typography fontWeight='600' fontSize={{ xs: '15px', sm: '18px' }} color='var(--black)' width='100%'>{dataFromChild} Ads Performance</Typography>
                <Grid container justifyContent='flex-end' alignItems='center' gap={{ xs: '5px', sm: '10px' }} width="inherit">
                    <Grid item>
                        <TableSort onData={handleDataFromChild} lebel1={lebel1} lebel2={lebel2} xLabels={xLabels} fromDate={fromDate} toDate={toDate} />
                    </Grid>
                    <Grid item>
                        <DateRange setFromDate={setFromDate} fromDate={fromDate} setToDate={setToDate} toDate={toDate} setDays={setDays} days={days} setWeekDayStartName={setWeekDayStartName} setMonthStartName={setMonthStartName} setWeekDayEndName={setWeekDayEndName} setMonthEndName={setMonthEndName} />
                    </Grid>
                </Grid>
            </Stack>

            {dataFromChild == 'Facebook' ? <FacebookAds lebel1={lebel1} lebel2={lebel2} xLabels={xLabels} fromDate={fromDate} toDate={toDate} data={listData} pagePerSize={pagePerSize} totalRecords={totalRecords} totalPages={totalPages} page={page} setPage={setPage} /> :
                dataFromChild == 'Instagram' ? <InstagramAds lebel1={lebel1} lebel2={lebel2} xLabels={xLabels} fromDate={fromDate} toDate={toDate} data={listDataInsta} pagePerSize={pagePerSize} totalRecords={totalRecords} totalPages={totalPages} page={page} setPage={setPage} /> :
                    dataFromChild == 'Google' ? <GoogleAds lebel1={lebel1} lebel2={lebel2} xLabels={xLabels} fromDate={fromDate} toDate={toDate} data={listDataGoogle} pagePerSize={pagePerSize} totalRecords={totalRecords} totalPages={totalPages} page={page} setPage={setPage} googleChannel={googleChannel} setGoogleChannel={setGoogleChannel} /> :
                        dataFromChild == 'TikTok' ? <TiktokAds lebel1={lebel1} lebel2={lebel2} xLabels={xLabels} fromDate={fromDate} toDate={toDate} data={tiktokListData} pagePerSize={pagePerSize} totalRecords={totalRecords} totalPages={totalPages} page={page} setPage={setPage} setSearch={setSearch} /> :
                            dataFromChild == 'LinkedIn' ? <LinkedinAds lebel1={lebel1} lebel2={lebel2} xLabels={xLabels} fromDate={fromDate} toDate={toDate} data={listDataLinkedIn} pagePerSize={pagePerSize} totalRecords={totalRecords} totalPages={totalPages} page={page} setPage={setPage} /> :
                                <FacebookAds lebel1={lebel1} lebel2={lebel2} xLabels={xLabels} fromDate={fromDate} toDate={toDate} data={listData} pageCount={pageCount} setSearch={setSearch} />
            }


            <Notification show={show} setShow={setShow} />

        </>
    )
}

export default AdsChannels