import React, { useEffect, useState } from 'react'
import { Checkbox, FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Select, Stack, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CommonButton from '../../../components/common/Button';
import DateRange from '../../../components/common/Table/DateRange';
import Notification from '../../../components/common/Notification/Notification';
import { generateReport, getActiveChannel } from '../../../Service/reports.service';
import { useSelector } from 'react-redux';

const GenerateReport = ({ valueHandler }) => {
	const { client } = useSelector((state) => state?.root);
	const [age, setAge] = React.useState('');
	const [selectedChannel, setSelectedChannel] = React.useState(null);
	const [reportText, setReportText] = React.useState("");
	const [fromDate, setFromDate] = React.useState('');
	const [toDate, setToDate] = React.useState('');
	const [days, setDays] = React.useState(7);
	const [weekDayStartName, setWeekDayStartName] = React.useState('');
	const [monthStartName, setMonthStartName] = React.useState('');
	const [weekDayEndName, setWeekDayEndName] = React.useState('');
	const [monthEndName, setMonthEndName] = React.useState('');
	const [fieldsDataFb, setFieldsDataFb] = React.useState(["Campaign"]);
	const [fieldsDataInsta, setFieldsDataInsta] = React.useState(["Campaign"]);
	const [fieldsDataTiktok, setFieldsDataTiktok] = React.useState(["Campaign"]);
	const [fieldsDataLinkedIn, setFieldsDataLinkedIn] = React.useState(["Campaign"]);
	const [fieldsDataGoogleSem, setFieldsDataGoogleSem] = React.useState(["Campaign"]);
	const [fieldsDataGoogleGdn, setFieldsDataGoogleGdn] = React.useState(["Campaign"]);
	const [fieldsDataGoogleYoutube, setFieldsDataGoogleYoutube] = React.useState(["Campaign"]);
	const [activatedChannel, setActivatedChannel] = React.useState({});
	const [insightsValues, setInsightsValues] = React.useState({});
	const [show, setShow] = React.useState({
		show: false,
		type: '',
		message: ''
	});

	const [selectedReportsCheck, setSelectedReportsCheck] = useState([]);

	const handleChange = async (event) => {
		if (client && client?.clientId) {
			setAge(event.target.value);
			setSelectedChannel(event.target.value);
			const qru = `?channel=${event.target.value}`
			const resActivatedChannel = await getActiveChannel(client?.clientId, qru);
			if (resActivatedChannel && resActivatedChannel.status == true) {
				setActivatedChannel(resActivatedChannel?.data)
			} else {
				setActivatedChannel({});
				setShow({
					show: true,
					type: 'error',
					message: `Channel not connected with your account`
				})
			}
		} else {
			setShow({
				show: true,
				type: 'error',
				message: 'Please select client'
			})
		}
	};

	const theme = createTheme({
		components: {
			// Name of the component
			MuiSelect: {
				styleOverrides: {
					icon: {
						color: 'var(--primary)',
						zIndex: '-1'
					},
					outlined: {
						padding: '0 !important',
						border: `1px solid var(--white)`,
					},
					root: {
						borderRadius: '5px',
						background: 'var(--white)',
						color: 'var(--secondary)',
						padding: '7px 12px',
						boxShadow: '2px 3px 10px 0px rgba(0, 0, 0, 0.15)',
						width: '130px',
					},
				},
			},
		},
	});

	const handleSelectedValueFB = (e) => {
		if (e.target.checked) {
			setFieldsDataFb([...fieldsDataFb, e.target.value])
		} else {
			var filteredArray = fieldsDataFb.filter(ev => ev !== e.target.value)
			setFieldsDataFb(filteredArray);
		}

	}

	const handleSelectedValueInsta = (e) => {
		if (e.target.checked) {
			setFieldsDataInsta([...fieldsDataInsta, e.target.value])
		} else {
			var filteredArray = fieldsDataInsta.filter(ev => ev !== e.target.value)
			setFieldsDataInsta(filteredArray);
		}
	}

	const handleSelectedValueTiktok = (e) => {
		if (e.target.checked) {
			setFieldsDataTiktok([...fieldsDataTiktok, e.target.value])
		} else {
			var filteredArray = fieldsDataTiktok.filter(ev => ev !== e.target.value)
			setFieldsDataTiktok(filteredArray);
		}
	}

	const handleSelectedValueLinkedIn = (e) => {
		if (e.target.checked) {
			setFieldsDataLinkedIn([...fieldsDataLinkedIn, e.target.value])
		} else {
			var filteredArray = fieldsDataLinkedIn.filter(ev => ev !== e.target.value)
			setFieldsDataLinkedIn(filteredArray);
		}
	}

	const handleSelectedValueGoogleSem = (e) => {
		if (e.target.checked) {
			setFieldsDataGoogleSem([...fieldsDataGoogleSem, e.target.value])
		} else {
			var filteredArray = fieldsDataGoogleSem.filter(ev => ev !== e.target.value)
			setFieldsDataGoogleSem(filteredArray);
		}
	}

	const handleSelectedValueGoogleGdn = (e) => {
		if (e.target.checked) {
			setFieldsDataGoogleGdn([...fieldsDataGoogleGdn, e.target.value])
		} else {
			var filteredArray = fieldsDataGoogleGdn.filter(ev => ev !== e.target.value)
			setFieldsDataGoogleGdn(filteredArray);
		}
	}

	const handleSelectedValueGoogleYoutube = (e) => {
		if (e.target.checked) {
			setFieldsDataGoogleYoutube([...fieldsDataGoogleYoutube, e.target.value])
		} else {
			var filteredArray = fieldsDataGoogleYoutube.filter(ev => ev !== e.target.value)
			setFieldsDataGoogleYoutube(filteredArray);
		}
	}

	const handleSubmitReport = async () => {
		try {
			let fieldsVal = {};
			if (selectedChannel == 'facebook') {
				fieldsVal.facebook_matrix = fieldsDataFb;
			}
			if (selectedChannel == 'instagram') {
				fieldsVal.instagram_matrix = fieldsDataInsta;
			}
			if (selectedChannel == 'tiktok') {
				fieldsVal.tiktok_matrix = fieldsDataTiktok;
			}
			if (selectedChannel == 'linkedin') {
				fieldsVal.linkedin_matrix = fieldsDataLinkedIn;
			}
			if (selectedChannel == 'google_sem') {
				fieldsVal.google_sem_matrix = fieldsDataGoogleSem;
			}
			if (selectedChannel == 'google_gdn') {
				fieldsVal.google_gdn_matrix = fieldsDataGoogleGdn;
			}
			if (selectedChannel == 'google_youtube') {
				fieldsVal.google_youtube_matrix = fieldsDataGoogleYoutube;
			}
			if (selectedChannel == 'all') {
				// fieldsVal = fieldsDataAll;
				fieldsVal.facebook_matrix = fieldsDataFb;
				fieldsVal.instagram_matrix = fieldsDataInsta;
				fieldsVal.tiktok_matrix = fieldsDataTiktok;
				fieldsVal.linkedin_matrix = fieldsDataLinkedIn;
				fieldsVal.google_sem_matrix = fieldsDataGoogleSem;
				fieldsVal.google_gdn_matrix = fieldsDataGoogleGdn;
				fieldsVal.google_youtube_matrix = fieldsDataGoogleYoutube;
			}
			if (client && client?.clientId) {
				if (fieldsVal && Object.keys(fieldsVal).length > 0) {
					if (fromDate !== "" && toDate !== "") {
						const payload = {
							"client": client?.clientId,
							"channel": selectedChannel,
							"start_date": fromDate,
							"end_date": toDate,
							// "fields": fieldsVal,
							// "description": reportText
							"channels_report": selectedReportsCheck
						}
						const mergeMatrix = { ...payload, ...fieldsVal };
						const mergedObject = { ...mergeMatrix, ...insightsValues };
						const res = await generateReport(mergedObject);
						if (res.status == true) {
							setShow({
								show: true,
								type: 'success',
								message: res.message || 'Report has been genrated successfully'
							})
							setTimeout(() => {
								valueHandler('2')
							}, 2000);
						} else {
							setShow({
								show: true,
								type: 'error',
								message: res.message || 'Something went wrong'
							})
						}
					} else {
						setShow({
							show: true,
							type: 'error',
							message: 'Start end date not seleted'
						})
					}
				} else {
					setShow({
						show: true,
						type: 'error',
						message: 'Please select value'
					})
				}
			} else {
				setShow({
					show: true,
					type: 'error',
					message: 'Please select client'
				})
			}
		} catch (error) {
			setShow({
				show: true,
				type: 'error',
				message: 'Something went wrong!'
			})
		}
	}

	useEffect(() => {
		if (client && client?.clientId) {
		} else {
			setShow({
				show: true,
				type: 'error',
				message: 'Please Select Client'
			})
		}
	}, [client])

	const handleReportText = (e) => {
		setInsightsValues({ ...insightsValues, [e.target.name]: e.target.value })
		setReportText(e.target.value);
	}

	const handleReportCheck = (e) => {
		if (age == "all") {
			if (e.target.checked) {
				setSelectedReportsCheck([...selectedReportsCheck, e.target.value])
			} else {
				setSelectedReportsCheck(selectedReportsCheck.filter((item) => item != e.target.value))
			}
		}
	}

	return (
		<>
			<ThemeProvider theme={theme} >
				<Grid container alignItems='center' gap={{ xs: '5px', sm: '10px' }} width="inherit" paddingTop={1}>
					<Grid item xs={12}>
						<Grid container alignItems='center' gap={{ xs: '5px', sm: '10px' }} width="inherit">
							<Grid item>
								<Select
									value={age}
									displayEmpty
									onChange={handleChange}
									// endAdornment={<KeyboardArrowDownIcon />}
									label='select'
									placeholder='Select'
								>
									<MenuItem value='' selected>Select</MenuItem>
									<MenuItem value='all' selected>All</MenuItem>
									<MenuItem value='facebook'>Facebook</MenuItem>
									<MenuItem value='instagram'>Instagram</MenuItem>
									<MenuItem value='tiktok'>TikTok</MenuItem>
									<MenuItem value='linkedin'>Linkedin</MenuItem>
									<MenuItem value='google_sem'>Google SEM</MenuItem>
									<MenuItem value='google_gdn'>Google GDN</MenuItem>
									<MenuItem value='google_youtube'>Google Youtube</MenuItem>
								</Select>
							</Grid>
							<Grid item>
								<DateRange setFromDate={setFromDate} fromDate={fromDate} setToDate={setToDate} toDate={toDate} setDays={setDays} days={days} setWeekDayStartName={setWeekDayStartName} setMonthStartName={setMonthStartName} setWeekDayEndName={setWeekDayEndName} setMonthEndName={setMonthEndName} />
							</Grid>
						</Grid>
					</Grid>


					{activatedChannel && activatedChannel?.facebook ? (
						<Grid item xs={12}>
							<FormLabel component="legend">
								{activatedChannel?.facebook?.channel_name}
								{age == "all" ? (
									<FormControlLabel
										value='facebook'
										control={<Checkbox />}
										label=""
										labelPlacement="end"
										sx={{ marginLeft: 0 }}
										onChange={handleReportCheck}
									/>
								) : ("")}
							</FormLabel>
							<FormControl component="fieldset" sx={{ flexDirection: 'row' }}>
								{activatedChannel?.facebook?.matrix ?
									activatedChannel?.facebook?.matrix.map((item) => (
										<FormControlLabel
											value={item}
											control={<Checkbox />}
											label={item}
											labelPlacement="end"
											onChange={handleSelectedValueFB}
										/>
									))
									: ("herer")}
							</FormControl>
							<Grid item xs={6} mt={1}>
								<Stack direction="row" alignItems="center" justifyContent="space-between">
									<Typography className='myprofile_input' sx={{ width: '100%' }}>
										<textarea placeholder={`Key ${activatedChannel?.facebook?.channels?.value}`} className='report_textarea' maxLength={'255'} rows={3} name={activatedChannel?.facebook?.channels?.key} onChange={handleReportText} ></textarea>
									</Typography>
								</Stack>
							</Grid>
						</Grid>
					) : ("")}

					{activatedChannel && activatedChannel?.instagram ? (
						<Grid item xs={12}>
							<FormLabel component="legend">
								{activatedChannel?.instagram?.channel_name}
								{age == "all" ? (
									<FormControlLabel
										value='instagram'
										control={<Checkbox />}
										label=""
										labelPlacement="end"
										sx={{ marginLeft: 0 }}
										onChange={handleReportCheck}
									/>
								) : ("")}
							</FormLabel>
							<FormControl component="fieldset" sx={{ flexDirection: 'row' }}>
								{activatedChannel?.instagram?.matrix ?
									activatedChannel?.instagram?.matrix.map((item) => (
										<FormControlLabel
											value={item}
											control={<Checkbox />}
											label={item}
											labelPlacement="end"
											onChange={handleSelectedValueInsta}
										/>
									))
									: ("herer")}
							</FormControl>
							<Grid item xs={6} mt={1}>
								<Stack direction="row" alignItems="center" justifyContent="space-between">
									<Typography className='myprofile_input' sx={{ width: '100%' }}>
										<textarea placeholder={`Key ${activatedChannel?.instagram?.channels?.value}`} className='report_textarea' maxLength={'255'} rows={3} name={activatedChannel?.instagram?.channels?.key} onChange={handleReportText} ></textarea>
									</Typography>
								</Stack>
							</Grid>
						</Grid>
					) : ("")}

					{activatedChannel && activatedChannel?.linkedin ? (
						<Grid item xs={12}>
							<FormLabel component="legend">
								{activatedChannel?.linkedin?.channel_name}
								{age == "all" ? (
									<FormControlLabel
										value='linkedin'
										control={<Checkbox />}
										label=""
										labelPlacement="end"
										sx={{ marginLeft: 0 }}
										onChange={handleReportCheck}
									/>
								) : ("")}
							</FormLabel>
							<FormControl component="fieldset" sx={{ flexDirection: 'row' }}>
								{activatedChannel?.linkedin?.matrix ?
									activatedChannel?.linkedin?.matrix.map((item) => (
										<FormControlLabel
											value={item}
											control={<Checkbox />}
											label={item}
											labelPlacement="end"
											onChange={handleSelectedValueLinkedIn}
										/>
									))
									: ("herer")}
							</FormControl>
							<Grid item xs={6} mt={1}>
								<Stack direction="row" alignItems="center" justifyContent="space-between">
									<Typography className='myprofile_input' sx={{ width: '100%' }}>
										<textarea placeholder={`Key ${activatedChannel?.linkedin?.channels?.value}`} className='report_textarea' maxLength={'255'} rows={3} name={activatedChannel?.linkedin?.channels?.key} onChange={handleReportText} ></textarea>
									</Typography>
								</Stack>
							</Grid>
						</Grid>
					) : ("")}

					{activatedChannel && activatedChannel?.tiktok ? (
						<Grid item xs={12}>
							<FormLabel component="legend">
								{activatedChannel?.tiktok?.channel_name}
								{age == "all" ? (
									<FormControlLabel
										value='tiktok'
										control={<Checkbox />}
										label=""
										labelPlacement="end"
										sx={{ marginLeft: 0 }}
										onChange={handleReportCheck}
									/>
								) : ("")}
							</FormLabel>
							<FormControl component="fieldset" sx={{ flexDirection: 'row' }}>
								{activatedChannel?.tiktok?.matrix ?
									activatedChannel?.tiktok?.matrix.map((item) => (
										<FormControlLabel
											value={item}
											control={<Checkbox />}
											label={item}
											labelPlacement="end"
											onChange={handleSelectedValueTiktok}
										/>
									))
									: ("herer")}
							</FormControl>
							<Grid item xs={6} mt={1}>
								<Stack direction="row" alignItems="center" justifyContent="space-between">
									<Typography className='myprofile_input' sx={{ width: '100%' }}>
										<textarea placeholder={`Key ${activatedChannel?.tiktok?.channels?.value}`} className='report_textarea' maxLength={'255'} rows={3} name={activatedChannel?.tiktok?.channels?.key} onChange={handleReportText} ></textarea>
									</Typography>
								</Stack>
							</Grid>
						</Grid>
					) : ("")}

					{activatedChannel && activatedChannel?.google_sem ? (
						<Grid item xs={12}>
							<FormLabel component="legend">
								{activatedChannel?.google_sem?.channel_name}
								{age == "all" ? (
									<FormControlLabel
										value='google_sem'
										control={<Checkbox />}
										label=""
										labelPlacement="end"
										sx={{ marginLeft: 0 }}
										onChange={handleReportCheck}
									/>
								) : ("")}
							</FormLabel>
							<FormControl component="fieldset" sx={{ flexDirection: 'row' }}>
								{activatedChannel?.google_sem?.matrix ?
									activatedChannel?.google_sem?.matrix.map((item) => (
										<FormControlLabel
											value={item}
											control={<Checkbox />}
											label={item}
											labelPlacement="end"
											onChange={handleSelectedValueGoogleSem}
										/>
									))
									: ("herer")}
							</FormControl>
							<Grid item xs={6} mt={1}>
								<Stack direction="row" alignItems="center" justifyContent="space-between">
									<Typography className='myprofile_input' sx={{ width: '100%' }}>
										<textarea placeholder={`Key ${activatedChannel?.google_sem?.channels?.value}`} className='report_textarea' maxLength={'255'} rows={3} name={activatedChannel?.google_sem?.channels?.key} onChange={handleReportText} ></textarea>
									</Typography>
								</Stack>
							</Grid>
						</Grid>
					) : ("")}

					{activatedChannel && activatedChannel?.google_gdn ? (
						<Grid item xs={12}>
							<FormLabel component="legend">
								{activatedChannel?.google_gdn?.channel_name}
								{age == "all" ? (
									<FormControlLabel
										value='google_gdn'
										control={<Checkbox />}
										label=""
										labelPlacement="end"
										sx={{ marginLeft: 0 }}
										onChange={handleReportCheck}
									/>
								) : ("")}
							</FormLabel>
							<FormControl component="fieldset" sx={{ flexDirection: 'row' }}>
								{activatedChannel?.google_gdn?.matrix ?
									activatedChannel?.google_gdn?.matrix.map((item) => (
										<FormControlLabel
											value={item}
											control={<Checkbox />}
											label={item}
											labelPlacement="end"
											onChange={handleSelectedValueGoogleGdn}
										/>
									))
									: ("herer")}
							</FormControl>
							<Grid item xs={6} mt={1}>
								<Stack direction="row" alignItems="center" justifyContent="space-between">
									<Typography className='myprofile_input' sx={{ width: '100%' }}>
										<textarea placeholder={`Key ${activatedChannel?.google_gdn?.channels?.value}`} className='report_textarea' maxLength={'255'} rows={3} name={activatedChannel?.google_gdn?.channels?.key} onChange={handleReportText} ></textarea>
									</Typography>
								</Stack>
							</Grid>
						</Grid>
					) : ("")}

					{activatedChannel && activatedChannel?.google_youtube ? (
						<Grid item xs={12}>
							<FormLabel component="legend">
								{activatedChannel?.google_youtube?.channel_name}
								{age == "all" ? (
									<FormControlLabel
										value='google_youtube'
										control={<Checkbox />}
										label=""
										labelPlacement="end"
										sx={{ marginLeft: 0 }}
										onChange={handleReportCheck}
									/>
								) : ("")}
							</FormLabel>
							<FormControl component="fieldset" sx={{ flexDirection: 'row' }}>
								{activatedChannel?.google_youtube?.matrix ?
									activatedChannel?.google_youtube?.matrix.map((item) => (
										<FormControlLabel
											value={item}
											control={<Checkbox />}
											label={item}
											labelPlacement="end"
											onChange={handleSelectedValueGoogleYoutube}
										/>
									))
									: ("")}
							</FormControl>
							<Grid item xs={6} mt={1}>
								<Stack direction="row" alignItems="center" justifyContent="space-between">
									<Typography className='myprofile_input' sx={{ width: '100%' }}>
										<textarea placeholder={`Key ${activatedChannel?.google_youtube?.channels?.value}`} className='report_textarea' maxLength={'255'} rows={3} name={activatedChannel?.google_youtube?.channels?.key} onChange={handleReportText} ></textarea>
									</Typography>
								</Stack>
							</Grid>
						</Grid>
					) : ("")}

					{activatedChannel ? (
						<Grid item xs={12}>
							<CommonButton buttonName='Generate Report' onClick={() => handleSubmitReport()} padding='6px 10px' background='var(--primary)' />
						</Grid>
					) : ("")}
				</Grid>
			</ThemeProvider>

			<Notification show={show} setShow={setShow} />
		</>
	)
}

export default GenerateReport;