import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CRMAds from './CRMAds';
import GDNAds from './GDNAds';
import YoutubeAds from './YoutubeAds';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function GoogleAds(props) {
  const {
    lebel1,
    lebel2,
    xLabels,
    fromDate,
    toDate,
    data,
    setSearch,
    pagePerSize,
    totalRecords,
    totalPages,
    page,
    setPage,
    googleChannel,
    setGoogleChannel,
    shortKey,
    setShortKey,
    sortBy,
    setSortBy
  } = props;
  const handleChange = (event, newValue) => {
    setGoogleChannel(newValue);
    setPage(1);
  };

  return (
    <Box sx={{ width: '100%' }} className='tab'>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={googleChannel} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="SEM" {...a11yProps(0)} />
          <Tab label="GDN" {...a11yProps(1)} />
          <Tab label="YouTube" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={googleChannel} index={0}>
        <CRMAds lebel1={lebel1} lebel2={lebel2} xLabels={xLabels} fromDate={fromDate} toDate={toDate} data={data} pagePerSize={pagePerSize} totalRecords={totalRecords} totalPages={totalPages} page={page} setPage={setPage} googleChannel={googleChannel}
          shortKey={shortKey}
          setShortKey={setShortKey}
          sortBy={sortBy} setSortBy={setSortBy}
        />
      </CustomTabPanel>
      <CustomTabPanel value={googleChannel} index={1}>
        <GDNAds lebel1={lebel1} lebel2={lebel2} xLabels={xLabels} fromDate={fromDate} toDate={toDate} data={data} pagePerSize={pagePerSize} totalRecords={totalRecords} totalPages={totalPages} page={page} setPage={setPage} googleChannel={googleChannel}
          shortKey={shortKey}
          setShortKey={setShortKey}
          sortBy={sortBy} setSortBy={setSortBy}
        />
      </CustomTabPanel>
      <CustomTabPanel value={googleChannel} index={2}>
        <YoutubeAds lebel1={lebel1} lebel2={lebel2} xLabels={xLabels} fromDate={fromDate} toDate={toDate} data={data} pagePerSize={pagePerSize} totalRecords={totalRecords} totalPages={totalPages} page={page} setPage={setPage} googleChannel={googleChannel}
          shortKey={shortKey}
          setShortKey={setShortKey}
          sortBy={sortBy} setSortBy={setSortBy}
        />
      </CustomTabPanel>
    </Box>
  );
}