import React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { Grid, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useStyles } from '../../../../helper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const lebel1 = [150, 300, 250, 400, 350, 180, 250, 180, 250, 200, 300, 250, 400];
const lebel2 = [400, 250, 300, 200, 250, 180, 250, 180, 350, 400, 250, 300, 180];
const xLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Nov', 'Dec'];

const TiktokAdsChart = (props) => {
    const { lebel1, lebel2, xLabels } = props;

    const [selectedLines, setSelectedLines] = React.useState(['label1', 'label2']);

    const handleChange = (event) => {
        setSelectedLines(event.target.value);
    };

    const legendStyles = {
        "--ChartsLegend-rootOffsetX": "220px",
        "--ChartsLegend-rootOffsetY": "-60px",
        "--ChartsLegend-itemMarkSize": "15px",
        "--ChartsLegend-itemWidth": "120px",
    };
    const classes = useStyles();

    if (!lebel1 || !lebel2 || !xLabels) {
        return   <Grid container>
        <Grid item xs={12} sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '300px',
        }}>
            <h4>No Data Found</h4>
        </Grid>
    </Grid>;
      }
    
      // Check if data arrays have the same length
      if (lebel1.length !== lebel2.length || lebel1.length !== xLabels.length) {
        return   <Grid container>
        <Grid item xs={12} sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '300px',
        }}>
            <h4>No Data Found</h4>
        </Grid>
    </Grid>;
      }
    return (
        <>
            {
                lebel1.filter((item) => item != 0).length != 0 && lebel2.filter((item) => item != 0).length != 0 ?   (
                    <>   <Grid
                        container
                        padding={2}
                        justifyContent="flex-end"
                        alignItems="center"
                        position="absolute"
                        top="0"
                        zIndex="999"
                    >
                        <Grid item>
                            <FormControl variant="outlined" sx={{ Width: 100 }}>
                            <Select
                                        multiple
                                        value={selectedLines}
                                        onChange={handleChange}
                                        select
                                        label="Select Line(s)"
                                        displayEmpty
                                        renderValue={(selected) => {
                                            if (selected) {
                                                return <em>Insight</em>;
                                            }
                                        }}
                                    >
                                        <MenuItem value="impressions">Impressions</MenuItem>
                                        <MenuItem value="Reach">Reach</MenuItem>
                                    </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                        {/* <LineChart
                            xAxis={[{ scaleType: 'point', data: xLabels }]}
                            series={selectedLines.map((line) => ({
                                id: line,
                                data: line === 'label1' ? lebel1 : lebel2,
                                label: line === 'label1' ? 'lebel1' : 'lebel2',
                                color: line === 'label1' ? '#040404' : '#fe2858',
                            }))}
                            sx={{
                                [`& .MuiAreaElement-series-label1`]: {
                                    fill: 'linear-gradient(180deg, #040404 0%, rgba(255, 255, 255, 0.00) 100%)',
                                },
                                [`& .MuiAreaElement-series-label2`]: {
                                    fill: 'linear-gradient(180deg, #fe2858 0%, rgba(255, 255, 255, 0.00) 100%)',
                                },
                                ...legendStyles,
                            }}
                            legend={{
                                directon: 'row',
                                position: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                            }}
                            height={300}
                        /> */}
                         <LineChart
                            xAxis={[{ scaleType: 'point', data: xLabels }]}
                            series={selectedLines.map((line) => ({
                                id: line,
                                data: line === 'impressions' ? lebel1 : lebel2,
                                label: line === 'impressions' ? 'Impressions' : 'Reach',
                                color: line === 'impressions' ? '#3b5998' : '#8b9dc3',

                            }))}
                            sx={{
                                [`& .MuiAreaElement-series-impressions`]: {
                                    fill: 'linear-gradient(180deg, #3b5998 0%, rgba(255, 255, 255, 0.00) 100%)',
                                },
                                [`& .MuiAreaElement-series-Reach`]: {
                                    fill: 'linear-gradient(180deg, #8b9dc3 0%, rgba(255, 255, 255, 0.00) 100%)',
                                },
                                ...legendStyles,
                            }}
                            legend={{
                                direction: 'row',
                                position: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                            }}
                            height={300}
                        />
                    </>) : (
                    <Grid container>
                        <Grid item xs={12} sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '300px',
                        }}>
                            <h4>No Data Found</h4>
                        </Grid>
                    </Grid>
                )}
        </>
    );
};

export default TiktokAdsChart;
