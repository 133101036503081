import React, { useState } from 'react'
import ManageRole from './ManageRole'
import { DataGrid } from '@mui/x-data-grid'
import { roleListrows, roleListcolumns, useStyles } from '../../../helper';
import { getGroups } from '../../../Service/profile.service';
import { useEffect } from 'react';
import { Stack } from '@mui/system';
import { AddIcon, EditIcon } from '../../../assets/icons/Icons';
import Skeleton from '@mui/material/Skeleton';
import { styled } from "@mui/material/styles";
import Notification from '../../../components/common/Notification/Notification';

import {
    Grid, Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Pagination,
    TableContainer
} from '@mui/material';
import AddRole from './AddRole';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    color: "#0F0F0F80",
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#1987540a",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const Roles = () => {
    const classes = useStyles();
    const [groupData, setGroupData] = React.useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [pagePerSize, setPagePerSize] = useState(10);
    const [page, setPage] = useState(1);
    const [editId, setEditId] = React.useState({})
    const [show, setShow] = useState({
        show: false,
        type: '',
        message: ''
    });

    const getGroupData = async () => {
        const groups = await getGroups();
        if (groups.status == true) {
            setGroupData(groups.data);
        } else {
            setGroupData([]);
        }
    }

    const [openAdd, setOpenAdd] = React.useState(false);
    const [roleName, setRoleName] = React.useState("");
    const handleOpen = (name) => {
        setOpenAdd(true);
        setRoleName(name)
    };
    const handleClose = () => {
        setOpenAdd(false);
    };

    const handleName = (name) => {

    }

    useEffect(() => {
        getGroupData();
    }, [])

    const handleChangePage = (event, value) => {
        setPage(value);
        getGroupData(value);
    };

    const TableRowsLoader = ({ rowsNum }) => {
        return [...Array(rowsNum)].map((row, index) => (
            <TableRow key={index}>
                <TableCell component="th" scope="row">
                    <Skeleton animation="wave" variant="text" width={210} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton animation="wave" variant="text" width={210} height={20} />
                </TableCell>
                {/* <TableCell>
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell> */}
            </TableRow>
        ));
    };


    return (
        <>
            <Grid container justifyContent='flex-end' sx={{ marginY: 1 }}>
                {/* <DataGrid
                    disableColumnMenu
                    autoHeight
                    autoWidth
                    rows={roleListrows}
                    columns={roleListcolumns}
                    pageSize={5}
                    className={classes.tableContainer}
                    disableRowSelectionOnClick
                /> */}
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table stickyHeader aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                                    Role Name
                                </StyledTableCell>
                                {/* <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
										Email/Username
									</StyledTableCell> */}
                                {/* <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
										Join Date
									</StyledTableCell>
									<StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
										Status
									</StyledTableCell> */}
                                <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                                    Action
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        {/* <Skeleton variant="rectangular" width={210} height={60} /> */}
                        <TableBody>
                            {/* {clientListData.length === 0 ? (<TableRowsLoader rowsNum={10} />) : (clientListData?.map((row, index) => { */}
                            {groupData?.map((row, index) => {
                                var {
                                    id,
                                    Group_name,
                                } = row;
                                return (
                                    <StyledTableRow
                                        key={index}
                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        // onClick={(e) => navigate(`/admin/client/details`, { state: { id: id } })}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {Group_name.charAt(0).toUpperCase() + Group_name.slice(1)}
                                            {/* {Group_name} */}
                                        </TableCell>
                                        {/* <TableCell>{email}</TableCell> */}

                                        <TableCell onClick={(e) => { e.stopPropagation(); setEditId(row) }}>
                                            <Grid container spacing={2} alignItems={'center'} flexWrap={'nowrap'}>
                                                <Grid item>
                                                    <EditIcon sx={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            handleOpen(Group_name)
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </StyledTableRow>
                                );
                            })}
                            {groupData === null ? (
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            color: "silver",
                                            textAlign: "center",
                                            paddingTop: "90px",
                                            borderBottom: "none",
                                            fontSize: "30px",
                                        }}
                                        colSpan="6"
                                    >
                                        No records to display
                                    </TableCell>
                                </TableRow>

                            ) : (
                                groupData.length === 0 ? (<TableRowsLoader rowsNum={10} />) : ("")
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* <Stack
                    sx={{ mt: 4, pb: 2 }}
                    flexDirection="row"
                    justifyContent="space-between"
                >
                    <div>
                        Showing{" "}
                        {page * pagePerSize > totalRecords
                            ? totalRecords
                            : page * pagePerSize}{" "}
                        of {totalRecords} Results
                    </div>
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handleChangePage}
                        variant="outlined"
                        shape="rounded"
                    />
                </Stack> */}
            </Grid>
            <AddRole openAdd={openAdd} handleClose={handleClose} editId={editId} setShow={setShow} roleName={roleName} />
            <Notification show={show} setShow={setShow} />
        </>
    )
}

export default Roles