import axios from "axios";

axios.defaults.withCredentials = false;
const AxiosWithoutToken = axios.create({
    // baseURL: process.env.REACT_APP_API_URL,
    // baseURL: "http://170.187.238.121:8000",
    baseURL:"https://apidms.mobiginie.com",
    crossDomain: false,
});


export default AxiosWithoutToken;



