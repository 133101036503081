import { Container, Typography } from '@mui/material'
import React from 'react'

const TermsOfService = () => {
	return (
		<Container maxWidth='md'>
			<Typography variant='h4' sx={{marginBottom:2,fontWeight:'600'}}>Terms of Service</Typography>
			
			<Typography variant='h6' sx={{fontWeight:'600'}}>1. Introduction</Typography>
			<Typography sx={{marginBottom:2}}>Welcome to [Your Company Name]! These Terms of Service ("Agreement") govern your use of our website located at [Your Website URL] ("Website") and our related services ("Services"). Please read this Agreement carefully before using our Website or Services.</Typography>
			
			<Typography variant='h6' sx={{fontWeight:'600'}}>2. Acceptance of Terms</Typography>
			<Typography sx={{marginBottom:2}}>By using our Website and Services, you agree to this Agreement. If you do not agree with any part of this Agreement, you must not use our Website or Services.</Typography>
			
			<Typography variant='h6' sx={{fontWeight:'600'}}>3. User Responsibilities</Typography>
			<Typography sx={{marginBottom:2}}>You agree to use our Website and Services for lawful purposes only. You must not use our Services to engage in any illegal activities, violate any third-party rights, or distribute malicious content.</Typography>
			
			<Typography variant='h6' sx={{fontWeight:'600'}}>4. Account Terms</Typography>
			<Typography sx={{marginBottom:2}}>In order to access some features of our Website and Services, you may need to create an account. You are responsible for maintaining the security of your account and for any activities conducted under your account.</Typography>
			
			<Typography variant='h6' sx={{fontWeight:'600'}}>5. Intellectual Property Rights</Typography>
			<Typography sx={{marginBottom:2}}>All content on our Website and provided through our Services is owned by [Your Company Name] or used under license. You must not use, copy, or distribute any of our content without our written permission.</Typography>
			
			<Typography variant='h6' sx={{fontWeight:'600'}}>6. Limitation of Liability</Typography>
			<Typography sx={{marginBottom:2}}>[Your Company Name] will not be liable for any indirect, incidental, or consequential damages arising out of or in connection with your use of our Website or Services.</Typography>
		</Container>
	)
}

export default TermsOfService