import React, { useEffect, useState } from 'react';
import { useStyles } from '../../../../helper';
import { Box, Card, FormControlLabel, Grid, Radio, RadioGroup, Stack, Typography, Button } from '@mui/material';
import {googleAccountSelect} from '../../../../Service/social.service';
import { useLocation } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import Notification from '../../../../components/common/Notification/Notification';
import CommonButton from '../../../../components/common/Button';
import { useNavigate } from 'react-router-dom';


const ChooseAccount = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [selectedValue, setSelectedValue] = useState('');
    const [accountData, setAccountData] = useState([]);
    const [responseState, setResponseState] = React.useState(0);
    const [showProcess, setShowProcess] = React.useState(0);
    const [refresh_token, setRefresh_token] = React.useState('');
    const [access_token, setAccess_token] = React.useState('');
    const [show, setShow] = React.useState({
        show: false,
        type: '',
        message: ''
    });

    const [message, setMessage] = React.useState("Your google accout is processing...");
    const [userId, setUserId] = useState('');
    const { state } = useLocation();

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    useEffect(() => {
        if ( state && state.data ) {

            setAccountData(state.data)
            setUserId(state.userId)
            setRefresh_token(state.refresh_token)
            setAccess_token(state.access_token)
        }
    }, [state])


    const handleSubmit = async() => {
        if ( selectedValue == '' ) {
            setShow({
                show: true,
                type: 'error',
                message: 'Please select account'
            })
            return ;
        }
        else{
            setShowProcess(1);
        const payload = {
            user: userId,
            customers_id: selectedValue,
            access_token: access_token,
            refresh_token: refresh_token
        }
        const res =await googleAccountSelect(payload)
        if ( res.status == true ) {
            setResponseState(1);
            setMessage(res.message)
        }else{
            setResponseState(2);
            setMessage(res.message)
        }
        }
    };

    return (
        <>
        {showProcess == 0 && (  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw' }}>
            <Card className={classes.cardContainer} sx={{ width: { xs: '300px', sm: '500px' }, height: 'unset', minWidth: { xs: '300px', sm: '500px' } }}>
                <Box padding={3}>
                    <RadioGroup value={selectedValue} onChange={handleChange}>
                        {accountData &&accountData.map((item, index) => (
                            <Stack key={index} container justifyContent={'flex-start'} alignItems={'center'} gap={1} flexDirection={'row'} padding={1}>
                                <FormControlLabel
                                    value={item}
                                    control={<Radio />}
                                    sx={{ marginRight: '0 !important' }}
                                    onChange={handleChange}
                                />
                                <Stack>
                                    <Typography fontWeight={'600'}>{item}</Typography>
                                    {/* <Typography>biren.nettyfy@gmail.com</Typography> */}
                                </Stack>
                            </Stack>
                        ))}
                    </RadioGroup>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Box>
            </Card>
        </Box>)}
        
        {showProcess ==1 && ( <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw' }}>
                <Card className={classes.cardContainer} sx={{ width: { xs: '300px', sm: '500px' }, height: 'unset', minWidth: { xs: '300px', sm: '500px' } }}>
                    <div>
                        <Stack container justifyContent={'center'} alignItems={'center'} padding={3} gap={3}>
                            <Grid item xs={12}>
                                {responseState == 1 ? (
                                    <CheckCircleOutlineIcon sx={{ color: '#0ea24a', fontSize: '100px' }} />
                                ) : ("")}
                                {responseState == 0 ? (
                                    <AccessTimeFilledIcon sx={{ color: '#dfd800', fontSize: '100px' }} />
                                ) : ("")}
                                {responseState == 2 ? (
                                    <HighlightOffIcon sx={{ color: '#dd0612', fontSize: '100px' }} />
                                ) : ("")}
                            </Grid>
                            <Grid item xs={12}>
                                {/* <Typography fontSize={{ xs: '18px', sm: '25px' }} fontWeight={'700'}>{message}</Typography> */}
                                <Typography fontSize={{ xs: '18px', sm: '25px' }} fontWeight={'700'} textAlign={'center'}>{message}</Typography>
                                {/* <Typography fontSize={{ xs: '18px', sm: '25px' }} fontWeight={'700'} textAlign={'center'}>Some Reasons Your Linkdin Account Not Connected</Typography> */}
                            </Grid>
                            <Grid item xs={12}>
                                {responseState != 0 ? (
                                    <CommonButton buttonName='Go to Home' background='primary' fontSize='1rem' minWidth='40px' padding='4px 8px' onClick={() => navigate('/admin/client/details',{state:{
                                        id: userId
                                    }})} />
                                ) : ("")}
                            </Grid>
                        </Stack>
                    </div>
                </Card>
            </Box>)}
            <Notification show={show} setShow={setShow} />

       
        </>
      

        
    );
};

export default ChooseAccount;
