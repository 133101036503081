import { FormControl, Grid, Stack, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import CommonButton from '../../../components/common/Button'
import CommonSelect from '../../../components/common/Select'
import CommonInput from '../../../components/common/Input'
import PageHeading from '../../../components/common/PageHeading'
import { useFormik } from "formik";
import { profileValidationSchema } from '../../../helper/validation';
import { profileInitialValues } from '../../../helper/initialValues';
import Notification from '../../../components/common/Notification/Notification';
import { profileDetails, updateProfile } from '../../../Service/profile.service';

const inputItem = [
  {
    label: 'First Name',
    inputValue: 'Anna',
    disabled: false,
    name: 'first_name'
  },
  {
    label: 'Last Name',
    inputValue: 'Knight',
    disabled: false,
    name: 'last_name'
  },
  {
    label: 'Email address',
    inputValue: 'anna34@gmail.com',
    disabled: true,
    name: 'email'
  },
]

const MyProfile = () => {
  const [admin, setAdmin] = React.useState('');
  const [show, setShow] = useState({
    show: false,
    type: '',
    message: ''
  });

  const handleChange = event => {
    setAdmin(event.target.value);
  };

  const formik = useFormik({
    initialValues: profileInitialValues,
    onSubmit: async (value) => {

      const payload = {
        first_name: value.first_name,
        last_name: value.last_name
      };

      const res = await updateProfile(payload);
      if (res.status) {
        setShow({
          show: true,
          type: 'success',
          message: res.message || 'Login Successfully'
        })
        window.location.reload();

      } else {
        setShow({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong'
        })
      }
    },
    validationSchema: profileValidationSchema,
  });

  useEffect(() => {
    getProfileData();
  }, [])

  const getProfileData = async () => {
    const res = await profileDetails();
    if (res.status) {
      formik.setFieldValue('first_name', res?.data?.first_name);
      formik.setFieldValue('last_name', res?.data?.last_name);
      formik.setFieldValue('email', res?.data?.email);
    }
  }

  return (
    <>
      <PageHeading heading='My Profile' para='Welcome to' paraspan='ADMS' />
      <Stack sx={{ padding: '0 !important', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', gap: '25px', marginTop: 2 }}>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography className='myprofile_head' fontWeight='600'>
            Personal Information
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Grid container spacing={2} sx={{ columnGap: { xs: '0', sm: '40px', md: '60px' }, rowGap: { xs: '0', sm: '10px' } }}>
            {inputItem.map((data, i) => {
              return (
                <Grid item xs={12} sm={5} md={4} key={i}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between" width={250}>
                    <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                      {data.label}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography className='myprofile_input' sx={{ width: '100%' }}>
                      <CommonInput fullWidth placeholder={data.label} disabled={data.disabled} name={data.name} formik={formik} />
                    </Typography>
                  </Stack>
                </Grid>
              )
            })}
          </Grid>
        </Stack>
        {/* <Stack direction="row" alignItems="center" justifyContent="flex-start">
          <Typography className='myprofile_head' sx={{ paddingRight: { xs: '20px', sm: '70px' } }} fontWeight='600'>
            Access Level
          </Typography>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <CommonSelect value={admin} onChange={handleChange} option='Admin' />
          </FormControl>
        </Stack> */}
        <Stack direction="row" alignItems="center" justifyContent="flex-start">
          <CommonButton buttonName='Save Profile Info' onClick={formik.handleSubmit} />
        </Stack>
      </Stack>
      <Notification show={show} setShow={setShow} />

    </>
  )
}

export default MyProfile