import React from 'react'
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

const CommonInput = (props) => {
    const { border, focusBorder } = props;
    const outerTheme = useTheme();

    const customTheme = (outerTheme) =>
        createTheme({
            components: {
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            '& .MuiInputBase-root': {
                                padding: '10px 15px',
                                border: `${border || "1.5px solid var(--secondary)"}`,
                                borderRadius: '6px',
                                input: {
                                    padding: '0'
                                }
                            },
                            '& .Mui-focused': {
                                border: `${focusBorder}`,
                            },
                        },
                    }
                },
            }
        });

    return (
        <>
            <ThemeProvider theme={customTheme(outerTheme)}>
                <TextField
                    sx={props.sx}
                    fullWidth={props.fullWidth}
                    hiddenLabel
                    placeholder={props.placeholder}
                    inputProps={{ maxLength: props.maxLength }} // Set maximum input length to 20 characters
                    type={props.type || 'text'}
                    error={props?.formik?.touched[props.name] && props?.formik?.errors[props.name]}
                    helperText={props?.formik?.touched[props.name] && props?.formik?.errors[props.name]}
                    {...props?.formik?.getFieldProps(props.name)}
                    {...props}

                />
            </ThemeProvider>

            {/* {props?.formik?.touched[props.name] && props?.formik?.errors[props.name] ? <div className='error'>{props?.formik?.errors[props.name]}</div> : <div className='error'></div>} */}
        </>
    )
}

export default CommonInput