export const loginInitialValues = {
    email: "",
    password: "",
}
export const superloginInitialValues = {
    user: "",
    password: "",
}

export const profileInitialValues = {
    first_name: "",
    last_name: "",
    email: "",
}

export const changePasswordInitialValues = {
    new_password: "",
    confirmpass: ""
}

export const addClientFormInitialValues = {
    fName: "",
    lName: "",
    email: "",
    company_name: "",
    contract_join_date: "",
    contract_end_date: "",
    password: ""
}

export const updateClientFormInitialValues = {
    fName: "",
    lName: "",
    company_name: "",
    contract_join_date: "",
    contract_end_date: "",
}

export const addAdminFormInitialValues = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
}

export const updateAdminFormInitialValues = {
    fName: "",
    lName: "",
    password: "",
}