import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Checkbox, Grid, Stack, Typography } from '@mui/material';
import { useStyles } from '../../../helper';
import AddRole from './AddRole';
import { AddIcon } from '../../../assets/icons/Icons';
import { rolePermissionList, rolePermissionAssign } from '../../../Service/role.service'
import { useEffect } from 'react';
import CommonButton from '../../../components/common/Button';

const columns = [
    { id: 'name', label: 'Admin', flex: 1, minWidth: 100 },
    { id: 'read', label: 'Create', Width: 30, align: 'center' },
    { id: 'read', label: 'Read', Width: 30, align: 'center' },
    { id: 'edit', label: 'Edit', Width: 30, align: 'center' },
    { id: 'delet', label: 'Delete', Width: 30, align: 'center' },

];







function createData(name, read, edit, delet) {
    return { name, read, edit, delet };
}

const rows = [
    createData('SM Channel Mgt', <Checkbox />, <Checkbox />, <Checkbox />),
    createData('Leads', <Checkbox />, <Checkbox />, <Checkbox />),
    createData('Client Mgt', <Checkbox />, <Checkbox />, <Checkbox />),
    createData('Keyword Planner', <Checkbox />, <Checkbox />, <Checkbox />),
    createData('Budget Calculator', <Checkbox />, <Checkbox />, <Checkbox />),
    createData('Reporting', <Checkbox />, <Checkbox />, <Checkbox />),
];

export default function ColumnGroupingTable(props) {
    const { editId, handleClose,setShow } = props;
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rolePermissionData, setRolePermissionData] = React.useState([]);
    const [assignId, setAssignId] = React.useState([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        if (editId) {
            getRolePermissionList(editId);
        }
    }, [editId])



    const getRolePermissionList = async (editId) => {
        try {
            const rolePermission = await rolePermissionList(editId?.group_id);

            if (rolePermission.status === true && rolePermission.data) {
                setRolePermissionData(rolePermission.data);
                const data = [];

                rolePermission.data.forEach((item) => {
                    if (item.isAdd) {
                        data.push(item.add);
                    }
                    if (item.isView) {
                        data.push(item.view);
                    }
                    if (item.isChange) {
                        data.push(item.change);
                    }
                    if (item.isDelete) {
                        data.push(item.delete);
                    }

                });
                setAssignId(data);
            } else {
                // Handle the case where rolePermission.status is not true or rolePermission.data is null or undefined.
                setRolePermissionData([]);
            }
        } catch (error) {
            // Handle any errors that occur during the API request.
            console.error("Error fetching role permissions:", error);
        }
    };

    const handelSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            group_id: editId?.group_id,
            permission_id: assignId,
            action:"assign"
        }
        const res = await rolePermissionAssign(payload);
        if (res.status == true) {
            handleClose();
            setShow({
                show: true,
                type: 'success',
                message: res.message
            });
        } else {
            setShow({
                show: true,
                type: 'error',
                message: res.message
            });
        }
    }

    return (
        <>
            <Paper sx={{ width: '100%' }} className={classes.cardContainer}>
                <TableContainer >
                    <Table stickyHeader aria-label="sticky table" size="small" >
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" colSpan={1} style={{ fontWeight: '600' }}>
                                    Module Name
                                </TableCell>
                                <TableCell align="center" colSpan={4} style={{ fontWeight: '600' }}>
                                    Permission
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, fontWeight: '600' }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rolePermissionData
                                .map((row, i) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            <TableCell key={row.id} align="left">
                                                {row.name}
                                            </TableCell>
                                            <TableCell key={row.id} align="left">
                                                <Checkbox checked={row.isAdd} onChange={(e) => {
                                                    row.isAdd = e.target.checked;
                                                    setRolePermissionData([...rolePermissionData]);
                                                    if (e.target.checked) {
                                                        setAssignId([...assignId, row.add]);
                                                    } else {
                                                        setAssignId(assignId.filter(item => item !== row.add));
                                                    }

                                                }} />
                                            </TableCell>
                                            <TableCell key={row.id} align="left">
                                                <Checkbox checked={row.isView} onChange={(e) => {
                                                    row.isView = e.target.checked;
                                                    setRolePermissionData([...rolePermissionData]);
                                                    if (e.target.checked) {
                                                        setAssignId([...assignId, row.view]);
                                                    }
                                                    else {
                                                        setAssignId(assignId.filter(item => item !== row.view));
                                                    }

                                                }} />
                                            </TableCell>
                                            <TableCell key={row.id} align="left">
                                                <Checkbox checked={row.isChange} onChange={(e) => {
                                                    row.isChange = e.target.checked;
                                                    setRolePermissionData([...rolePermissionData]);
                                                    if (e.target.checked) {
                                                        setAssignId([...assignId, row.change]);
                                                    }
                                                    else {
                                                        setAssignId(assignId.filter(item => item !== row.change));
                                                    }

                                                }} />
                                            </TableCell>
                                            <TableCell key={row.id} align="left">
                                                <Checkbox checked={row.isDelete} onChange={(e) => {
                                                    row.isDelete = e.target.checked;
                                                    setRolePermissionData([...rolePermissionData]);
                                                    if (e.target.checked) {
                                                        setAssignId([...assignId, row.delete]);
                                                    }
                                                    else {
                                                        setAssignId(assignId.filter(item => item !== row.delete));
                                                    }

                                                }} />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Stack flexDirection={'row'} gap={1} marginTop={2} justifyContent={'flex-end'}>
                <CommonButton onClick={handleClose} buttonName="Cancel" />
                <CommonButton onClick={handelSubmit} buttonName='Save' />
            </Stack>
        </>
    );
}