import React from 'react'
import { LineChart } from '@mui/x-charts/LineChart';
import { Typography } from '@mui/material';
// const lebel1 = [150, 300, 250, 400, 350, 180, 250, 180, 250, 200, 300, 250];
// const lebel2 = [400, 250, 300, 200, 250, 180, 250, 180, 350, 400, 250, 300];
// const xLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'oCT', 'Nov', 'Dec'];

const ChartKeyword = ({ keywordGraphData }) => {

    const monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const xLabels = [];
    const lebel1 = [];

    keywordGraphData && keywordGraphData?.forEach(entry => {
        const monthIndex = (entry.month - 1) % 12
        // Calculate modulo to ensure it's within the range 0 to 11 // Adjust month to be 0-based and -1 for monthNames
        xLabels.push(monthNames[monthIndex]);
        lebel1.push(entry.monthly_searches);
    });

    return (
        <>
            {xLabels?.length === lebel1?.length && xLabels?.length !== 0 ? (
                <LineChart
                    xAxis={[{ scaleType: 'point', data: xLabels }]}
                    series={[
                        { id: 'label1', data: lebel1, label: 'Search Volume', color: '#3CFAEA', },
                        // { id: 'label2', data: lebel2, label: 'lebel2', color: '#287973', },
                    ]}
                    sx={{
                        '& .MuiAreaElement-series-label1': {
                            fill: 'linear-gradient(180deg, #3CFAEA 0%, rgba(255, 255, 255, 0.00) 100%)',
                        },
                        '& .MuiAreaElement-series-label2': {
                            fill: "linear-gradient(180deg, #287973 0%, rgba(255, 255, 255, 0.00) 100%);",
                        },
                        "--ChartsLegend-rootOffsetX": "-140px",
                        "--ChartsLegend-rootOffsetY": "-60px",
                        '--ChartsLegend-itemMarkSize': '12px',
                        '--ChartsLegend-itemWidth': '80px',
                    }}
                    legend={{
                        directon: "row",
                        position: {
                            vertical: "top",
                            horizontal: "right"
                        }
                    }}
                    height={300}
                />
            ) : (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '300px'

                    }}
                ><Typography variant="h6" sx={{ textAlign: 'center', color: 'silver' }}>No data found</Typography></div>
            )}

        </>

    )
}

export default ChartKeyword