import React, { useEffect, useState } from 'react'
import { facebookadscolumns, facebookadsrows, useStyles } from '../../../../helper'
import { DataGrid } from '@mui/x-data-grid'
import TableSearch from '../../../../components/common/Table/TableSearch';
import FacebookAdsChart from './FacebookAdsChart';
import { facebookAdsDataList } from '../../../../Service/facebook.service';
import { useSelector } from 'react-redux';
import {
  Grid, Stack, TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Pagination,
  Typography
} from '@mui/material';
import { styled } from "@mui/material/styles";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#1987540a",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));

const FacebookAds = (props) => {
  const { lebel1, lebel2, xLabels, fromDate, toDate, data, setSearch, pagePerSize, totalRecords, totalPages, page, setPage } = props
  const classes = useStyles();
  const { client } = useSelector((state) => state?.root);
  // const [search, ] = useState('');
  const handleChangePage = (event, value) => {
    setPage(value);
    // getClientList(value);
  };

  return (
    <>
      {/* <Grid className={classes.cardContainer}>
        <FacebookAdsChart lebel1={lebel1 ? lebel1 : ['']} lebel2={lebel2 ? lebel2 : ['']} xLabels={xLabels ? xLabels : ['']} />
      </Grid> */}

      <Stack justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: 1 }, marginTop: { xs: 2 }, flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 1 }, alignItems: { xs: 'start', sm: 'end' } }}>
        <Typography fontWeight='600' fontSize={{ xs: '15px', sm: '18px' }} color='var(--black)' >Campaigns</Typography>
        <TableSearch onChange={(e) => {
          setSearch(e.target.value)
          setPage(1)
        }} />
      </Stack>


      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Campaign Name
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Impressions
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Reach
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Link Clicks
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                CPC
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Amount Spent($)
              </StyledTableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, index) => {
              var {
                ad_id,
                ad_name,
                clicks,
                impressions,
                reach,
                spend,
                hedged_cost,
                hedged_cpc
              } = row;

              return (
                <StyledTableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell component="th" scope="row">
                    {ad_name}
                  </TableCell>
                  <TableCell>{impressions}</TableCell>
                  <TableCell>{reach}</TableCell>
                  <TableCell>
                    {clicks}
                  </TableCell>
                  <TableCell>
                    {hedged_cpc}
                  </TableCell>
                  <TableCell>
                    {hedged_cost.toLocaleString("en-US", { style: "currency", currency: "USD" }) || 0}
                  </TableCell>
                </StyledTableRow>
              );
            })}
            {data?.length === 0 ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="6"
                >
                  No records to display
                </TableCell>
              </TableRow>
            ) : (
              ""
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          Showing{" "}
          {data && data.length}
          {' '}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>


    </>
  )
}

export default FacebookAds
