import { Card, Grid, Typography, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { theme, useStyles } from '../../../helper'
import { Chart } from '../../../components/Constants';
import CommonInput from '../../../components/common/Input';
import { useSelector } from 'react-redux';
import { saveGoals } from "../../../Service/dashboard.service"

const CounterCard = (props) => {
    const { dashboardData, setDashboardData, setShow, isGoal, handleDashboardData } = props;
    const classes = useStyles();
    const { client } = useSelector((state) => state?.root);

    const handleGoleUpdate = async (goal, fieldName) => {
        if (client && client?.clientId) {
            const payload = {
                [fieldName]: goal,
            }
            const resSaveGoal = await saveGoals(payload, client?.clientId);
            if (resSaveGoal.status == true) {
                handleDashboardData();
                setShow({
                    show: true,
                    type: 'success',
                    message: resSaveGoal.message || 'Report has been genrated successfully'
                })
            } else {
                setShow({
                    show: true,
                    type: 'error',
                    message: resSaveGoal.message || 'Something went wrong'
                })
            }
        } else {
            setShow({
                show: true,
                type: 'error',
                message: 'Please Select Client'
            })
        }
    }

    const handleNewvaluUpdate = (e) => {
        const { name, value } = e.target;
        const updatedDashboardData = { ...dashboardData, [name]: value };
        setDashboardData(updatedDashboardData);
    }

    const handlePercentageValues = (num) => {
        if (num > 0) {
            return (
                <Grid item xs={4} className={classes.percentage}>{num}%</Grid>
            )
        } else {
            return (
                <Grid item xs={4} className={classes.percentage} sx={{ color: 'red !important' }}>{num}%</Grid>
            )
        }
    }

    return (
        <Grid container spacing={{ xs: '10px', md: 3 }}>
            <Grid item theme={theme} xs={6} sm={6} md={3} >
                <Card className={classes.cardContainer}>
                    <Grid container padding={{ xs: 2, md: 3 }} sx={{ background: 'var(--cardBG)' }}>
                        <Grid item xs={8} className={classes.smallHead}>Clicks</Grid>
                        <Grid item xs={4} className={classes.counterImg}><img src={Chart} /></Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={'3px'}>
                                <Grid item xs={12} className={classes.counterNumber}>{dashboardData?.total_click?.toLocaleString() || 0}</Grid>
                                <Grid item xs={12} className={classes.goalHead} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    {isGoal ? (
                                        <>
                                            <Typography fontSize={'12px'} sx={{ marginRight: '3px' }}>
                                                Goal :
                                            </Typography>
                                            <CommonInput
                                                sx={{
                                                    width: '70px',
                                                    '& .MuiInputBase-root': {
                                                        padding: '0 2px',
                                                        input: {
                                                            fontSize: '12px',
                                                            height: '14px'
                                                        }
                                                    },
                                                }}
                                                type='text'
                                                // disabled={isGoal}
                                                value={dashboardData?.goal_click || ""}
                                                name="goal_click"
                                                onBlur={() => handleGoleUpdate(dashboardData?.goal_click, "goal_click")}
                                                onChange={(e) => handleNewvaluUpdate(e)}
                                                border={'1.5px solid transparent'}
                                                focusBorder='1.5px solid var(--secondary)'
                                            />
                                        </>
                                    ) : (
                                        <Typography fontSize={'12px'} sx={{ marginRight: '3px' }}>
                                            Goal :{dashboardData?.goal_click}
                                        </Typography>

                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={4} className={classes.percentage}>{dashboardData?.click_percentage}</Grid> */}
                        {handlePercentageValues(dashboardData?.click_percentage)}
                    </Grid>
                </Card>
            </Grid>

            <Grid item theme={theme} xs={6} sm={6} md={3} >
                <Card className={classes.cardContainer}>
                    <Grid container padding={{ xs: 2, md: 3 }} sx={{ background: 'var(--cardBG)' }}>
                        <Grid item xs={8} className={classes.smallHead}>Impressions</Grid>
                        <Grid item xs={4} className={classes.counterImg}><img src={Chart} /></Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={'3px'}>
                                <Grid item xs={12} className={classes.counterNumber}>{dashboardData?.total_impressions?.toLocaleString() || 0}</Grid>
                                {/* <Grid item xs={12} className={classes.goalHead}>Goal : {dashboardData.goal_impressions}</Grid> */}
                                <Grid item xs={12} className={classes.goalHead} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    {isGoal ? (
                                        <>
                                            <Typography fontSize={'12px'} sx={{ marginRight: '3px' }}>
                                                Goal :
                                            </Typography>
                                            <CommonInput
                                                sx={{
                                                    width: '50px',
                                                    '& .MuiInputBase-root': {
                                                        padding: '0 2px',
                                                        input: {
                                                            fontSize: '12px',
                                                            height: '14px'
                                                        }
                                                    },
                                                }}
                                                type='text'
                                                value={dashboardData?.goal_impressions}
                                                name="goal_impressions"
                                                onBlur={() => handleGoleUpdate(dashboardData?.goal_impressions, "goal_impressions")}
                                                onChange={(e) => handleNewvaluUpdate(e)}
                                                border={'1.5px solid transparent'}
                                                focusBorder='1.5px solid var(--secondary)'
                                            />
                                        </>
                                    ) : (
                                        <Typography fontSize={'12px'} sx={{ marginRight: '3px' }}>
                                            Goal :{dashboardData?.goal_impressions}
                                        </Typography>
                                    )}

                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={4} className={classes.percentage}>{dashboardData?.impressions_percentage}</Grid> */}
                        {handlePercentageValues(dashboardData?.impressions_percentage)}
                    </Grid>
                </Card>
            </Grid>

            <Grid item theme={theme} xs={6} sm={6} md={3} >
                <Card className={classes.cardContainer}>
                    <Grid container padding={{ xs: 2, md: 3 }} sx={{ background: 'var(--cardBG)' }}>
                        <Grid item xs={8} className={classes.smallHead}>CTR</Grid>
                        <Grid item xs={4} className={classes.counterImg}><img src={Chart} /></Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={'3px'}>
                                <Grid item xs={12} className={classes.counterNumber}>{dashboardData && dashboardData.total_ctr ? dashboardData.total_ctr.toFixed(2) : 0}%</Grid>
                                {/* <Grid item xs={12} className={classes.goalHead}>Goal : {dashboardData.goal_ctr}</Grid> */}
                                <Grid item xs={12} className={classes.goalHead} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    {isGoal ? (
                                        <>
                                            <Typography fontSize={'12px'} sx={{ marginRight: '3px' }}>
                                                Goal :
                                            </Typography>
                                            <CommonInput
                                                sx={{
                                                    width: '50px',
                                                    '& .MuiInputBase-root': {
                                                        padding: '0 2px',
                                                        input: {
                                                            fontSize: '12px',
                                                            height: '14px'
                                                        }
                                                    },
                                                }}
                                                type='text'
                                                value={dashboardData?.goal_ctr || ""}
                                                name="goal_ctr"
                                                onBlur={() => handleGoleUpdate(dashboardData?.goal_ctr, "goal_ctr")}
                                                onChange={(e) => handleNewvaluUpdate(e)}
                                                border={'1.5px solid transparent'}
                                                maxLength={3}
                                                focusBorder='1.5px solid var(--secondary)' />
                                        </>
                                    ) : (
                                        <Typography fontSize={'12px'} sx={{ marginRight: '3px' }}>
                                            Goal :{dashboardData?.goal_ctr}
                                        </Typography>
                                    )}

                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={4} className={classes.percentage}>{dashboardData?.ctr_percentage}</Grid> */}
                        {handlePercentageValues(dashboardData?.ctr_percentage)}
                    </Grid>
                </Card>
            </Grid>

            <Grid item theme={theme} xs={6} sm={6} md={3} >
                <Card className={classes.cardContainer}>
                    <Grid container padding={{ xs: 2, md: 3 }} sx={{ background: 'var(--cardBG)' }}>
                        <Grid item xs={8} className={classes.smallHead}>Conversion</Grid>
                        <Grid item xs={4} className={classes.counterImg}><img src={Chart} /></Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={'3px'}>
                                <Grid item xs={12} className={classes.counterNumber}>{dashboardData?.total_conversions || 0}</Grid>
                                {/* <Grid item xs={12} className={classes.goalHead}>Goal : {dashboardData.goal_conversion}</Grid> */}
                                <Grid item xs={12} className={classes.goalHead} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    {isGoal ? (
                                        <>
                                            <Typography fontSize={'12px'} sx={{ marginRight: '3px' }}>
                                                Goal :
                                            </Typography>
                                            <CommonInput
                                                sx={{
                                                    width: '50px',
                                                    '& .MuiInputBase-root': {
                                                        padding: '0 2px',
                                                        input: {
                                                            fontSize: '12px',
                                                            height: '14px'
                                                        }
                                                    },
                                                }}
                                                type='text'
                                                value={dashboardData?.goal_conversion}
                                                name="goal_conversion"
                                                onBlur={() => handleGoleUpdate(dashboardData?.goal_conversion, "goal_conversion")}
                                                onChange={(e) => handleNewvaluUpdate(e)}
                                                border={'1.5px solid transparent'}
                                                focusBorder='1.5px solid var(--secondary)' /></>
                                    ) : (
                                        <Typography fontSize={'12px'} sx={{ marginRight: '3px' }}>
                                            Goal :{dashboardData?.goal_conversion}
                                        </Typography>
                                    )}

                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={4} className={classes.percentage}>{dashboardData?.conversions_percentage}</Grid> */}
                        {handlePercentageValues(dashboardData?.conversions_percentage)}
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    )
}

export default CounterCard