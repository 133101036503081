import React, { useState, useEffect } from 'react'
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import {
  MainLogo,
  Vector1, Vector2, Vector3, Vector4, Vector5, Vector7, Vector8, Vector10,
  Access, Ads, Calc, Client, Dashboard, Leads, Keyword, Reporting,
} from '../Constants'
import List from '@mui/material/List';
import FormControl from '@mui/material/FormControl';
import { ListItemText } from '@mui/material';
import CommonSelect from '../common/Select';
import { useLocation, useNavigate } from 'react-router-dom';
import { clientDropDown } from '../../Service/client.service';
import { addClient } from '../../state/action/clientAction';
import { useDispatch, useSelector } from 'react-redux';
import { IsRead } from '../../helper/handlePermission'

const SidebarList = [
  {
    name: 'Dashboard',
    icon: Vector10,
    activeIcon: Dashboard,
    path: '/admin/'
  },
  {
    name: 'Ads Channel',
    icon: Vector1,
    activeIcon: Ads,
    path: '/admin/channel'
  },
  {
    name: 'Leads',
    icon: Vector2,
    activeIcon: Leads,
    path: '/admin/leads'

  },
  {
    name: 'Clients Management',
    icon: Vector3,
    activeIcon: Client,
    path: '/admin/client'
  },
  {
    name: 'Keyword Planner',
    icon: Vector4,
    activeIcon: Keyword,
    path: '/admin/planner'
  },
  {
    name: 'Budget Calculator',
    icon: Vector5,
    activeIcon: Calc,
    path: '/admin/budget'
  },
  {
    name: 'Reporting',
    icon: Vector7,
    activeIcon: Reporting,
    path: '/admin/reporting'
  },
  {
    name: 'Role based access mgt',
    icon: Vector8,
    activeIcon: Access,
    path: '/admin/access'
  },
]

const Sidebar = ({ handleDrawerToggle }) => {
  const [client, setClient] = useState('');
  const [clientData, setClientData] = useState([]);
  const dispatch = useDispatch();
  const handleChange = event => {
    setClient(event.target.value);
    dispatch(addClient(event.target.value));
  };
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useSelector((state) => state?.root?.auth);
  const leadsPermission = IsRead('leads');

  const getClientList = async () => {
    const res = await clientDropDown({
      group_id: 3
    });
    if (res.status) {
      setClientData(res?.data.map((data) => ({
        lable: data?.first_name + ' ' + data?.last_name,
        id: data?.id
      })));
    }
    else {
      setClientData([]);
    }
  }


  useEffect(() => {
    if (token) {
      getClientList();
    }
    // getClientList();
  }, [token])


  return (
    <>
      <List sx={{
        padding: '24px 24px 15px 24px',
      }}>
        <img src={MainLogo} alt='main_logo' />
      </List>
      <List sx={{ padding: '0 !important' }} >
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <CommonSelect value={client} onChange={handleChange} option='Select Client' options={clientData} />
        </FormControl>
      </List>
      <List>
        {/* {SidebarList.map((data, index) => (
          <ListItem onClick={() => navigate(data.path)} key={data} disablePadding sx={{ marginBottom: '8px' }}>
            <ListItemButton onClick={handleDrawerToggle} key={index} sx={{ backgroundColor: `${location.pathname == data.path ? '#e7f1f1' : ''}`, borderLeft: ` ${location.pathname == data.path ? '4px solid var(--primary) !important' : '4px solid transparent !important'}` }}>
              <ListItemIcon sx={{ minWidth: 30, width: '13px', height: '17px', }}>
                <img src={location.pathname == data.path ? data.activeIcon : data.icon} alt='' />
              </ListItemIcon>
              <ListItemText primary={data.name} sx={{ color: `${location.pathname == data.path ? 'var(--primary)' : ''}`, fontSize: '15px' }} />
            </ListItemButton>
          </ListItem>
        ))} */}
        {SidebarList
          .filter((data) => !(data.name === "Leads" && leadsPermission === false))
          .map((data, index) => (
            <ListItem
              onClick={() => navigate(data.path)}
              key={data.name} // Use a unique key
              disablePadding
              sx={{ marginBottom: '8px' }}
            >
              <ListItemButton
                onClick={handleDrawerToggle}
                key={index}
                sx={{
                  backgroundColor: location.pathname === data.path ? '#e7f1f1' : '',
                  borderLeft: location.pathname === data.path ? '4px solid var(--primary) !important' : '4px solid transparent !important',
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 30,
                    width: '13px',
                    height: '17px',
                  }}
                >
                  <img src={location.pathname === data.path ? data.activeIcon : data.icon} alt='' />
                </ListItemIcon>
                <ListItemText
                  primary={data.name}
                  sx={{
                    color: location.pathname === data.path ? 'var(--primary)' : '',
                    fontSize: '15px',
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))
        }
      </List>
    </>
  )
}

export default Sidebar