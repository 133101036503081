import React from 'react'
import PageHeading from '../../../components/common/PageHeading'
import { Card, Grid, Stack, Switch, ThemeProvider, Typography } from '@mui/material'
import { theme, useStyles } from '../../../helper';
import { AddIcon, CloseIcon } from '../../../assets/icons/Icons';

const Setting = () => {
    const classes = useStyles();

    return (
        <>
            <PageHeading heading='Settings' />
            <ThemeProvider theme={theme}>
                <Grid container spacing={{ xs: 2, sm: 3 }} paddingTop={2}>
                    <Grid item xs={12}>
                        <Stack>
                            <Typography variant='h5'>Notification Settings</Typography>
                            <Typography>We may still send you important notifications about your account outside of your notification settings.</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={9} sm={5}><Typography variant='h6'>Comments</Typography></Grid>
                                    <Grid item> <Switch defaultChecked color="primary" /></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={9} sm={5}><Typography variant='h6'>Tags</Typography></Grid>
                                    <Grid item> <Switch color="primary" /></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={9} sm={5}><Typography variant='h6'>Reminders</Typography></Grid>
                                    <Grid item> <Switch color="primary" /></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={9} sm={5}><Typography variant='h6'>More Activity about you</Typography></Grid>
                                    <Grid item> <Switch defaultChecked color="primary" /></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </>
    )
}

export default Setting