import React from 'react'
import MyProfile from '../../pages/admin/myprofile/MyProfile';
import { Route, Routes } from 'react-router-dom';
import DashBoard from '../../pages/admin/dashboard/DashBoard';
import Leads from '../../pages/admin/leads/Leads';
import Clientmanagement from '../../pages/admin/clientmanagement/Clientmanagement';
import KeywordPlaner from '../../pages/admin/keywordplanner/KeywordPlaner';
import Reporting from '../../pages/admin/reporting/Reporting';
import RoleAccessManagement from '../../pages/admin/accessmanagement/RoleAccessManagement';
import AdsChannels from '../../pages/admin/adschannel/AdsChannels';
import BudgetCalculator from '../../pages/admin/budgetcalculator/BudgetCalculator';
import Setting from '../../pages/admin/setting/Setting';
import PreviousReport from '../../pages/admin/reporting/PreviousReport';
import { useSelector } from 'react-redux';
import ClientDetails from '../../pages/admin/clientmanagement/ClientDetails';
import AdGroup from '../../pages/admin/adschannel/google/adgroup/AdGroup';
import Sem from '../../pages/admin/adschannel/google/adgroup/sem/Sem';
import Gdn from '../../pages/admin/adschannel/google/adgroup/gdn/Gdn';
import Youtube from '../../pages/admin/adschannel/google/adgroup/youtube/Youtube';

const Main = () => {

  const user = useSelector((state) => state?.root?.auth);

  const pages = [
    { name: 'Dashboard', path: '/admin/', element: <DashBoard /> },
    { name: 'Channel', path: '/admin/channel', element: <AdsChannels /> },
    { name: 'Channel', path: '/admin/channel/groups', element: <AdGroup /> },
    { name: 'Channel', path: '/admin/channel/groups/semads', element: <Sem /> },
    { name: 'Channel', path: '/admin/channel/groups/gdnads', element: <Gdn /> },
    { name: 'Channel', path: '/admin/channel/groups/youtubeads', element: <Youtube /> },
    { name: 'leads', path: '/admin/leads', element: <Leads /> },
    { name: 'Client', path: '/admin/client', element: <Clientmanagement /> },
    { name: 'Client', path: '/admin/client/details', element: <ClientDetails /> },
    { name: 'Planner', path: '/admin/planner', element: <KeywordPlaner /> },
    { name: 'Budget', path: '/admin/budget', element: <BudgetCalculator /> },
    { name: 'Reporting', path: '/admin/reporting', element: <Reporting /> },
    { name: 'Previous Reporting', path: '/admin/previous-reporting', element: <PreviousReport /> },
    { name: 'Access', path: '/admin/access', element: <RoleAccessManagement /> },
    { name: 'customer', path: '/admin/myprofile', element: <MyProfile /> },
    { name: 'Setting', path: '/admin/setting', element: <Setting /> },
  ]


  return (
    <Routes>
      {pages.map((page, index) => {
        const { name, path, element } = page;
        const permissions =
          user &&
          user.permissions &&
          user.permissions.find((permission) => {
            return permission.name == name;
          });

        if (user && user.user && user.user?.groups && user?.user?.groups[0] && user?.user?.groups[0]?.name && user?.user?.groups[0]?.name == "super_admin") {
          return (
            <Route key={index} exact path={path} element={element} />
          )
        } else {
          if (name == "leads" || name == "customuser") {

            if (permissions && permissions?.view) {
              return (
                <Route key={index} exact path={path} element={element} />
              )
            }
            else {
              // navigate("/404")
            }
          } else {
            return (
              <Route key={index} exact path={path} element={element} />
            )
          }
        }
      }
      )}

    </Routes>
  )
}

export default Main