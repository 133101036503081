import React, { useState, useEffect } from 'react'
import PageHeading from '../../../components/common/PageHeading'
import { ThemeProvider } from '@mui/material/styles';
import { theme, useStyles } from '../../../helper';
import {
	Dialog, Grid, Stack, TableContainer,
	Paper,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Chip,
	Typography,
	Pagination
} from '@mui/material';
import TableSearch from '../../../components/common/Table/TableSearch';
import DateRange from '../../../components/common/Table/DateRange';
import ChartKeyword from './ChartKeyword';
import CommonButton from '../../../components/common/Button';
import SearchKeyword from './SearchKeyword';
import { styled } from "@mui/material/styles";
import { useSelector } from 'react-redux';
import Notification from '../../../components/common/Notification/Notification';
import Skeleton from '@mui/material/Skeleton';
import { postKeywords } from '../../../Service/keyword.service';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: "#1987540a",
	},
	// hide last border
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
	color: "#0F0F0F80",
}));

const KeywordPlaner = () => {
	const classes = useStyles();
	const [modalOpen, setModalOpen] = useState(false);
	const [keywordsList, setKeywordsList] = useState([]);
	const [page, setPage] = useState(1);
	const [pagePerSize, setPagePerSize] = useState(20);
	const [totalRecords, setTotalRecords] = useState(0);
	const [totalPages, setTotalPages] = useState(0);
	const [selectedLocations, setSelectedLocations] = useState([]);
	const [selectedKeywords, setSelectedKeywords] = useState([]);
	const [websiteUrl, setWebsiteUrl] = useState("");
	const [urlValidation, setUrlValidation] = useState(false);
	const [loading, setLoading] = useState(false);
	const [keywordGraphData, setKeywordGraphData] = useState([]);
	const [show, setShow] = useState({
		show: false,
		type: '',
		message: ''
	});
	const { client } = useSelector((state) => state?.root);

	const handleOpenModal = () => {
		if (client && client?.clientId) {
			setModalOpen(true);
		} else {
			setShow({
				show: true,
				type: 'error',
				message: 'Please select client'
			})
		}

	};
	const handleCloseModal = () => {
		setModalOpen(false);
	};

	const handleChangePage = (event, value) => {
		setPage(value);
		// setKeywordsList([])
		handelSubmit(value);
	};

	const handelGrapData = (data) => {
		if (data) {
			setKeywordGraphData(data);
		}
	}

	useEffect(() => { }, [page])

	const TableRowsLoader = ({ rowsNum }) => {
		return [...Array(rowsNum)].map((row, index) => (
			<TableRow key={index}>
				<TableCell component="th" scope="row">
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell>
			</TableRow>
		));
	};

	const handelSubmit = async (selectedPage) => {
		if (client && client?.clientId) {
			if (selectedLocations.length > 0) {
				if (selectedKeywords.length > 0) {
					// if (!urlValidation) {
					setLoading(true);
					const payload = {
						"location_ids": selectedLocations?.map(item => item.id),
						"keyword": selectedKeywords,
						"page_url": websiteUrl
					}
					let query = `?page=${selectedPage || 1}&limit=${pagePerSize}`;
					const getKeywordsData = await postKeywords(payload, client?.clientId, query);
					if (getKeywordsData.status) {
						setKeywordsList(getKeywordsData?.data)
						setTotalRecords(getKeywordsData?.count);
						setTotalPages(Math.ceil(getKeywordsData?.count / pagePerSize));
						handleCloseModal();
						setLoading(false);
						setKeywordGraphData([]);
					} else {
						setKeywordsList([]);
						setTotalRecords(0);
						setTotalPages(0);
						setLoading(false);
						setKeywordGraphData([]);
						setShow({
							show: true,
							type: 'error',
							message: getKeywordsData?.message
						})
					}
				} else {
					setShow({
						show: true,
						type: 'error',
						message: 'Please add some keywords'
					})
				}
			} else {
				setShow({
					show: true,
					type: 'error',
					message: 'Please select locations'
				})
			}
		} else {
			setShow({
				show: true,
				type: 'error',
				message: 'Please select client'
			})
		}
	}

	return (
		<>
			<ThemeProvider theme={theme}>
				<Stack direction="row" alignItems="center" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: 1 }, gap: '8px' }}>
					<PageHeading heading='Keyword Planner' />
				</Stack>
				<Stack direction="row" alignItems="center" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: 1 }, gap: '8px' }}>
					{/* <TableSearch /> */}
					<Stack direction="row" alignItems="center" justifyContent="flex-end" width='100%' sx={{ marginBottom: { xs: 1 }, gap: '8px' }}>
						<CommonButton buttonName="Search Keyword" padding='6px 20px' onClick={handleOpenModal} />
						{/* <DateRange /> */}
					</Stack>
				</Stack>
				<Grid className={classes.cardContainer} sx={{ marginBottom: 2 }}>
					<ChartKeyword keywordGraphData={keywordGraphData} />
				</Grid>

				<ThemeProvider theme={theme}>
					<TableContainer component={Paper} className={classes.chartContainer}>
						<Table stickyHeader aria-label="simple table">
							<TableHead>
								<TableRow>
									<StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
										Keyword
									</StyledTableCell>
									<StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
										Avg. Monthly Search
									</StyledTableCell>
									<StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
										Competition
									</StyledTableCell>
									{/* <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
										Ad Impression share
									</StyledTableCell> */}
									<StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
										Top of page bid(low)
									</StyledTableCell>
									<StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
										Top of page bid(high)
									</StyledTableCell>
									{/* <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
										Acc. Status
									</StyledTableCell> */}
								</TableRow>
							</TableHead>
							{/* <Skeleton variant="rectangular" width={210} height={60} /> */}
							<TableBody>
								{keywordsList?.map((row, index) => {
									var {
										text,
										keyword_idea_metrics,
									} = row;
									return (
										<StyledTableRow
											sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
											style={{ cursor: "pointer" }}
											onClick={() => handelGrapData(keyword_idea_metrics?.monthly_search_volumes)}
										>
											<TableCell component="th" scope="row">
												{text}
											</TableCell>
											<TableCell component="th" scope="row">
												{keyword_idea_metrics?.avg_monthly_searches || "-"}
											</TableCell>
											<TableCell component="th" scope="row">
												{keyword_idea_metrics?.competition}
											</TableCell>
											{/* <TableCell component="th" scope="row">
												{keyword_idea_metrics.competition}
											</TableCell> */}
											<TableCell component="th" scope="row">
												{keyword_idea_metrics?.low_top_of_page_bid_micros.toLocaleString("en-US", { style: "currency", currency: "USD" }) || "-"}
											</TableCell>
											<TableCell component="th" scope="row">
												{keyword_idea_metrics?.high_top_of_page_bid_micros.toLocaleString("en-US", { style: "currency", currency: "USD" }) || "-"}
											</TableCell>
											{/* <TableCell component="th" scope="row">
												-
											</TableCell> */}
										</StyledTableRow>
									)
								})}
								{keywordsList.length === 0 ? (
									<TableRow>
										<TableCell
											sx={{
												color: "silver",
												textAlign: "center",
												paddingTop: "90px",
												borderBottom: "none",
												fontSize: "20px",
											}}
											colSpan="6"
										>
											No records to display
										</TableCell>
									</TableRow>

								) : (
									keywordsList.length === null ? (<TableRowsLoader rowsNum={10} />) : ("")
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<Stack
						sx={{ mt: 4, pb: 2 }}
						flexDirection="row"
						justifyContent="space-between"
					>
						<div>
							Showing{" "}
							{page * pagePerSize > totalRecords
								? totalRecords
								: page * pagePerSize}{" "}
							of {totalRecords} Results
						</div>
						<Pagination
							count={totalPages}
							page={page}
							onChange={handleChangePage}
							variant="outlined"
							shape="rounded"
						/>
					</Stack>
				</ThemeProvider>
			</ThemeProvider>

			<Dialog open={modalOpen} onClose={handleCloseModal} maxWidth='sm' >
				<SearchKeyword
					handleCloseModal={handleCloseModal}
					selectedLocations={selectedLocations}
					setSelectedLocations={setSelectedLocations}
					selectedKeywords={selectedKeywords}
					setSelectedKeywords={setSelectedKeywords}
					websiteUrl={websiteUrl}
					setWebsiteUrl={setWebsiteUrl}
					handelSubmit={handelSubmit}
					urlValidation={urlValidation}
					setUrlValidation={setUrlValidation}
					loading={loading}
					setLoading={setLoading}
				/>
			</Dialog>
			<Notification show={show} setShow={setShow} />
		</>
	)
}

export default KeywordPlaner