import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import './App.css';
import Layout from './components/Layout/Layout';
import UserLayout from './components/userLayout/Layout';
import LogIn from './pages/admin/login/LogIn';
import PrivacyPolicy from "./pages/privacypolicy/PrivacyPolicy";
import TermsOfService from "./pages/termsofservices/TermsOfService";
import { ThemeProvider } from "@mui/material";
import { theme } from "./helper";
import Login from "./pages/user/login/Login";
import ForgotPassword from "./pages/admin/login/ForgotPassword";
import SuccessMessage from "./pages/admin/login/SuccessMessage";
import UserForgotPassword from "./pages/user/login/UserForgotPassword";
import UserSuccessMessage from "./pages/user/login/UserSuccessMessage";
import PrivateRoute from "../src/Routes/PrivateRoute";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Ads from "./pages/Ads";
import FacebookAds from "./pages/facebookads"
import { loadUser } from "./Service/auth.service";
import { loginUserApi } from "./state/action/authAction";
import ChooseAccount from "./pages/admin/adschannel/google/ChooseAccount";
import GoogleAds from "./pages/GoogleAds";
import TiktokAds from "./pages/TiktokAds";
import Nodata from "./components/Nodata";

function App() {

  let routeElement = null;
  const location = useLocation();
  const dispatch = useDispatch();
  const pathname = location.pathname.substr(1);



  const { user } = useSelector((state) => state?.root?.auth);
  const isAuthenticate = useSelector((store) => store?.auth?.isAuthenticate);

  const slug = location.pathname.split("/")[1];

  const [roleName, setRoleName] = useState("admin");
  const Navigate = useNavigate();

  const getUsetData = async (data) => {
    let res = await loadUser(data);
    if (res?.status) {
      dispatch(loginUserApi(res?.data));
    }
  }


  useEffect(() => {
    if (
      pathname == "login"
    ) {
      if (user && user?.groups && user?.groups[0]?.name) {

        if (user?.groups && user?.groups[0]?.name == "admin" || user?.groups && user?.groups[0]?.name == "super_admin") {
          Navigate("/admin/")
        }
        if (user?.groups && user?.groups[0]?.name == "client") {
          Navigate("/dashboard")
        }
        if (user?.groups && user?.groups[0]?.name == "") {
          Navigate("/login")
        }
      }
    }
    setRoleName(user && user?.groups && user?.groups[0]?.name);

  }, [user]);

  useEffect(() => {
    if (
      pathname != "login"
    ) {
      const token = localStorage.getItem("token");
      const payload = {
        access_token: token,
      };
      getUsetData(payload);
    }
  }, []);

  useEffect(() => {
    if (slug == "") {
      if (!isAuthenticate) {
        Navigate("/login");
      }
    }
  }, [isAuthenticate, slug]);


  if (roleName) {
    routeElement =
      roleName == "admin" || roleName == "super_admin" ? (
        <PrivateRoute>
          <Layout />
        </PrivateRoute>
      ) : roleName == "client" ? (
        <PrivateRoute >
          <UserLayout />
        </PrivateRoute>
      ) : null;
  }

  return (
    <ThemeProvider theme={theme}>
      <div className='background_Image'>
        <Routes>
          <Route path="/adminLogin" element={<LogIn />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/success" element={<SuccessMessage />} />
          <Route path="/user-forgotPassword" element={<UserForgotPassword />} />
          <Route path="/user-success" element={<UserSuccessMessage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy page="privacy-policy" />} />
          <Route path="/terms-of-services" element={<TermsOfService page="terms-of-services" />} />
          <Route path="/tiktok" element={<TiktokAds page="terms-of-services" />} />
          <Route path="/facebook" element={<FacebookAds page="privacy-policy" />} />
          <Route path="/googlead" element={<GoogleAds page="terms-of-services" />} />
          <Route path="/linkedin" element={<Ads page="terms-of-services" />} />
          <Route path="/choose-account" element={<ChooseAccount page="terms-of-services" />} />
          <Route path="/404" element={<Nodata page="terms-of-services" />} />

          <Route path="*" element={routeElement} />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
