import axios from "axios";
import { useSelector } from "react-redux";

export const IsRead = (pageName) => {
    const user = useSelector((state) => state?.root?.auth)

    const elementIndex =
        user && user?.permissions?.findIndex((item) => item.name == pageName);

    if (user && user?.user && user?.user?.groups && user?.user?.groups[0]?.name && user?.user?.groups[0]?.name != "super_admin") {

        if (elementIndex == -1) {
            return false;
        } else {
            if (
                user &&
                user.permissions &&
                user.permissions[elementIndex] && user.permissions[elementIndex]?.view
            ) {
                return true;
            } else {
                return false;
            }
        }
    }
    else {
        return true;
    }
};

export const IsAdd = (pageName) => {
    const user = useSelector((state) => state?.root?.auth)

    const elementIndex =
        user && user?.permissions?.findIndex((item) => item.name == pageName);

    if (user && user?.user && user?.user?.groups && user?.user?.groups[0]?.name && user?.user?.groups[0]?.name != "super_admin") {

        if (elementIndex == -1) {
            return false;
        } else {
            if (
                user &&
                user.permissions &&
                user.permissions[elementIndex] && user.permissions[elementIndex]?.add
            ) {
                return true;
            } else {
                return false;
            }
        }
    }
    else {
        return true;
    }
};