import React from 'react'
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../../pages/user/dashboard/Dashboard';
import MyProfile from '../../pages/user/myprofile/MyProfile';
import Leads from '../../pages/user/leads/Leads';
import AdsChannels from '../../pages/user/adschannel/AdsChannels';
import Setting from '../../pages/user/setting/Setting';
import Report from '../../pages/user/report/Report';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Main = () => {
  const user = useSelector((state) => state?.root?.auth);
  const navigate = useNavigate();

  const pages = [
    { name: 'Dashboard', path: '/dashboard/', element: <Dashboard /> },
    { name: 'customuser', path: '/dashboard/myprofile', element: <MyProfile /> },
    { name: 'leads', path: '/dashboard/leads', element: <Leads /> },
    { name: 'Reporting', path: '/dashboard/report', element: <Report /> },
    { name: 'Channel', path: '/dashboard/platform', element: <AdsChannels /> },
    { name: 'Setting', path: '/dashboard/setting', element: <Setting /> },
  ]



  return (
    <Routes>
      {pages.map((page, index) => {
        const { name, path, element } = page;
        const permissions =
          user &&
          user.permissions &&
          user.permissions.find((permission) => {
            return permission.name == name;
          });

        if (user && user.user && user.user?.groups && user?.user?.groups[0] && user?.user?.groups[0]?.name && user?.user?.groups[0]?.name == "super_admin") {
          return (
            <Route key={index} exact path={path} element={element} />
          )
        } else {
          if (name == "leads" || name == "customuser") {

            if (permissions && permissions?.view) {
              return (
                <Route key={index} exact path={path} element={element} />
              )
            }
            else {
              // navigate("/404")
            }
          } else {
            return (
              <Route key={index} exact path={path} element={element} />
            )
          }
        }
      }
      )}

    </Routes>
  )
}

export default Main