import React from 'react';
import { facebook, facebookReconnect } from '../Service/social.service';
import CommonButton from "../components/common/Button";
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import { useStyles } from '../helper';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

function FacebookAds() {
    const classes = useStyles();
    const navigate = useNavigate();
    const [message, setMessage] = React.useState("Your Facebook accout is processing...");
    const [responseState, setResponseState] = React.useState(0);
    const [userIds, setUserIds] = React.useState("")

    const linkFacebook = (code, userId, reconnect) => {
        setTimeout(async () => {
            if (code) {
                let res = "";
                if (reconnect == "true") {
                    const payload = {
                        access_token: code
                    }
                    res = await facebookReconnect(userId, payload);
                } else if (reconnect == "false") {
                    const payload = {
                        user: userId,
                        access_token: code
                    }
                    res = await facebook(payload);
                
                }
                if (res.status == true) {
                    setResponseState(1);
                    setMessage(res.message)
                    localStorage.removeItem('isReconnect')

                } else {
                    setResponseState(2);
                    setMessage(res.message)
                }
            }
        }, 3000);
    }

    React.useEffect(() => {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const hashFragment = url.hash;
        let accessToken = "";
        let userId = "";
        if (hashFragment.includes('access_token')) {
            const params = hashFragment.slice(1).split('&');
            for (const param of params) {
                const [key, value] = param.split('=');
                if (key === 'access_token') {
                    accessToken = value;
                }
                if (key === 'state') {
                    userId = value;
                }
            }
        }
        const isReconnect = localStorage.getItem('isReconnect');
        if (userId) {
            setUserIds(userId)
        }

        linkFacebook(accessToken, userId, isReconnect)
    }, [])
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw' }}>
            <Card className={classes.cardContainer} sx={{ width: { xs: '300px', sm: '500px' }, height: 'unset', minWidth: { xs: '300px', sm: '500px' } }}>
                <div>
                    <Stack container justifyContent={'center'} alignItems={'center'} padding={3} gap={3}>
                        <Grid item xs={12}>
                            {responseState == 1 ? (
                                <CheckCircleOutlineIcon sx={{ color: '#0ea24a', fontSize: '100px' }} />
                            ) : ("")}
                            {responseState == 0 ? (
                                <AccessTimeFilledIcon sx={{ color: '#dfd800', fontSize: '100px' }} />
                            ) : ("")}
                            {responseState == 2 ? (
                                <HighlightOffIcon sx={{ color: '#dd0612', fontSize: '100px' }} />
                            ) : ("")}
                        </Grid>
                        <Grid item xs={12}>
                            {/* <Typography fontSize={{ xs: '18px', sm: '25px' }} fontWeight={'700'}>{message}</Typography> */}
                            <Typography fontSize={{ xs: '18px', sm: '25px' }} fontWeight={'700'} textAlign={'center'}>{message}</Typography>
                            {/* <Typography fontSize={{ xs: '18px', sm: '25px' }} fontWeight={'700'} textAlign={'center'}>Some Reasons Your Linkdin Account Not Connected</Typography> */}
                        </Grid>
                        <Grid item xs={12}>
                            {responseState != 0 ? (
                                <CommonButton buttonName='Back to home' fontSize='1rem' minWidth='40px' padding='4px 8px' onClick={() => navigate('/admin/client/details', {state:{
                                    id:userIds
                                } })} />
                            ) : ("")}
                        </Grid>
                    </Stack>
                </div>
            </Card>
        </Box>
    )
}

export default FacebookAds
