import { get, post, patch, getpagination, getAllResponse, deleteRequest } from "../web.request";

export const clientDropDown = async (data) => {
    return await get(`users/client_list_all`, data);
}

export const clientList = async (query) => {
    return await getAllResponse(`/users/client_list${query}`);
}

export const addClient = async (data) => {
    return await post(`/users/userdata/`, data);
}

export const clientDetails = async (id) => {
    return await get(`/users/client_info/${id}`);
}

export const updateClient = async (data, id) => {
    return await patch(`/users/userdata/${id}/`, data);
}

export const deleteClient = async (id) => {
    return await deleteRequest(`/users/userdata/${id}/`);
}

export const getAdminList = async (data) => {
    return await get(`/users/admin_list`, data);
}

export const syncClient = async (data) => {
    return await post(`/google_app/multi_google_accountadd`, data);
}