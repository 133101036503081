import React, { useState } from 'react'
import { tiktokadsrows, tiktokadscolumns, useStyles } from '../../../../helper'
import { DataGrid } from '@mui/x-data-grid'
import TableSearch from '../../../../components/common/Table/TableSearch';
import TiktokAdsChart from './TiktokAdsChart';
import {
  Grid, Stack, TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Pagination,
  Typography
} from '@mui/material';
import { styled } from "@mui/material/styles";
import { FaArrowUp } from "react-icons/fa6";
import { FaArrowDown } from "react-icons/fa6";



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#1987540a",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const TiktokAds = (props) => {
  const classes = useStyles();
  const { lebel1, lebel2, xLabels, fromDate, toDate, data, setSearch, pagePerSize, totalRecords, totalPages, page, setPage,
    shortKey,
    setShortKey,
    sortBy,
    setSortBy } = props

  const [sortCTR, setSortCTR] = useState(false);
  const [sortCPC, setSortCPC] = useState(false);
  const [sortImp, setSortImp] = useState(false);
  const [sortClick, setSortClick] = useState(false);
  const [sortCost, setSortCost] = useState(false);

  const handleChangePage = (event, value) => {
    setPage(value);
    // getClientList(value);
  };

  const handleSortClick = (name) => {
    switch (name) {
      case 'impressions':
        setSortImp((current) => !current)
        setSortCTR(false)
        setSortClick(false)
        setSortCost(false)
        setSortCPC(false)
        setSortBy(sortImp ? 1 : -1)
        setShortKey(name);
        break;
      case 'ctr':
        setSortCTR((current) => !current)
        setSortImp(false)
        setSortClick(false)
        setSortCost(false)
        setSortCPC(false)
        setSortBy(sortCTR ? 1 : -1)
        setShortKey(name);
        break;
      case 'clicks':
        setSortClick((current) => !current)
        setSortImp(false)
        setSortCTR(false)
        setSortCost(false)
        setSortCPC(false)
        setSortBy(sortClick ? 1 : -1)
        setShortKey(name);
        break;
      case 'spend':
        setSortCost((current) => !current)
        setSortClick(false)
        setSortImp(false)
        setSortCTR(false)
        setSortCPC(false)
        setSortBy(sortCost ? 1 : -1)
        setShortKey(name);
        break;
      case 'cpr':
        setSortCPC((current) => !current)
        setSortClick(false)
        setSortImp(false)
        setSortCTR(false)
        setSortCost(false)
        setSortBy(sortCPC ? 1 : -1)
        setShortKey(name);
        break;
      default:
        setSortClick((current) => !current)
        setSortImp(false)
        setSortCTR(false)
        setSortCost(false)
        setSortBy(sortClick ? 1 : -1)
        setShortKey(name);
        break;
    }
  };

  return (
    <>
      {/* <Grid className={classes.cardContainer}>
        <TiktokAdsChart lebel1={lebel1 ? lebel1 : ['']} lebel2={lebel2 ? lebel2 : ['']} xLabels={xLabels ? xLabels : ['']} />
      </Grid> */}

      <Stack justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: 1 }, marginTop: { xs: 2 }, flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 1 }, alignItems: { xs: 'start', sm: 'end' } }}>
        <Typography fontWeight='600' fontSize={{ xs: '15px', sm: '18px' }} color='var(--black)' >Campaigns</Typography>
        <TableSearch onChange={(e) => {
          setSearch(e.target.value)
          setPage(1)
        }} />
      </Stack>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Campaign Name
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }} onClick={() => handleSortClick('impressions')}>
                Impressions
                {sortImp ?
                  <FaArrowUp style={{ color: '#adadad' }} />
                  :
                  <FaArrowDown style={{ color: '#adadad' }} />
                }
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }} onClick={() => handleSortClick('clicks')}>
                Clicks
                {sortClick ?
                  <FaArrowUp style={{ color: '#adadad' }} />
                  :
                  <FaArrowDown style={{ color: '#adadad' }} />
                }
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }} onClick={() => handleSortClick('ctr')}>
                Click-Through-Rate(CTR)%
                {sortCTR ?
                  <FaArrowUp style={{ color: '#adadad' }} />
                  :
                  <FaArrowDown style={{ color: '#adadad' }} />
                }
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }} onClick={() => handleSortClick('cpr')}>
                Cost-Per-Click(CPC)$
                {sortCPC ?
                  <FaArrowUp style={{ color: '#adadad' }} />
                  :
                  <FaArrowDown style={{ color: '#adadad' }} />
                }
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Conversions
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }} onClick={() => handleSortClick('spend')}>
                Total Cost($)
                {sortCost ?
                  <FaArrowUp style={{ color: '#adadad' }} />
                  :
                  <FaArrowDown style={{ color: '#adadad' }} />
                }
              </StyledTableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, index) => {
              var {
                ad_id,
                ad_name,
                clicks,
                impressions,
                reach,
                ctr,
                cpr,
                conversion,
                spend,
                hedged_cost,
                actual_CPC

              } = row;

              return (
                <StyledTableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell component="th" scope="row">
                    {ad_name}
                  </TableCell>
                  <TableCell>{impressions}</TableCell>
                  <TableCell>{clicks}</TableCell>
                  <TableCell>
                    %{ctr}
                  </TableCell>
                  <TableCell>
                    {cpr.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                  </TableCell>
                  <TableCell>
                    {conversion}
                  </TableCell>
                  <TableCell>
                    {spend ? spend.toLocaleString("en-US", { style: "currency", currency: "USD" }) : hedged_cost.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                  </TableCell>
                </StyledTableRow>
              );
            })}
            {data?.length === 0 ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="6"
                >
                  No records to display
                </TableCell>
              </TableRow>
            ) : (
              ""
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          Showing{" "}
          {data && data.length}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  )
}

export default TiktokAds
