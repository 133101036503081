import React, { useEffect, useState } from 'react'
import PageHeading from '../../../components/common/PageHeading'
import { ThemeProvider } from '@mui/material/styles';
import { budgetcolumns, budgetrows, theme, useStyles } from '../../../helper'
import { DataGrid } from '@mui/x-data-grid'
import {
    Grid, Stack, Typography, TableContainer,
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Chip,
    Pagination,
    Box
} from '@mui/material';
import BudgetChart from './BudgetChart';
import DateRange from '../../../components/common/Table/DateRange';
import TableDropDown from '../../../components/common/Table/TableDropDown';
import { styled } from "@mui/material/styles";
import Slider from '@mui/material/Slider';
import CommonInput from '../../../components/common/Input';
import { useSelector } from 'react-redux';
import Notification from '../../../components/common/Notification/Notification';
import { getBudgetFacebookList, saveBudget, getBudgetInstaList, getBudgetgoogleSEMList, getBudgetgoogleGDMList, getBudgetgoogleYouTubeList, getBudgetTikTokList, getBudgetLinkedInList } from '../../../Service/budget.service';
import moment from 'moment';
import Skeleton from '@mui/material/Skeleton';

const marks = [
    {
        value: 0,
        label: '0%',
    },
    {
        value: 100,
        label: '100%',
    },
];

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#1987540a",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    color: "#0F0F0F80",
}));

const StyledChip = styled(Chip)(() => ({
    borderRadius: "10px",
    fontSize: "14px",
}));

const BudgetCalculator = () => {
    const classes = useStyles();

    const [channels, setChannels] = useState(null);
    const [fromDate, setFromDate] = useState('');
    const [days, setDays] = useState(7);
    const [toDate, setToDate] = useState('');
    const [weekDayStartName, setWeekDayStartName] = useState('');
    const [monthStartName, setMonthStartName] = useState('');
    const [weekDayEndName, setWeekDayEndName] = useState('');
    const [monthEndName, setMonthEndName] = useState('');
    const [search, setSearch] = useState('');
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [pagePerSize, setPagePerSize] = useState(5);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [show, setShow] = useState({
        show: false,
        type: '',
        message: ''
    });
    const [tableListData, setTableListData] = useState(null);
    const { client } = useSelector((state) => state?.root);

    useEffect(() => {
        if (client && client?.clientId) {
            let query = `?page=${page}&page_size=${pagePerSize}&start_date=${moment(fromDate).format('YYYY-MM-DD')}&end_date=${moment(toDate).format('YYYY-MM-DD')}&search=${search}`;
            if (channels === "facebook" && client && client?.clientId) {
                getFacebookTableData(query, client && client?.clientId);
            } else if (channels === "instagram" && client && client?.clientId) {
                getInstaTableData(query, client && client?.clientId)
            } else if (channels === "google_sem" && client && client?.clientId) {
                getGoogleSEMTableData(query, client && client?.clientId)
            } else if (channels === "google_gdn" && client && client?.clientId) {
                getGoogleGDMTableData(query, client && client?.clientId)
            } else if (channels === "google_youtube" && client && client?.clientId) {
                getGoogleyoutubeableData(query, client && client?.clientId)
            } else if (channels === "tiktok" && client && client?.clientId) {
                getTikToktableData(query, client && client?.clientId)
            } else if (channels === "linkedin" && client && client?.clientId) {
                getLinkedIntableData(query, client && client?.clientId)
            }
        } else {
            setShow({
                show: true,
                type: 'error',
                message: 'Please Select Client'
            })
        }
    }, [channels, fromDate, toDate, client, page])

    const getFacebookTableData = async (qry, clientId) => {
        const fbres = await getBudgetFacebookList(qry, clientId);
        if (fbres?.data?.status) {
            setTimeout(() => {
                setTableListData(fbres?.data?.data);
                setTotalRecords(fbres?.data?.count);
                setTotalPages(Math.ceil(fbres?.data?.count / pagePerSize));
            }, 2000);
        } else {
            setTableListData(null);
            setTotalRecords(0);
            setTotalPages(0);
            setShow({
                show: true,
                type: 'error',
                message: fbres?.data?.message || 'Please Select Date'
            })
        }
    }

    const getInstaTableData = async (qry, clientId) => {
        setTimeout(async () => {
            const instares = await getBudgetInstaList(qry, clientId);
            if (instares?.data?.status) {
                setTimeout(() => {
                    setTableListData(instares?.data?.data);
                    setTotalRecords(instares?.data?.count);
                    setTotalPages(Math.ceil(instares?.data?.count / pagePerSize));
                }, 2000);
            } else {
                setTableListData(null);
                setTotalRecords(0);
                setTotalPages(0);
                setShow({
                    show: true,
                    type: 'error',
                    message: instares?.data?.message || 'Please Select Date'
                })
            }
        }, 2000);
    }

    const getGoogleSEMTableData = async (qry, clientId) => {
        const goglSEM = await getBudgetgoogleSEMList(qry, clientId);
        if (goglSEM?.data?.status) {
            setTimeout(() => {
                setTableListData(goglSEM?.data?.data);
                setTotalRecords(goglSEM?.data?.count);
                setTotalPages(Math.ceil(goglSEM?.data?.count / pagePerSize));
            }, 2000);
        } else {
            setTableListData(null);
            setTotalRecords(0);
            setTotalPages(0);
            setShow({
                show: true,
                type: 'error',
                message: goglSEM?.data?.message || 'Please Select Date'
            })
        }
    }

    const getGoogleGDMTableData = async (qry, clientId) => {
        const goglGDM = await getBudgetgoogleGDMList(qry, clientId);
        if (goglGDM?.data?.status) {
            setTimeout(() => {
                setTableListData(goglGDM?.data?.data);
                setTotalRecords(goglGDM?.data?.count);
                setTotalPages(Math.ceil(goglGDM?.data?.count / pagePerSize));
            }, 2000);
        } else {
            setTableListData(null);
            setTotalRecords(0);
            setTotalPages(0);
            setShow({
                show: true,
                type: 'error',
                message: goglGDM?.data?.message || 'Please Select Date'
            })
        }
    }

    const getGoogleyoutubeableData = async (qry, clientId) => {
        const goglYouTybe = await getBudgetgoogleYouTubeList(qry, clientId);
        if (goglYouTybe?.data?.status) {
            setTimeout(() => {
                setTableListData(goglYouTybe?.data?.data);
                setTotalRecords(goglYouTybe?.data?.count);
                setTotalPages(Math.ceil(goglYouTybe?.data?.count / pagePerSize));
            }, 2000);
        } else {
            setTableListData(null);
            setTotalRecords(0);
            setTotalPages(0);
            setShow({
                show: true,
                type: 'error',
                message: goglYouTybe?.data?.message || 'Please Select Date'
            })
        }
    }

    const getTikToktableData = async (qry, clientId) => {
        const getTiktok = await getBudgetTikTokList(qry, clientId);
        if (getTiktok?.data?.status) {
            setTimeout(() => {
                setTableListData(getTiktok?.data?.data);
                setTotalRecords(getTiktok?.data?.count);
                setTotalPages(Math.ceil(getTiktok?.data?.count / pagePerSize));
            }, 2000);
        } else {
            setTableListData(null);
            setTotalRecords(0);
            setTotalPages(0);
            setShow({
                show: true,
                type: 'error',
                message: getTiktok?.data?.message || 'Please Select Date'
            })
        }
    }

    const getLinkedIntableData = async (qry, clientId) => {
        const getLinkedIn = await getBudgetLinkedInList(qry, clientId);
        if (getLinkedIn?.data?.status) {
            setTimeout(() => {
                setTableListData(getLinkedIn?.data?.data);
                setTotalRecords(getLinkedIn?.data?.count);
                setTotalPages(Math.ceil(getLinkedIn?.data?.count / pagePerSize));
            }, 2000);
        } else {
            setTableListData(null);
            setTotalRecords(0);
            setTotalPages(0);
            setShow({
                show: true,
                type: 'error',
                message: getLinkedIn?.data?.message || 'Please Select Date'
            })
        }
    }

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const changeBudget = (e, index, id) => {
        let newArr = [...tableListData]; // copying the old datas array
        newArr[index]["original_budget"] = e.target.value;
        setTableListData(newArr);
    };

    const saveChange = async (value, id) => {
        const payload = {
            "original_budget": value,
            channel: channels
        }
        const updateBudget = await saveBudget(payload, id);
        if (updateBudget.status) {
            let query = `?page=${page}&page_size=${pagePerSize}&start_date=${moment(fromDate).format('YYYY-MM-DD')}&end_date=${moment(toDate).format('YYYY-MM-DD')}&search=${search}`;
            if (channels === "facebook" && client && client?.clientId) {
                getFacebookTableData(query, client && client?.clientId);
            } else if (channels === "instagram" && client && client?.clientId) {
                getInstaTableData(query, client && client?.clientId)
            } else if (channels === "google_sem" && client && client?.clientId) {
                getGoogleSEMTableData(query, client && client?.clientId)
            } else if (channels === "google_gdn" && client && client?.clientId) {
                getGoogleGDMTableData(query, client && client?.clientId)
            } else if (channels === "google_youtube" && client && client?.clientId) {
                getGoogleyoutubeableData(query, client && client?.clientId)
            } else if (channels === "tiktok" && client && client?.clientId) {
                getTikToktableData(query, client && client?.clientId)
            } else if (channels === "linkedin" && client && client?.clientId) {
                getLinkedIntableData(query, client && client?.clientId)
            }
            setShow({
                show: true,
                type: 'success',
                message: updateBudget?.message || 'Updated successfully'
            })
        } else {
            setShow({
                show: true,
                type: 'error',
                message: updateBudget?.message || 'Something goes wrong'
            })
        }
    }

    const TableRowsLoader = ({ rowsNum }) => {
        return [...Array(rowsNum)].map((row, index) => (
            <TableRow key={index}>
                <TableCell component="th" scope="row">
                    <Skeleton animation="wave" variant="text" width={100} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton animation="wave" variant="text" width={100} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton animation="wave" variant="text" width={100} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton animation="wave" variant="text" width={100} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton animation="wave" variant="text" width={100} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton animation="wave" variant="text" width={100} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton animation="wave" variant="text" width={100} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton animation="wave" variant="text" width={100} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton animation="wave" variant="text" width={100} height={20} />
                </TableCell>
            </TableRow>
        ));
    }

    const handleHangingPerNew = async (value, index, id) => {
        const payload = {
            "hedging_percentage": value || 0,
            channel: channels
        }
        const updateBudget = await saveBudget(payload, id);
        if (updateBudget.status) {
            let query = `?page=${page}&page_size=${pagePerSize}&start_date=${moment(fromDate).format('YYYY-MM-DD')}&end_date=${moment(toDate).format('YYYY-MM-DD')}&search=${search}`;
            if (channels === "facebook" && client && client?.clientId) {
                getFacebookTableData(query, client && client?.clientId);
            } else if (channels === "instagram" && client && client?.clientId) {
                getInstaTableData(query, client && client?.clientId)
            } else if (channels === "google_sem" && client && client?.clientId) {
                getGoogleSEMTableData(query, client && client?.clientId)
            } else if (channels === "google_gdn" && client && client?.clientId) {
                getGoogleGDMTableData(query, client && client?.clientId)
            } else if (channels === "google_youtube" && client && client?.clientId) {
                getGoogleyoutubeableData(query, client && client?.clientId)
            } else if (channels === "tiktok" && client && client?.clientId) {
                getTikToktableData(query, client && client?.clientId)
            } else if (channels === "linkedin" && client && client?.clientId) {
                getLinkedIntableData(query, client && client?.clientId)
            }
            setShow({
                show: true,
                type: 'success',
                message: updateBudget?.message || 'Updated successfully'
            })
        } else {
            setShow({
                show: true,
                type: 'error',
                message: updateBudget?.message || 'Something goes wrong'
            })
        }
    }

    const handleSliderChnage = (e, index, id) => {
        let newArr = [...tableListData]; // copying the old datas array
        newArr[index]["hedging_percentage"] = e.target.value;
        setTableListData(newArr);
    }



    return (
        <>
            <PageHeading heading='Budget Calculator' />

            <Stack direction="row" alignItems="center" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: 1 } }}>
                <Grid container justifyContent='flex-end' alignItems='center' gap={{ xs: '5px', sm: '10px' }} width="inherit">
                    <Grid item>
                        <TableDropDown channels={channels} setChannels={setChannels} />
                    </Grid>
                    <Grid item>
                        <DateRange setFromDate={setFromDate} fromDate={fromDate} setToDate={setToDate} toDate={toDate} setDays={setDays} days={days} setWeekDayStartName={setWeekDayStartName} setMonthStartName={setMonthStartName} setWeekDayEndName={setWeekDayEndName} setMonthEndName={setMonthEndName} />
                    </Grid>
                </Grid>
            </Stack>


            <ThemeProvider theme={theme}>
                <TableContainer component={Paper} className={classes.chartContainer}>
                    <Table stickyHeader aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                                    Campaign Name
                                </StyledTableCell>
                                <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                                    Original Budget
                                </StyledTableCell>
                                <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                                    Spent
                                </StyledTableCell>
                                <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                                    Hedging Percentage
                                </StyledTableCell>
                                <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                                    Reported Spent
                                </StyledTableCell>
                                <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                                    Actual CPC
                                </StyledTableCell>
                                <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                                    Reported CPC
                                </StyledTableCell>
                                <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                                    Remaining
                                </StyledTableCell>
                                {/* <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                                    Currency
                                </StyledTableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableListData?.map((row, index) => {
                                const { id, ad_name, original_budget, spend, hedging_percentage, reported_budget, actual_CPC, reported_CPC, remaining, currencyCode } = row
                                return (
                                    <StyledTableRow
                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {ad_name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <CommonInput type='number' value={original_budget || ""} name="original_budget" onBlur={() => saveChange(original_budget, row.id)} onChange={(e) => changeBudget(e, index, row.id)} border={'1.5px solid transparent'} focusBorder='1.5px solid var(--secondary)' />
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {spend}
                                        </TableCell>
                                        <TableCell component="th" scope="row" sx={{ margin: '0 20px' }}>
                                            <Slider
                                                sx={{ marginTop: '20px' }}
                                                aria-label="Always visible"
                                                value={hedging_percentage}
                                                marks={marks}
                                                step={10}
                                                valueLabelDisplay="on"
                                                onChange={(e) => handleSliderChnage(e, index, row.id)}
                                                onChangeCommitted={(e) => handleHangingPerNew(hedging_percentage, index, row.id)}
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {reported_budget ? reported_budget.toLocaleString("en-US", { style: "currency", currency: "USD" }) : "0.00"}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {actual_CPC ? actual_CPC.toLocaleString("en-US", { style: "currency", currency: "USD" }) : "0.00"}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {reported_CPC ? reported_CPC.toLocaleString("en-US", { style: "currency", currency: "USD" }) : "0.00"}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {remaining ? (remaining).toFixed(2) : "0.00"}
                                        </TableCell>
                                        {/* <TableCell component="th" scope="row">
                                            {currencyCode}
                                        </TableCell> */}
                                    </StyledTableRow>
                                )
                            })}
                            {tableListData === null ? (
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            color: "silver",
                                            textAlign: "center",
                                            paddingTop: "90px",
                                            borderBottom: "none",
                                            fontSize: "20px",
                                        }}
                                        colSpan="9"
                                    >
                                        No records to display
                                    </TableCell>
                                </TableRow>

                            ) : (
                                tableListData.length === 0 ? (<TableRowsLoader rowsNum={10} />) : ("")
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Stack
                    sx={{ mt: 4, pb: 2 }}
                    flexDirection="row"
                    justifyContent="space-between"
                >
                    <div>
                        Showing{" "}
                        {page * pagePerSize > totalRecords
                            ? totalRecords
                            : page * pagePerSize}{" "}
                        of {totalRecords} Results
                    </div>
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handleChangePage}
                        variant="outlined"
                        shape="rounded"
                    />
                </Stack>
            </ThemeProvider>
            <Notification show={show} setShow={setShow} />
        </>
    )
}

export default BudgetCalculator