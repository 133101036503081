import React, { useState, useEffect } from "react";
import { useStyles } from "../../../helper";
import {
  Grid,
  Stack,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Pagination,
  DialogContent,
  Typography,
  DialogActions,
  Dialog,
  DialogTitle,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Menu } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {
  MoreVertIcon,
  EditIcon,
  DeleteIcon,
  LinkIcon,
  SendIcon,
} from "../../../assets/icons/Icons";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Skeleton from "@mui/material/Skeleton";
import Notification from "../../../components/common/Notification/Notification";
import { useSelector } from "react-redux";
import {
  previousReportList,
  sendReportToClient,
  deleteReport,
  updateReportName,
} from "../../../Service/reports.service";
import CommonButton from "../../../components/common/Button";
import CommonInput from "../../../components/common/Input";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#1987540a",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const PreviousReport = () => {
  const classes = useStyles();
  const [previousReportData, setPreviousReportData] = useState([]);
  const [page, setPage] = useState(1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [show, setShow] = useState({
    show: false,
    type: "",
    message: "",
  });
  const [sendReportId, setSendReportId] = useState(null);
  const [DeleteReportId, setDeleteReportId] = useState(null);
  const [reportUrl, setReportUrl] = useState(null);
  const [openSendPopup, setOpenSendPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [showNoData, setShowNoData] = useState(false);
  const [search, setSearch] = useState("");
  const { client } = useSelector((state) => state?.root);

  // For edit report name
  const [editingReportName, setEditReportingName] = useState(null);
  const [editName,setEditName] = useState("");

  const editNameApiHandler = async (id,value,oldValue) =>{
    let inputValue = value;
    if(value.trim() === ""){
      inputValue =oldValue;
    }
    const payload = {
      repor_name:inputValue
    }
    const {data,message,status} = await updateReportName(id,payload);
    if(status){
      getPreviousReports(client?.clientId, "");
    }
    setEditReportingName(null)
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    getPreviousReports(client?.clientId, "");
  };

  const getPreviousReports = async (id, searchtext) => {
    let query = `?page=${page}&search=${searchtext || ""}`;
    setShowNoData(false);
    const res = await previousReportList(id, query);
    const { data } = res;
    if (data.status) {
      setTimeout(() => {
        setPreviousReportData(data?.data);
        setTotalRecords(data?.count);
        setTotalPages(Math.ceil(data?.count / pagePerSize));
      }, 1000);
    } else {
      setShowNoData(true);
      setPreviousReportData([]);
      setTotalRecords(0);
      setTotalPages(0);
    }
  };

  const handelSendReport = async () => {
    setAnchorEl(null);
    if (sendReportId) {
      const payload = {
        send: true,
      };
      const sendRes = await sendReportToClient(payload, sendReportId);
      if (sendRes.status == true) {
        setOpenSendPopup(false);
        getPreviousReports(client?.clientId, "");
        setSendReportId(null);
        setShow({
          show: true,
          type: "success",
          message: sendRes.message || "Client deleted successfully",
        });
      } else {
        setOpenSendPopup(false);
        setShow({
          show: true,
          type: "error",
          message: sendRes.message || "Something went wrong",
        });
      }
    } else {
      setOpenSendPopup(false);
      setShow({
        show: true,
        type: "error",
        message: "Report not selected",
      });
    }
  };

  const handleDownloadReport = async () => {
    try {
      const response = await fetch(reportUrl);
      const blob = await response.blob();
      const objectURL = URL.createObjectURL(blob);
      const myArray = reportUrl.split("report_pdf/");
      const link = document.createElement("a");
      link.href = objectURL;
      link.download = myArray[1];
      link.click();

      URL.revokeObjectURL(objectURL);
      setAnchorEl(null);
    } catch (error) {
      setShow({
        show: true,
        type: "error",
        message: "No PDF file found",
      });
    }
  };

  const handleCopyToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(reportUrl)
        .then(() => {
          setAnchorEl(null);
          setShow({
            show: true,
            type: "success",
            message: "Link is copied to clipboard",
          });
        })
        .catch((error) => console.error("Unable to copy to clipboard", error));
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = reportUrl;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      setShow({
        show: true,
        type: "success",
        message: "Link is copied to clipboard",
      });
    }
  };

  const handleOpenSendModal = () => {
    setOpenSendPopup(true);
  };
  const handleCloseSendModal = () => {
    setOpenSendPopup(false);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeletePopup(false);
  };

  const clodehandleSend = () => {
    setOpenSendPopup(false);
    setAnchorEl(null);
  };

  const handleDeleteConfirm = async () => {
    if (DeleteReportId) {
      const deleteRes = await deleteReport(sendReportId);
      if (deleteRes.status == true) {
        setOpenDeletePopup(false);
        getPreviousReports(client?.clientId, "");
        setDeleteReportId(null);
        setAnchorEl(null);
        setShow({
          show: true,
          type: "success",
          message: deleteRes.message || "Client deleted successfully",
        });
      } else {
        setOpenDeletePopup(false);
        setShow({
          show: true,
          type: "error",
          message: deleteRes.message || "Something went wrong",
        });
      }
    } else {
      setOpenDeletePopup(false);
      setShow({
        show: true,
        type: "error",
        message: "Report not selected",
      });
    }
  };

  const getSearchKeywords = (e) => {
    setSearch(e.target.value);
    if (e.target.value.length > 3) {
      getPreviousReports(client?.clientId, e.target.value);
    } else if (e.target.value.length == 0) {
      getPreviousReports(client?.clientId, "");
    }
  };

  useEffect(() => {
    if (client && client?.clientId) {
      getPreviousReports(client?.clientId, "");
    } else {
      setShow({
        show: true,
        type: "error",
        message: "Please Select Client",
      });
    }
  }, [client, page]);

  const TableRowsLoader = ({ rowsNum }) => {
    return [...Array(rowsNum)].map((row, index) => (
      <TableRow key={index}>
        <TableCell component="th" scope="row">
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <>
      {/* <PageHeading heading='Previous Report'/> */}
      {/* <ReportTable/> */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        sx={{
          margin: { xs: "8px 0" },
          flexDirection: { xs: "column-reverse", sm: "row" },
          gap: "8px",
          alignItems: "start",
        }}
      >
        {/* <TableSearch /> */}

        <TextField
          value={search}
          hiddenLabel
          placeholder="Search"
          xs={{ width: "100% !important" }}
          onChange={getSearchKeywords}
        />
        {/* <Grid container justifyContent='flex-end' alignItems='center'  gap={{xs:'5px',sm:'10px'}} width="inherit">
          <Grid item>
            <DeleteModal head='Report' para='Report' />
          </Grid>
          <Grid item>
            <SendReport />
          </Grid>
          <Grid item>
            <ReportUrl />
          </Grid>
        </Grid> */}
      </Stack>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow onClick={()=>setEditReportingName(null)}>
              <StyledTableCell sx={{ fontWeight: "700", color: "#000" }}>
                Report Name
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "700", color: "#000" }}>
                Report Date
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "700", color: "#000" }}>
                Created At
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "700", color: "#000" }}>
                Status
              </StyledTableCell>
              <StyledTableCell
                sx={{ fontWeight: "700", color: "#000" }}
              ></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {previousReportData?.map((row, index) => {
              var {
                id,
                repor_name,
                creat_date,
                is_active,
                start_date,
                end_date,
                send,
              } = row;
              // var status = getStatus(is_active);
              var reportDates = `${start_date} to ${end_date}`;
              var statusText = send ? "Sended" : "Generated";
              return (
                <StyledTableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  // onClick={(e) => navigate(`/admin/client/details`, { state: { id: id } })}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell component="th" scope="row">
                    {editingReportName === id ? (
                      <CommonInput
                        type="Text"
                        inputProps={{ maxLength: 35 }}
                        value={editName || ""}
                        onBlur={() => editNameApiHandler(id,editName,repor_name)}
                        onChange={(e) => setEditName(e.target.value)}
                        // border={"1.5px solid transparent"}
                        border="1.5px solid var(--secondary)"
                      />
                    ) : (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => [setEditReportingName(id),setEditName(repor_name)]}
                      >
                        {repor_name}
                      </div>
                    )}
                  </TableCell>
                  <TableCell>{reportDates}</TableCell>
                  <TableCell>{creat_date}</TableCell>
                  <TableCell>{statusText}</TableCell>
                  <TableCell
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      alignItems={"center"}
                      flexWrap={"nowrap"}
                    >
                      <Grid item>
                        {/* <ClientMoreBtn /> */}
                        <FormControl fullWidth>
                          <MoreVertIcon
                            onClick={(e) => {
                              handleClick(e);
                              setSendReportId(row.id);
                              setReportUrl(row.pdf_file);
                              setDeleteReportId(row.id);
                            }}
                            sx={{ cursor: "pointer" }}
                          />
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem onClick={() => handleCopyToClipboard()}>
                              <Grid container spacing={1}>
                                <Grid item>
                                  <LinkIcon
                                    sx={{ width: "20px", height: "20px" }}
                                  />
                                </Grid>
                                <Grid item>Link</Grid>
                              </Grid>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleOpenSendModal();
                              }}
                            >
                              <Grid container spacing={1}>
                                <Grid item>
                                  <SendIcon
                                    sx={{ width: "20px", height: "20px" }}
                                  />
                                </Grid>
                                <Grid item>Send</Grid>
                              </Grid>
                            </MenuItem>
                            <MenuItem onClick={handleDownloadReport}>
                              <Grid container spacing={1}>
                                <Grid item>
                                  <ArrowDownwardIcon
                                    sx={{ width: "20px", height: "20px" }}
                                  />
                                </Grid>
                                <Grid item>Download</Grid>
                              </Grid>
                            </MenuItem>
                            <MenuItem onClick={() => setOpenDeletePopup(true)}>
                              <Grid container spacing={1}>
                                <Grid item>
                                  <DeleteIcon
                                    sx={{ width: "20px", height: "20px" }}
                                  />
                                </Grid>
                                <Grid item>Delete</Grid>
                              </Grid>
                            </MenuItem>
                          </Menu>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </TableCell>
                </StyledTableRow>
              );
            })}

            {showNoData ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="6"
                >
                  No records to display
                </TableCell>
              </TableRow>
            ) : previousReportData.length === 0 ? (
              <TableRowsLoader rowsNum={10} />
            ) : (
              ""
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>

      <Dialog
        open={openSendPopup}
        onClose={handleCloseSendModal}
        sx={{ "& .MuiDialog-paper": { width: "500px" } }}
      >
        <DialogTitle>
          Send Report
          {/* <IconButton
						aria-label="close"
						onClick={handleCloseModal}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: 'grey',
						}}
					>
						<CloseIcon />
					</IconButton> */}
        </DialogTitle>
        <DialogActions>
          <CommonButton onClick={() => clodehandleSend()} buttonName="No" />
          <CommonButton onClick={() => handelSendReport()} buttonName="Yes" />
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDeletePopup}
        onClose={handleCloseDeleteModal}
        sx={{ "& .MuiDialog-paper": { width: "500px" } }}
      >
        <DialogTitle>Delete Report</DialogTitle>
        <DialogContent dividers>
          <Typography
            className="myprofile_input_label"
            sx={{ paddingBottom: "2px" }}
          >
            Are You Sure you want to delete?
          </Typography>
        </DialogContent>
        <DialogActions>
          <CommonButton onClick={handleCloseDeleteModal} buttonName="No" />
          <CommonButton
            onClick={() => handleDeleteConfirm()}
            buttonName="Yes"
          />
        </DialogActions>
      </Dialog>

      <Notification show={show} setShow={setShow} />
    </>
  );
};

export default PreviousReport;
