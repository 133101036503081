import React, { useState, useEffect } from 'react'
import TableSearch from '../../../../../../components/common/Table/TableSearch';
import {
    Grid, Stack, TableContainer,
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Pagination
} from '@mui/material';
import { styled } from "@mui/material/styles";
import { useStyles } from '../../../../../../helper';
import { FaArrowUp } from "react-icons/fa6";
import { FaArrowDown } from "react-icons/fa6";
import { getAdsOfListSEM, getAdsOfListGSM, getAdsOfListYouTube } from "../../../../../../Service/facebook.service";
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#1987540a",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    color: "#0F0F0F80",
}));

const Gdn = (props) => {
    const {
        lebel1,
        lebel2,
        xLabels,
        fromDate,
        toDate,
        listOfAdsAll,
        setListOfAdsAll,
        googleChannel,
        pageAd,
        setPageAd,
        pagePerSizeAd,
        setPagePerSizeAd,
        totalRecordsAd,
        setTotalRecordsAd,
        totalPagesAd,
        setTotalPagesAd,

        adShortBy,
        adShortByField,
        setAdShortBy,
        setAdShortByField,
        setAdRowData,
        adRowData,
        handleAdGroupClick,
        pagePerSize
    } = props;
    const classes = useStyles();

    const [sortAdCTR, setSortAdCTR] = useState(false);
    const [sortAdCPC, setSortAdCPC] = useState(false);
    const [sortAdImp, setSortAdImp] = useState(false);
    const [sortAdClick, setSortAdClick] = useState(false);
    const [sortAdCost, setSortAdCost] = useState(false);
    // 
    const [conversions, setConversions] = useState(false);

    const handleSortClick = (name) => {
        switch (name) {
            case 'impressions':
                setSortAdImp((current) => !current)
                setSortAdCTR(false)
                setSortAdCPC(false)
                setSortAdClick(false)
                setSortAdCost(false)
                setConversions(false);
                setAdShortBy(sortAdImp ? 1 : -1)
                setAdShortByField(name);
                // handleClickonGroup(adRowData);
                break;
            case 'clicks':
                setSortAdClick((current) => !current)
                setSortAdImp(false)
                setSortAdCTR(false)
                setSortAdCPC(false)
                setSortAdCost(false)
                setConversions(false);
                setAdShortBy(sortAdClick ? 1 : -1)
                setAdShortByField(name);
                // handleClickonGroup(adRowData);
                break;
            case 'ctr':
                setSortAdCTR((current) => !current)
                setSortAdClick(false)
                setSortAdImp(false)
                setSortAdCPC(false)
                setSortAdCost(false)
                setConversions(false);
                setAdShortBy(sortAdCTR ? 1 : -1)
                setAdShortByField(name);
                // handleClickonGroup(adRowData);
                break;
            case 'averageCpc':
                setSortAdCPC((current) => !current)
                setSortAdCTR(false)
                setSortAdClick(false)
                setSortAdImp(false)
                setSortAdCost(false)
                setConversions(false);
                setAdShortBy(sortAdCPC ? 1 : -1)
                setAdShortByField(name);
                // handleClickonGroup(adRowData);
                break;
            case 'costMicros':
                setSortAdCost((current) => !current)
                setSortAdCPC(false)
                setSortAdCTR(false)
                setSortAdClick(false)
                setSortAdImp(false)
                setSortAdCost(false)
                setConversions(false);
                setAdShortBy(sortAdCost ? 1 : -1)
                setAdShortByField(name);
                // handleClickonGroup(adRowData);
                break;
            case 'conversions':
                setSortAdCost(false)
                setSortAdCPC(false)
                setSortAdCTR(false)
                setSortAdClick(false)
                setSortAdImp(false)
                setSortAdCost(false)
                setConversions((current) => !current);
                setAdShortBy(conversions ? 1 : -1)
                setAdShortByField(name);
                // handleClickonGroup(adRowData);
                break;
            default:
                setSortAdImp((current) => !current)
                setSortAdCTR(false)
                setSortAdCPC(false)
                setSortAdClick(false)
                setSortAdCost(false)
                setAdShortBy(sortAdClick ? 1 : -1)
                setAdShortByField(name);
                // handleClickonGroup(adRowData);
                break;
        }
    };

    useEffect(() => {
        handleClickonGroup(adRowData);
    }, [sortAdCTR, sortAdCPC, sortAdImp, sortAdClick, sortAdCost, conversions])

    const handleClickonGroup = async (clickData) => {
        if (clickData) {
            const qry = `?start_date=${fromDate}&end_date=${toDate}&page=${pageAd}&filter_field=${adShortByField}&filter_type=${adShortBy}`;
            let getads = [];
            if (googleChannel == 0) {
                getads = await getAdsOfListSEM(qry, clickData.id);
            }
            if (googleChannel == 1) {
                getads = await getAdsOfListGSM(qry, clickData.id);
            }
            if (googleChannel == 2) {
                getads = await getAdsOfListYouTube(qry, clickData.id);
            }
            const { data } = getads;
            if (data?.status == true) {
                setListOfAdsAll(data.data)
                setTotalRecordsAd(data?.count);
                setTotalPagesAd(Math.ceil(data?.count / pagePerSize));
                // handleAdGroupClick();
            } else {
                setListOfAdsAll([])
                setTotalRecordsAd(0);
                setTotalPagesAd(0);
            }
        }
    }


    return (
        <>
            <Stack justifyContent="end" width='100%' sx={{ marginBottom: { xs: 1 }, marginTop: { xs: 2 }, flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 1 }, alignItems: { xs: 'start', sm: 'end' } }}>
                <TableSearch />
            </Stack>
            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                                Ad
                            </StyledTableCell>
                            <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                                Status
                            </StyledTableCell>
                            <StyledTableCell sx={{ fontWeight: '700', color: '#000', cursor: 'pointer' }} onClick={() => handleSortClick('impressions')}>
                                Impressions
                                {sortAdImp ?
                                    <FaArrowUp style={{ color: '#adadad' }} />
                                    :
                                    <FaArrowDown style={{ color: '#adadad' }} />
                                }
                            </StyledTableCell>
                            <StyledTableCell sx={{ fontWeight: '700', color: '#000', cursor: 'pointer' }} onClick={() => handleSortClick('clicks')}>
                                Clicks
                                {sortAdClick ?
                                    <FaArrowUp style={{ color: '#adadad' }} />
                                    :
                                    <FaArrowDown style={{ color: '#adadad' }} />
                                }
                            </StyledTableCell>
                            <StyledTableCell sx={{ fontWeight: '700', color: '#000', cursor: 'pointer' }} onClick={() => handleSortClick('ctr')}>
                                Click-Through-Rate(CTR)%
                                {sortAdCTR ?
                                    <FaArrowUp style={{ color: '#adadad' }} />
                                    :
                                    <FaArrowDown style={{ color: '#adadad' }} />
                                }
                            </StyledTableCell>
                            <StyledTableCell sx={{ fontWeight: '700', color: '#000', cursor: 'pointer' }} onClick={() => handleSortClick('averageCpc')}>
                                Cost-Per-Click(CPC)$
                                {sortAdCPC ?
                                    <FaArrowUp style={{ color: '#adadad' }} />
                                    :
                                    <FaArrowDown style={{ color: '#adadad' }} />
                                }
                            </StyledTableCell>
                            <StyledTableCell sx={{ fontWeight: '700', color: '#000', cursor: 'pointer' }} onClick={() => handleSortClick('conversions')}>
                                Conversions
                                {
                                    conversions ?
                                        <FaArrowUp style={{ color: '#adadad' }} />
                                        :
                                        <FaArrowDown style={{ color: '#adadad' }} />
                                }
                            </StyledTableCell>
                            <StyledTableCell sx={{ fontWeight: '700', color: '#000', cursor: 'pointer' }} onClick={() => handleSortClick('costMicros')}>
                                Cost($)
                                {sortAdCost ?
                                    <FaArrowUp style={{ color: '#adadad' }} />
                                    :
                                    <FaArrowDown style={{ color: '#adadad' }} />
                                }
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {listOfAdsAll && listOfAdsAll.map((row) => {
                            const {
                                headlines,
                                finalUrls,
                                descriptions,
                                status,
                                impressions,
                                clicks,
                                ctr,
                                averageCpc,
                                conversions,
                                costMicros
                            } = row
                            return (
                                <StyledTableRow
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                    style={{ cursor: "pointer" }}
                                >
                                    <TableCell component="th" scope="row">
                                        <Stack>
                                            <Typography fontSize={'13px'} sx={{ color: '#681da8' }}>
                                                {headlines.join(" | ")}
                                            </Typography>
                                            <Typography fontSize={'13px'} sx={{ color: 'blue' }}>
                                                <a href={finalUrls} target='_blank'>{finalUrls}</a>
                                            </Typography>
                                            <Typography fontSize={'13px'} sx={{ color: 'gray' }}>
                                                {descriptions.join(" | ")}
                                            </Typography>
                                        </Stack>
                                    </TableCell>
                                    <TableCell>{status}</TableCell>
                                    <TableCell>{impressions}</TableCell>
                                    <TableCell>{clicks}</TableCell>
                                    <TableCell>{ctr}</TableCell>
                                    <TableCell>{averageCpc}</TableCell>
                                    <TableCell>{conversions}</TableCell>
                                    <TableCell>{costMicros}</TableCell>

                                </StyledTableRow>
                            )
                        })}

                    </TableBody>
                </Table>
            </TableContainer>
            <Stack
                sx={{ mt: 4, pb: 2 }}
                flexDirection="row"
                justifyContent="space-between"
            >
                <div>
                    Showing{" "}
                    {listOfAdsAll && listOfAdsAll.length} {' '}
                    of {totalRecordsAd} Results
                </div>
                <Pagination
                    count={totalPagesAd}
                    page={pageAd}
                    //   onChange={handleChangePage}
                    variant="outlined"
                    shape="rounded"
                />
            </Stack>

        </>
    )
}

export default Gdn