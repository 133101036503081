import React from 'react'
import { CloseIcon } from '../../../assets/icons/Icons'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import CommonButton from '../../../components/common/Button';
import CommonInput from '../../../components/common/Input';
import { Stack } from '@mui/system';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { addClient, updateClient } from '../../../Service/client.service';
import { addClientFormInitialValues, updateClientFormInitialValues } from '../../../helper/initialValues';
import { useFormik } from "formik";
import { addClientValidationSchema, updateClientValidationSchema } from '../../../helper/validation'


const AddClient = (props) => {
    const { getClientList, editClientData, handleCloseAddClient, setShow, setEditClientData, handleMenuClose } = props;
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const formik = useFormik({
        initialValues: editClientData && editClientData.id ? updateClientFormInitialValues : addClientFormInitialValues,
        onSubmit: async (value) => {
            if (editClientData && editClientData.id) {
                const updatePayload = {
                    first_name: value.fName,
                    last_name: value.lName,
                    company_name: value.company_name,
                    contract_join_date: value.contract_join_date,
                    contract_end_date: value.contract_end_date,
                };
                const updateRes = await updateClient(updatePayload, editClientData.id);
                if (updateRes.status == true) {
                    handleCloseAddClient();
                    setEditClientData({});
                    formik.resetForm();
                    getClientList(1, "");
                    handleMenuClose();
                    setShow({
                        show: true,
                        type: 'success',
                        message: updateRes.message || 'Client updated successfully'
                    })
                } else {
                    setShow({
                        show: true,
                        type: 'error',
                        message: updateRes.message || 'Something went wrong'
                    })
                }
            } else {
                const payload = {
                    first_name: value.fName,
                    last_name: value.lName,
                    email: value.email,
                    password: value.password,
                    group: '3',
                    username: value.email,
                    company_name: value.company_name,
                    contract_join_date: value.contract_join_date,
                    contract_end_date: value.contract_end_date,
                };
                const res = await addClient(payload);
                if (res.status == true) {
                    handleCloseAddClient();
                    formik.resetForm();
                    getClientList(1, "");
                    setShow({
                        show: true,
                        type: 'success',
                        message: res.message || 'Client created successfully'
                    })
                } else {
                    setShow({
                        show: true,
                        type: 'error',
                        message: res.message || 'Something went wrong'
                    })
                }
            }

        },
        validationSchema: editClientData && editClientData.id ? updateClientValidationSchema : addClientValidationSchema,
    });

    const handleModelClose = () => {
        setEditClientData({});
        formik.resetForm();
        handleCloseAddClient();
        handleMenuClose();
    }

    React.useEffect(() => {
        if (editClientData && editClientData.id) {
            formik.setFieldValue('fName', editClientData?.first_name);
            formik.setFieldValue('lName', editClientData?.last_name);
            formik.setFieldValue('email', editClientData?.email);
            formik.setFieldValue('company_name', editClientData?.company_name);
            formik.setFieldValue('contract_join_date', editClientData?.contract_join_date);
            formik.setFieldValue('contract_end_date', editClientData?.contract_end_date);
        }
    }, [])
    return (
        <>
            <DialogTitle>{editClientData && editClientData.id ? "Edit" : "Add"} Client
                <IconButton
                    aria-label="close"
                    onClick={() => handleModelClose()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'grey',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers >
                <Grid container spacing={2}>
                    <Grid item sm={4}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" width={250}>
                            <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                                First Name
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography className='myprofile_input' sx={{ width: '100%' }}>
                                <CommonInput fullWidth placeholder='First Name' name="fName" formik={formik} maxLength={30} />
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item sm={4}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" width={250} >
                            <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                                Last Name
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography className='myprofile_input' sx={{ width: '100%' }}>
                                <CommonInput fullWidth placeholder='Last Name' name="lName" formik={formik} maxLength={30} />
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item sm={4}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" width={250}>
                            <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                                Company Name
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography className='myprofile_input' sx={{ width: '100%' }}>
                                <CommonInput fullWidth placeholder='Company Name' name="company_name" formik={formik} />
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item sm={4}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" width={250}>
                            <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                                Email/Username
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography className='myprofile_input' sx={{ width: '100%' }}>
                                <CommonInput fullWidth placeholder='Email/Username' name="email" formik={formik} maxLength={250} disabled={editClientData && editClientData.id ? true : false} />
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item sm={4}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" width={250}>
                            <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                                Contract Start Date
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography className='myprofile_input' sx={{ width: '100%' }}>
                                <CommonInput fullWidth type='date' placeholder='Contract Start Date' name="contract_join_date" formik={formik} />
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item sm={4}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" width={250}>
                            <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                                Contract End Date
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography className='myprofile_input' sx={{ width: '100%' }}>
                                <CommonInput fullWidth type='date' placeholder='Contract End Date' name="contract_end_date" formik={formik} />
                            </Typography>
                        </Stack>
                    </Grid>
                    {editClientData && editClientData.id ? (
                        ""
                    ) : (
                        <Grid item sm={4}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width={250}>
                                <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                                    Password
                                </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                <Typography className='myprofile_input' sx={{ width: '100%' }}>
                                    <div className='p_relative'>
                                        <CommonInput fullWidth placeholder="Password" type={showPassword ? 'text' : 'password'} name="password" formik={formik} maxLength={50} />
                                        <InputAdornment position="end" className="eye_btn">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    </div>
                                </Typography>
                            </Stack>
                        </Grid>
                    )}

                </Grid>

            </DialogContent>
            <DialogActions>
                <CommonButton onClick={() => handleModelClose()} buttonName="Cancel" />
                <CommonButton onClick={formik.handleSubmit} buttonName={`${props.nameEditBtn || 'Add'}`} />
            </DialogActions>

        </>
    )
}

export default AddClient