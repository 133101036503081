import React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { rolecolumns, rolerows, useStyles } from '../../../helper';

const RoleTable = () => {
    const classes = useStyles();

    return (
        <>
            <DataGrid
                disableColumnMenu
                autoHeight
                autoWidth
                rows={rolerows}
                columns={rolecolumns}
                pageSize={5}
                className={classes.tableContainer}
                disableRowSelectionOnClick
            />
        </>
    )
}

export default RoleTable