import React from 'react'
import { CloseIcon } from '../../../assets/icons/Icons'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import CommonButton from '../../../components/common/Button';
import CommonInput from '../../../components/common/Input';
import CommonSelect from '../../../components/common/Select'
import { Stack } from '@mui/system';
import { getGroups } from '../../../Service/profile.service';
import { useFormik } from "formik";
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { addAdminFormInitialValues, updateAdminFormInitialValues } from '../../../helper/initialValues';
import { addAdminValidationSchema, updateAdminValidationSchema } from '../../../helper/validation';
import { addClient, updateClient } from '../../../Service/client.service';

const AddAdmin = (props) => {
    const { handleCloseManageAdmin, setShow, setEditAdmintData, editAdmintData, getAdminLists } = props;

    const [groupData, setGroupData] = React.useState([]);
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const getGroupData = async () => {
        const groups = await getGroups();
        if (groups.status == true) {
            setGroupData(groups.data);
        } else {
            setGroupData([]);
        }
    }

    const formik = useFormik({
        initialValues: editAdmintData && editAdmintData.id ? updateAdminFormInitialValues : addAdminFormInitialValues,
        onSubmit: async (value) => {
            if (editAdmintData && editAdmintData.id) {
                const updatePayload = {
                    first_name: value.first_name,
                    last_name: value.last_name
                };
                const updateRes = await updateClient(updatePayload, editAdmintData.id);
                if (updateRes.status == true) {
                    handleCloseManageAdmin();
                    setEditAdmintData({});
                    formik.resetForm();
                    getAdminLists();
                    setShow({
                        show: true,
                        type: 'success',
                        message: updateRes.message || 'Client updated successfully'
                    })
                } else {
                    setShow({
                        show: true,
                        type: 'error',
                        message: updateRes.message || 'Something went wrong'
                    })
                }
            } else {
                const payload = {
                    first_name: value.first_name,
                    last_name: value.last_name,
                    email: value.email,
                    password: value.password,
                    group: '2',
                    username: value.email,
                };
                const res = await addClient(payload);
                if (res.status == true) {
                    handleCloseManageAdmin();
                    formik.resetForm();
                    setEditAdmintData({})
                    getAdminLists();
                    setShow({
                        show: true,
                        type: 'success',
                        message: res.message || 'Client created successfully'
                    })
                } else {
                    setShow({
                        show: true,
                        type: 'error',
                        message: res.message || 'Something went wrong'
                    })
                }
            }

        },
        validationSchema: editAdmintData && editAdmintData.id ? updateAdminValidationSchema : addAdminValidationSchema,
    });

    React.useEffect(() => {
        if (editAdmintData && editAdmintData.id) {
            formik.setFieldValue('first_name', editAdmintData?.first_name);
            formik.setFieldValue('last_name', editAdmintData?.last_name);
            formik.setFieldValue('email', editAdmintData?.email);
        }
    }, [])

    const handleClosePopup = () => {
        setEditAdmintData({})
        handleCloseManageAdmin();
        formik.resetForm();
    }

    return (
        <>
            <DialogTitle>Add Admin
                <IconButton
                    aria-label="close"
                    onClick={() => handleClosePopup()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'grey',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers >
                <Grid container gap={2}>
                    <Grid item sm={12}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" width={250}>
                            <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                                First Name
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography className='myprofile_input' sx={{ width: '100%' }}>
                                <CommonInput fullWidth placeholder='First Name' name="first_name" formik={formik} maxLength={50} />
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item sm={12}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" width={250}>
                            <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                                Last Name
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography className='myprofile_input' sx={{ width: '100%' }}>
                                <CommonInput fullWidth placeholder='Last Name' name="last_name" formik={formik} maxLength={50} />
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item sm={12}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" width={250}>
                            <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                                Email
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography className='myprofile_input' sx={{ width: '100%' }}>
                                <CommonInput fullWidth placeholder='Email' name="email" formik={formik} maxLength={200} disabled={editAdmintData && editAdmintData.id ? true : false} />
                            </Typography>
                        </Stack>
                    </Grid>
                    {/* <Grid item sm={12}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" width={250}>
                            <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                                Group
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography className='myprofile_input' sx={{ width: '100%' }}>
                                <CommonSelect fullWidth padding='12px 15px' background='white' width="100%" options={groupData} color='var(--secondary)' boxShadow='unset' borderColor='var(--secondary)' />
                            </Typography>
                        </Stack>
                    </Grid> */}
                    {editAdmintData && editAdmintData.id ? (
                        ""
                    ) : (
                        <Grid item sm={12}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width={250}>
                                <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                                    Password
                                </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                <Typography className='myprofile_input' sx={{ width: '100%' }}>
                                    <div className='p_relative'>
                                        <CommonInput fullWidth placeholder="Password" type={showPassword ? 'text' : 'password'} name="password" formik={formik} maxLength={50} />
                                        <InputAdornment position="end" className="eye_btn">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    </div>
                                </Typography>
                            </Stack>
                        </Grid >
                    )}

                </Grid>
            </DialogContent>
            <DialogActions>
                <CommonButton onClick={() => handleClosePopup()} buttonName="Close" />
                <CommonButton onClick={formik.handleSubmit} buttonName="Save" />
            </DialogActions>
        </>
    )
}

export default AddAdmin