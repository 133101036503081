import React, { useEffect, useState } from 'react'
import PageHeading from '../../../components/common/PageHeading'
import CounterCard from './CounterCard'
import { Grid, Stack } from '@mui/material'
import { useStyles } from '../../../helper'
import PerformanceChart from './PerformanceChart'
import CostPerAdChart from './CostPerAdChart'
import DateRange from '../../../components/common/Table/DateRange'
import { getDasboardData } from "../../../Service/dashboard.service"
import { useSelector } from 'react-redux';
import Notification from '../../../components/common/Notification/Notification';
import LeadsList from './Leadslist'

const DashBoard = () => {
  const classes = useStyles();
  const { client } = useSelector((state) => state?.root);
  const [dataFromChild, setDataFromChild] = useState('');
  const [dashboardData, setDashboardData] = useState({});
  const [fromDate, setFromDate] = React.useState('');
  const [toDate, setToDate] = React.useState('');
  const [days, setDays] = React.useState(7);
  const [weekDayStartName, setWeekDayStartName] = React.useState('');
  const [monthStartName, setMonthStartName] = React.useState('');
  const [weekDayEndName, setWeekDayEndName] = React.useState('');
  const [monthEndName, setMonthEndName] = React.useState('');
  const [show, setShow] = useState({
    show: false,
    type: '',
    message: ''
  });

  const handleDashboardData = async (id) => {
    let query = `?start_date=${fromDate || "2023-09-01"}&end_date=${toDate || "2023-09-30"}`;
    const res = await getDasboardData(id || client?.clientId, query);
    const { data } = res;
    if (data.status) {
      setDashboardData(data.data)
    } else {
      setDashboardData({})
    }
  }

  useEffect(() => {
    if (client && client?.clientId) {
      handleDashboardData(client?.clientId);
    } else {
      setShow({
        show: true,
        type: 'error',
        message: 'Please Select Client'
      })
    }
  }, [client, fromDate, toDate])
  return (
    <>
      <Stack direction='row' alignItems="center" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: 1 }, flexDirection: { xs: 'column', sm: 'row' }, gap: '8px' }}>
        <PageHeading heading='Dashboard' para='Welcome back to' paraspan='ADMS' />
        <Grid container justifyContent='flex-end' alignItems='center' gap={{ xs: '5px', sm: '10px' }} width="inherit">
          <Grid item>
            <DateRange setFromDate={setFromDate} fromDate={fromDate} setToDate={setToDate} toDate={toDate} setDays={setDays} days={days} setWeekDayStartName={setWeekDayStartName} setMonthStartName={setMonthStartName} setWeekDayEndName={setWeekDayEndName} setMonthEndName={setMonthEndName} />
          </Grid>
        </Grid>
      </Stack>
      <Grid container spacing={{ xs: '10px', sm: 3 }}>
        <Grid item xs={12}>
          <CounterCard dashboardData={dashboardData} setDashboardData={setDashboardData} setShow={setShow} isGoal={true} handleDashboardData={handleDashboardData} />
        </Grid>
        {/* <Grid item xs={12}>
          <Grid container spacing={{ xs: '10px', sm: 3 }}>
            <Grid item md={7} xs={12}>
              <div className={classes.chartContainer}>
                <CostPerAdChart />
              </div>
            </Grid>
            <Grid item md={5} xs={12}>
              <div className={classes.chartContainer}>
                <PerformanceChart />
              </div>
            </Grid>
          </Grid>
        </Grid> */}
        <Grid item xs={12}>
          <LeadsList setShow={setShow} />
        </Grid>
        {/* <Grid item xs={12}>
          <RoleTable />
        </Grid> */}
      </Grid>

      <Notification show={show} setShow={setShow} />
    </>
  )
}

export default DashBoard