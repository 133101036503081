import { get, post, patch } from "../web.request";

export const profileDetails = async () => {
    return await get(`users/profile`);
}

export const updateProfile = async (data) => {
    return await patch(`users/profile/`, data);
}

export const getGroups = async () => {
    return await get(`users/group-list`);
}

