import { get, post, patch, getpagination, getAllResponse ,getAllResponsePost} from "../web.request";

export const linkedin = async (data) => {
    return await post(`linkedin_app/linkedin_user/`, data);
}
export const linkedinReconnect = async (id,data) => {
    return await post(`linkedin_app/linkedin_regenerate_token/${id}`, data);
}
export const google = async (id,data) => {
    return await getAllResponsePost(`google_app/google_account/${id}`, data);
}
export const googleReconnect = async (id,data) => {
    return await getAllResponsePost(`google_app/google_auth/${id}`, data);
}
export const googleAccountSelect = async (data) => {
    return await post(`google_app/google_user/`, data);
}

export const facebook = async (data) => {
    return await post(`facebook_app/facebook_user/`, data);
}
export const facebookReconnect = async (id,data) => {
    return await post(`facebook_app/facebook_regenerate_token/${id}`, data);
}
export const instagram = async (data) => {
    return await post(`instagram_app/instagram_user/`, data);
}

export const tiktok = async (data) => {
    return await post(`tiktok_app/tiktok_user/`, data);
}
export const tiktokReconnect = async (id,data) => {
    return await post(`tiktok_app/tiktok_regenerate_token/${id}`, data);
}