import React, { useEffect } from 'react'
import { Checkbox, ListItemText, MenuItem, Select, ThemeProvider, createTheme } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FacebookAds from '../../../pages/admin/adschannel/facebook/FacebookAds';
import InstagramAds from '../../../pages/admin/adschannel/instagram/InstagramAds';
import GoogleAds from '../../../pages/admin/adschannel/google/GoogleAds';
import TiktokAds from '../../../pages/admin/adschannel/tiktok/TiktokAds';
import LinkedinAds from '../../../pages/admin/adschannel/linkedin/LinkedinAds';


const names = [
    {
        name: 'Select Channel',
        key: null
    },
    {
        name: 'Facebook',
        key: 'facebook'
    },
    {
        name: 'Instagram',
        key: 'instagram'
    },
    {
        name: 'Google SEM',
        key: 'google_sem'
    },
    {
        name: 'Google GDN',
        key: 'google_gdn'
    },
    {
        name: 'Google YouTube',
        key: 'google_youtube'
    },
    {
        name: 'TikTok',
        key: 'tiktok'
    },
    {
        name: 'LinkedIn',
        key: 'linkedin'
    }
];

const TableDropDown = (props) => {
    const { channels, setChannels } = props;
    const theme2 = createTheme({
        components: {
            // Name of the component
            MuiSelect: {
                styleOverrides: {
                    icon: {
                        color: 'var(--secondary)',
                        zIndex: '1'
                    },
                    outlined: {
                        padding: '0 !important',
                        border: `1px solid ${props.border || 'var(--white)'}`,
                    },
                    root: {
                        borderRadius: '5px',
                        background: `${props.background || 'var(--white)'}`,
                        color: `${props.color || 'var(--secondary)'}`,
                        padding: '3px 7px',
                        boxShadow: '2px 3px 10px 0px rgba(0, 0, 0, 0.15)',
                        width: '160px',
                    },
                },
            },
            MuiList: {
                styleOverrides: {
                    root: {
                        padding: '0'
                    },
                },
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        padding: '4px 8px 4px 0'
                    },
                },
            }
        },
    });

    const handleChange = (event) => {
        setChannels(event.target.value);
    };
    return (
        <ThemeProvider theme={theme2}>
            <Select
                value={channels}
                onChange={handleChange}
                displayEmpty
                name={'channels'}
            // endAdornment={<KeyboardArrowDownIcon />}
            >
                {names.map((name) => (
                    <MenuItem key={name.key} name={name.key} value={name.key}>
                        <ListItemText name={name.key} primary={name.name} />
                    </MenuItem>
                ))}
            </Select>
        </ThemeProvider>
    )
}

export default TableDropDown