import React, { useState } from 'react'
import { CloseIcon, EditIcon } from '../../../assets/icons/Icons'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import CommonButton from '../../../components/common/Button';
import ManageRole from './ManageRole';

const AddRole = (props) => {
    const { openAdd, handleClose, editId, setShow, roleName } = props;

    return (
        <>

            <Dialog open={openAdd} onClose={handleClose} sx={{ '& .MuiDialog-paper': { width: '800px' } }}>
                <DialogTitle>Edit Permissions</DialogTitle>
                <DialogContent dividers >
                    <Typography fontSize={20}>Role : {roleName !== "" ? roleName : " Admin"}</Typography>
                    <ManageRole editId={editId} handleClose={handleClose} setShow={setShow} />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AddRole