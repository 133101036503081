import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import GenerateReport from './GenerateReport';
import PreviousReport from './PreviousReport';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

export default function Reporting() {
	const [value, setValue] = React.useState('1');

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const childrenValueHandler = (data) =>{
		setValue(data);
	}

	return (
		<Box sx={{ width: '100%' }} className='tab'>
			<TabContext value={value}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList onChange={handleChange} aria-label="lab API tabs example">
						<Tab label="Generate Report" value="1" />
						<Tab label="Previous Report" value="2" />
					</TabList>
				</Box>
				<TabPanel value="1">
					<GenerateReport  valueHandler={childrenValueHandler}/>
				</TabPanel>
				<TabPanel value="2">
					<PreviousReport />
				</TabPanel>
			</TabContext>
		</Box>
	);
}