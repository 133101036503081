import { get, post, patch, getpagination, getAllResponse, deleteRequest } from "../web.request";

export const previousReportList = async (id, query) => {
    return await getAllResponse(`/report/creat_report/${id}${query}`);
}

export const sendReportToClient = async (payload, id) => {
    return await patch(`report/creat_report/${id}/`, payload);
}

export const deleteReport = async (id) => {
    return await deleteRequest(`/report/creat_report/${id}/`);
}

export const generateReport = async (payload) => {
    return await post(`/report/creat_report/`, payload);
}

export const getActiveChannel = async (id, query) => {
    return await get(`/report/report_key/${id}${query}`);
}

// FOR Update Report Name => PreviousName 

export const updateReportName = async (id,data) => {
    return await patch(`/report/creat_report/${id}/`,data);
}