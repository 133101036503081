import React from 'react'
import { Button, Tooltip } from '@mui/material'
import { Download } from '@mui/icons-material';


const CommonButton = (props) => {
	return (
		<>
			{
				props.tooltip ?
					<>
						<Tooltip title={props.titles} arrow placement="top">
							<Button variant="contained" size="large" disabled={props.disabled} sx={{
								borderRadius: `${props.borderRadius}`,
								minWidth: `${props.minWidth}`,
								height: `${props.height}`,
								padding: `${props.padding}` || { xs: '8px 30px', sm: '10px 60px' },
								textTransform: 'capitalize', background: `${props.background}` || 'var(--primary)', minWidth: `${props.minWidth}`,
								fontSize: `${props.fontSize}` || { xs: '15px', sm: '20px' }, '&:hover': {
									backgroundColor: `${props.background}` || 'var(--primary)'
								},
								type: `${props.type}` || 'submit',

							}}
								onClick={props.onClick}
							>
								{props.buttonName} {props.icon ? <Download /> : ''}
							</Button>
						</Tooltip>
					</> : 
					<Button variant="contained" size="large" sx={{
						borderRadius: `${props.borderRadius}`,
						minWidth: `${props.minWidth}`,
						height: `${props.height}`,
						padding: `${props.padding}` || { xs: '8px 30px', sm: '10px 60px' },
						textTransform: 'capitalize', background: `${props.background}` || 'var(--primary)', minWidth: `${props.minWidth}`,
						fontSize: `${props.fontSize}` || { xs: '15px', sm: '20px' }, '&:hover': {
							backgroundColor: `${props.background}` || 'var(--primary)'
						},
						type: `${props.type}` || 'submit',
					}}
						onClick={props.onClick}
					>
						{props.buttonName} {props.icon ? <Download /> : ''}
					</Button>
			}

		</>
	)
}

export default CommonButton