import { TextField, ThemeProvider } from '@mui/material'
import React from 'react'
import { theme } from '../../../helper'

const TableSearch = (props) => {
    const { onChange } = props
    return (
        <ThemeProvider theme={theme}>
            <TextField hiddenLabel placeholder='Search' xs={{ width: '100% !important' }} onChange={onChange} />
        </ThemeProvider>
    )
}

export default TableSearch