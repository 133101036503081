import React, { useState } from "react";
import { useStyles } from "../../../../helper";
import TableSearch from "../../../../components/common/Table/TableSearch";
import YoutubeChart from "./YoutubeChart";
import {
  Grid,
  Stack,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  Typography,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AdGroup from "./adgroup/AdGroup";
import Youtube from "./adgroup/youtube/Youtube";
import { getGroupOfYouTubeList } from "../../../../Service/facebook.service";
import { FaArrowUp } from "react-icons/fa6";
import { FaArrowDown } from "react-icons/fa6";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#1987540a",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));

const YoutubeAds = (props) => {
  const {
    lebel1,
    lebel2,
    xLabels,
    fromDate,
    toDate,
    data,
    setSearch,
    pagePerSize,
    totalRecords,
    totalPages,
    page,
    setPage,
    googleChannel,
    shortKey,
    setShortKey,
    sortBy,
    setSortBy,
  } = props;
  const classes = useStyles();

  const [campaign, setCampaign] = useState(true);
  const [adGroup, setAdGroup] = useState(false);
  const [semList, setSemList] = useState(false);
  const [breAdGroup, setBreAdGroup] = useState(false);
  const [breSemList, setBreSemList] = useState(false);

  const [pageSub, setPageSub] = useState(1);
  const [pagePerSizeSub, setPagePerSizeSub] = useState(10);
  const [totalRecordsSub, setTotalRecordsSub] = useState(0);
  const [totalPagesSub, setTotalPagesSub] = useState(0);
  const [groupSearch, setGroupSearch] = useState("");
  const [listOfAdsAll, setListOfAdsAll] = useState([]);
  const [adGroupName, setGroupTabName] = useState("");
  const [adAdName, setAdTabName] = useState("");
  const [groupDataListYoutube, setGroupDataListYoutube] = useState([]);
  const [adsDataList, setAdsDataList] = useState([]);

  const [pageAd, setPageAd] = useState(1);
  const [pagePerSizeAd, setPagePerSizeAd] = useState(10);
  const [totalRecordsAd, setTotalRecordsAd] = useState(0);
  const [totalPagesAd, setTotalPagesAd] = useState(0);

  const [sortBudgetYT, setSortBudgetYT] = useState(false);
  const [sortImpYT, setSortImpYT] = useState(false);
  const [sortClickYT, setSortClickYT] = useState(false);
  const [sortCostYT, setSortCostYT] = useState(false);

  //
  const [sortCtrYT, setSortCtrYT] = useState(false);
  const [sortCpcYT, setSortCpcYT] = useState(false);
  const [sortConversionsYT, setSortConversionsYT] = useState(false);
  const [avCpv, setAvCpv] = useState(false);
  const [vVRate, setVVRate] = useState(false);
  const [views, setViews] = useState(false);

  const [groupShortBy, setGroupShortBy] = useState(1);
  const [groupShortByField, setGroupShortByField] = useState("");
  const [campionRowData, setcampionRowData] = useState({});

  const [adShortBy, setAdShortBy] = useState(1);
  const [adShortByField, setAdShortByField] = useState("");
  const [adRowData, setAdRowData] = useState({});

  const handleCampaignClick = () => {
    setCampaign(false);
    setAdGroup(true);
    setSemList(false);
    setBreAdGroup(true);
    setBreSemList(false);
  };

  const handleAdGroupClick = () => {
    setCampaign(false);
    setAdGroup(false);
    setSemList(true);
    setBreSemList(true);
  };

  const handleCampaignBreClick = () => {
    setBreAdGroup(false);
    setAdGroup(false);
    setSemList(false);
    setCampaign(true);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    // getClientList(value);
  };

  const handleClickOnCampion = async (clickData) => {
    // setSelectedCapionId(clickData.id);
    setGroupTabName(clickData.ad_name);
    setcampionRowData(clickData);
    const qry = `?start_date=${fromDate}&end_date=${toDate}&page=${pageSub}&filter_field=${groupShortByField}&filter_type=${groupShortBy ? 1 : -1
      }`;
    const getGroupList = await getGroupOfYouTubeList(qry, clickData.id);
    const { data } = getGroupList;
    if (data.status == true) {
      setGroupDataListYoutube(data.data);
      setTotalRecordsSub(data?.count);
      setTotalPagesSub(Math.ceil(data?.count / pagePerSize));
      setCampaign(false);
      setAdGroup(true);
      setSemList(false);
      setBreAdGroup(true);
      setBreSemList(false);
    } else {
      setGroupDataListYoutube([]);
      setTotalRecordsSub(0);
      setTotalPagesSub(0);
    }
  };

  const handleSortClick = (name) => {
    switch (name) {
      case "amountMicros":
        setSortBudgetYT((current) => !current);
        setSortImpYT(false);
        setSortClickYT(false);
        setSortCostYT(false);
        setSortCtrYT(false);
        setSortCpcYT(false);
        setSortConversionsYT(false);
        setAvCpv(false);
        setVVRate(false);
        setViews(false);
        setSortBy(sortBudgetYT ? 1 : -1);
        setShortKey(name);
        break;
      case "impressions":
        setSortImpYT((current) => !current);
        setSortBudgetYT(false);
        setSortClickYT(false);
        setSortCostYT(false);
        setSortCtrYT(false);
        setSortCpcYT(false);
        setSortConversionsYT(false);
        setAvCpv(false);
        setVVRate(false);
        setViews(false);
        setSortBy(sortImpYT ? 1 : -1);
        setShortKey(name);
        break;
      case "clicks":
        setSortClickYT((current) => !current);
        setSortImpYT(false);
        setSortBudgetYT(false);
        setSortCostYT(false);
        setSortCtrYT(false);
        setSortCpcYT(false);
        setSortConversionsYT(false);
        setAvCpv(false);
        setVVRate(false);
        setViews(false);
        setSortBy(sortClickYT ? 1 : -1);
        setShortKey(name);
        break;
      case "costMicros":
        setSortCostYT((current) => !current);
        setSortClickYT(false);
        setSortImpYT(false);
        setSortBudgetYT(false);
        setSortCtrYT(false);
        setSortCpcYT(false);
        setSortConversionsYT(false);
        setAvCpv(false);
        setVVRate(false);
        setViews(false);
        setSortBy(sortCostYT ? 1 : -1);
        setShortKey(name);
        break;
      case "ctr":
        setSortCostYT(false);
        setSortClickYT(false);
        setSortImpYT(false);
        setSortBudgetYT(false);
        setSortCtrYT((current) => !current);
        setSortCpcYT(false);
        setSortConversionsYT(false);
        setAvCpv(false);
        setVVRate(false);
        setViews(false);
        setSortBy(sortCtrYT ? 1 : -1);
        setShortKey(name);
        break;
      case "averageCpc":
        setSortCostYT(false);
        setSortClickYT(false);
        setSortImpYT(false);
        setSortBudgetYT(false);
        setSortCtrYT(false);
        setSortCpcYT((current) => !current);
        setSortConversionsYT(false);
        setAvCpv(false);
        setVVRate(false);
        setViews(false);
        setSortBy(sortCpcYT ? 1 : -1);
        setShortKey(name);
        break;

      case "conversions":
        setSortCostYT(false);
        setSortClickYT(false);
        setSortImpYT(false);
        setSortBudgetYT(false);
        setSortCtrYT(false);
        setSortCpcYT(false);
        setSortConversionsYT((current) => !current);
        setAvCpv(false);
        setVVRate(false);
        setViews(false);
        setSortBy(sortConversionsYT ? 1 : -1);
        setShortKey(name);
        break;

      case "averageCpv":
        setSortCostYT(false);
        setSortClickYT(false);
        setSortImpYT(false);
        setSortBudgetYT(false);
        setSortCtrYT(false);
        setSortCpcYT(false);
        setSortConversionsYT(false);
        setAvCpv((current) => !current);
        setVVRate(false);
        setViews(false);
        setSortBy(avCpv ? 1 : -1);
        setShortKey(name);
        break;

      case "videoViewRate":
        setSortCostYT(false);
        setSortClickYT(false);
        setSortImpYT(false);
        setSortBudgetYT(false);
        setSortCtrYT(false);
        setSortCpcYT(false);
        setSortConversionsYT(false);
        setAvCpv(false);
        setVVRate((current) => !current);
        setViews(false);
        setSortBy(vVRate ? 1 : -1);
        setShortKey(name);
        break;

      case "videoViews":
        setSortCostYT(false);
        setSortClickYT(false);
        setSortImpYT(false);
        setSortBudgetYT(false);
        setSortCtrYT(false);
        setSortCpcYT(false);
        setSortConversionsYT(false);
        setAvCpv(false);
        setVVRate(false);
        setViews((current) => !current);
        setSortBy(views ? 1 : -1);
        setShortKey(name);
        break;

      default:
        setSortClickYT((current) => !current);
        setSortImpYT(false);
        setSortBudgetYT(false);
        setSortCostYT(false);
        setSortCtrYT(false);
        setSortCpcYT(false);
        setSortConversionsYT(false);
        setAvCpv(false);
        setVVRate(false);
        setViews(false);
        setSortBy(sortClickYT ? 1 : -1);
        setShortKey(name);
        break;
    }
  };

  return (
    <>
      <Stack
        justifyContent="space-between"
        width="100%"
        sx={{
          marginBottom: { xs: 1 },
          marginTop: { xs: 2 },
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: 1 },
          alignItems: { xs: "start", sm: "end" },
        }}
      >
        <Breadcrumbs aria-label="breadcrumb" separator="›">
          <Link
            underline="hover"
            color="inherit"
            onClick={handleCampaignBreClick}
            style={{ cursor: "pointer" }}
          >
            Campaigns
          </Link>
          {breAdGroup && (
            <Link
              underline="hover"
              color="inherit"
              onClick={handleCampaignClick}
              style={{ cursor: "pointer" }}
            >
              {adGroupName}
            </Link>
          )}
          {breSemList && (
            <Link
              underline="hover"
              color="text.primary"
              style={{ cursor: "pointer" }}
            >
              {adAdName}
            </Link>
          )}
        </Breadcrumbs>
      </Stack>
      {/* <Grid className={classes.cardContainer} sx={{ marginTop: '10px' }}>
        <YoutubeChart lebel1={lebel1 ? lebel1 : ['']} lebel2={lebel2 ? lebel2 : ['']} xLabels={xLabels ? xLabels : ['']} />
      </Grid> */}
      {campaign && (
        <>
          <Stack
            justifyContent="end"
            width="100%"
            sx={{
              marginBottom: { xs: 1 },
              marginTop: { xs: 2 },
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: 1 },
              alignItems: { xs: "start", sm: "end" },
            }}
          >
            <TableSearch
              onChange={(e) => {
                setSearch(e.target.value);
                setPage(1);
              }}
            />
          </Stack>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ fontWeight: "700", color: "#000" }}>
                    Campaign Name
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ fontWeight: "700", color: "#000", cursor: "pointer" }}
                    onClick={() => handleSortClick("amountMicros")}
                  >
                    Budget/Day
                    {sortBudgetYT ? (
                      <FaArrowUp style={{ color: "#adadad" }} />
                    ) : (
                      <FaArrowDown style={{ color: "#adadad" }} />
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ fontWeight: "700", color: "#000", cursor: "pointer" }}
                    onClick={() => handleSortClick("impressions")}
                  >
                    Impressions
                    {sortImpYT ? (
                      <FaArrowUp style={{ color: "#adadad" }} />
                    ) : (
                      <FaArrowDown style={{ color: "#adadad" }} />
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ fontWeight: "700", color: "#000", cursor: "pointer" }}
                    onClick={() => handleSortClick("clicks")}
                  >
                    Clicks
                    {sortClickYT ? (
                      <FaArrowUp style={{ color: "#adadad" }} />
                    ) : (
                      <FaArrowDown style={{ color: "#adadad" }} />
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ fontWeight: "700", color: "#000", cursor: "pointer" }}
                    onClick={() => handleSortClick("ctr")}
                  >
                    Click-Through-Rate(CTR)%
                    {sortCtrYT ? (
                      <FaArrowUp style={{ color: "#adadad" }} />
                    ) : (
                      <FaArrowDown style={{ color: "#adadad" }} />
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ fontWeight: "700", color: "#000", cursor: "pointer" }}
                    onClick={() => handleSortClick("averageCpc")}
                  >
                    Cost-Per-Click(CPC)$
                    {sortCpcYT ? (
                      <FaArrowUp style={{ color: "#adadad" }} />
                    ) : (
                      <FaArrowDown style={{ color: "#adadad" }} />
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ fontWeight: "700", color: "#000", cursor: "pointer" }}
                    onClick={() => handleSortClick("conversions")}
                  >
                    Conversions
                    {sortConversionsYT ? (
                      <FaArrowUp style={{ color: "#adadad" }} />
                    ) : (
                      <FaArrowDown style={{ color: "#adadad" }} />
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ fontWeight: "700", color: "#000", cursor: "pointer" }}
                    onClick={() => handleSortClick("costMicros")}
                  >
                    Cost($)
                    {sortCostYT ? (
                      <FaArrowUp style={{ color: "#adadad" }} />
                    ) : (
                      <FaArrowDown style={{ color: "#adadad" }} />
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ fontWeight: "700", color: "#000", cursor: "pointer" }}
                    onClick={() => handleSortClick("averageCpv")}
                  >
                    AverageCpv($)
                    {avCpv ? (
                      <FaArrowUp style={{ color: "#adadad" }} />
                    ) : (
                      <FaArrowDown style={{ color: "#adadad" }} />
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ fontWeight: "700", color: "#000", cursor: "pointer" }}
                    onClick={() => handleSortClick("videoViewRate")}
                  >
                    VideoViewRate
                    {vVRate ? (
                      <FaArrowUp style={{ color: "#adadad" }} />
                    ) : (
                      <FaArrowDown style={{ color: "#adadad" }} />
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ fontWeight: "700", color: "#000", cursor: "pointer" }}
                    onClick={() => handleSortClick("videoViews")}
                  >
                    Views
                    {views ? (
                      <FaArrowUp style={{ color: "#adadad" }} />
                    ) : (
                      <FaArrowDown style={{ color: "#adadad" }} />
                    )}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row, index) => {
                  var {
                    ad_id,
                    ad_name,
                    conversions,
                    impressions,
                    ctr,
                    spend,
                    clicks,
                    costMicros,
                    amountMicros,
                    averageCpc,
                    averageCpv,
                    videoViewRate,
                    videoViews,
                    hedged_cost,
                    hedged_cpc
                  } = row;

                  return (
                    <StyledTableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClickOnCampion(row)}
                    >
                      {/* onClick={handleCampaignClick} */}
                      <TableCell component="th" scope="row">
                        {ad_name}
                      </TableCell>
                      <TableCell>
                        {amountMicros.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </TableCell>
                      <TableCell>{impressions}</TableCell>
                      <TableCell>{clicks}</TableCell>
                      <TableCell>{ctr}%</TableCell>
                      <TableCell>
                        {/* {Number(averageCpc).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })} */}
                        {averageCpc ? Number(averageCpc).toLocaleString("en-US", { style: "currency", currency: "USD" }) : Number(hedged_cpc).toLocaleString("en-US", { style: "currency", currency: "USD" })}
                      </TableCell>
                      <TableCell>{conversions}</TableCell>
                      <TableCell>
                        {/* {costMicros.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })} */}
                        {costMicros ? costMicros?.toLocaleString("en-US", { style: "currency", currency: "USD" }) : hedged_cost?.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                      </TableCell>
                      <TableCell>{averageCpv ? averageCpv : "-"}</TableCell>
                      <TableCell>{videoViewRate ? videoViewRate : "-"}</TableCell>
                      <TableCell>{videoViews ? videoViews : '-'}</TableCell>

                    </StyledTableRow>
                  );
                })}
                {data?.length === 0 ? (
                  <TableRow>
                    <TableCell
                      sx={{
                        color: "silver",
                        textAlign: "center",
                        paddingTop: "90px",
                        borderBottom: "none",
                        fontSize: "30px",
                      }}
                      colSpan="6"
                    >
                      No records to display
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack
            sx={{ mt: 4, pb: 2 }}
            flexDirection="row"
            justifyContent="space-between"
          >
            <div>
              Showing {data && data.length}
              of {totalRecords} Results
            </div>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
            />
          </Stack>
        </>
      )}

      {adGroup && (
        <AdGroup
          lebel1={lebel1}
          lebel2={lebel2}
          xLabels={xLabels}
          youtubeFlag={true}
          fromDate={fromDate}
          toDate={toDate}
          pagePerSize={pagePerSizeSub}
          totalRecords={totalRecordsSub}
          totalPages={totalPagesSub}
          page={pageSub}
          setPage={setPageSub}
          handleAdGroupClick={handleAdGroupClick}
          groupDataListSEM={groupDataListYoutube}
          adsDataList={adsDataList}
          setAdsDataList={setAdsDataList}
          listOfAdsAll={listOfAdsAll}
          setListOfAdsAll={setListOfAdsAll}
          setAdTabName={setAdTabName}
          googleChannel={googleChannel}
          pageAd={pageAd}
          setPageAd={setPageAd}
          pagePerSizeAd={pagePerSizeAd}
          setPagePerSizeAd={setPagePerSizeAd}
          totalRecordsAd={totalRecordsAd}
          setTotalRecordsAd={setTotalRecordsAd}
          totalPagesAd={totalPagesAd}
          setTotalPagesAd={setTotalPagesAd}
          setGroupShortBy={setGroupShortBy}
          setGroupShortByField={setGroupShortByField}
          handleClickOnCampion={handleClickOnCampion}
          campionRowData={campionRowData}
          groupShortBy={groupShortBy}
          adShortBy={adShortBy}
          adShortByField={adShortByField}
          setAdRowData={setAdRowData}
          adRowData={adRowData}
        />
      )}
      {semList && (
        <Youtube
          lebel1={lebel1}
          lebel2={lebel2}
          xLabels={xLabels}
          fromDate={fromDate}
          toDate={toDate}
          listOfAdsAll={listOfAdsAll}
          setListOfAdsAll={setListOfAdsAll}
          googleChannel={googleChannel}
          totalRecordsSub={totalRecordsSub}
          pageSub={pageSub}
          setPageSub={setPageSub}
          totalPagesSub={totalPagesSub}
          pageAd={pageAd}
          setPageAd={setPageAd}
          pagePerSizeAd={pagePerSizeAd}
          setPagePerSizeAd={setPagePerSizeAd}
          totalRecordsAd={totalRecordsAd}
          setTotalRecordsAd={setTotalRecordsAd}
          totalPagesAd={totalPagesAd}
          setTotalPagesAd={setTotalPagesAd}
          adShortBy={adShortBy}
          adShortByField={adShortByField}
          setAdShortBy={setAdShortBy}
          setAdShortByField={setAdShortByField}
          setAdRowData={setAdRowData}
          adRowData={adRowData}
          handleAdGroupClick={handleAdGroupClick}
          pagePerSize={pagePerSizeSub}
        />
      )}
    </>
  );
};

export default YoutubeAds;
