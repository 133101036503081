import React from 'react';
import { google, googleReconnect } from '../Service/social.service';
import { useSelector } from 'react-redux';
import CommonButton from "../components/common/Button";
import { useNavigate } from 'react-router-dom';
import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import { useStyles } from '../helper';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';


function GoogleAds() {
    const classes = useStyles();
    const navigate = useNavigate();
    const [message, setMessage] = React.useState("Your google accout is processing...");
    const [responseState, setResponseState] = React.useState(0);
    const [data, setData] = React.useState([])
    const [isReconnect, setIsReconnect] = React.useState("")
    const [userIds, setUserIds] = React.useState("")

    const { auth } = useSelector((state) => state?.root);
    const { user } = auth;
    const linkAccount = (code, userId, reconnect) => {
        setTimeout(async () => {
            if (code) {
                const payload = {
                    // user: userId,
                    code: code
                }
                let res = ""
                if (reconnect == "true") {

                    res = await googleReconnect(userId, payload);
                } else if (reconnect == "false") {
                    res = await google(userId, payload);
                }
                if (res && res.data && res.data.status == true) {
                    setResponseState(1);
                    localStorage.removeItem('isReconnect')

                    setMessage(res.data.message)
                    if (reconnect != "true") {
                        navigate('/choose-account', {
                            state: {
                                userId: userId, data: res.data.data, refresh_token: res && res.data && res.data.refresh_token != null ? res.data.refresh_token : "1//0gyHf6CiQccemCgYIARAAGBASNwF-L9Irz0d-S7KxAzR5jmx-etB3MVm0LtKZgaYHtP-Fz4SI92x9MZsMW_09Or1Y-IZjhRSx3ss", access_token: res.data.access_token
                            }
                        })
                    }

                } else {
                    setResponseState(2);
                    setMessage(res?.message || res?.data?.message || "Something went wrong")
                }
            }
        }, 3000);

    }
    React.useEffect(() => {
        const queryString = window.location.search;

        const queryParams = new URLSearchParams(queryString);

        const paramValue = queryParams.get('code');
        const userId = queryParams.get('state');
        const isReconnects = localStorage.getItem('isReconnect');
        if (isReconnects) {
            setIsReconnect(true)
        }
        else {
            setIsReconnect(false)
        }
        if (userId) {
            setUserIds(userId)
        }
        linkAccount(paramValue, userId, isReconnects)
    }, [])

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw' }}>
                <Card className={classes.cardContainer} sx={{ width: { xs: '300px', sm: '500px' }, height: 'unset', minWidth: { xs: '300px', sm: '500px' } }}>
                    <div>
                        <Stack container justifyContent={'center'} alignItems={'center'} padding={3} gap={3}>
                            <Grid item xs={12}>
                                {responseState == 1 ? (
                                    <CheckCircleOutlineIcon sx={{ color: '#0ea24a', fontSize: '100px' }} />
                                ) : ("")}
                                {responseState == 0 ? (
                                    <AccessTimeFilledIcon sx={{ color: '#dfd800', fontSize: '100px' }} />
                                ) : ("")}
                                {responseState == 2 ? (
                                    <HighlightOffIcon sx={{ color: '#dd0612', fontSize: '100px' }} />
                                ) : ("")}
                            </Grid>
                            <Grid item xs={12}>
                                {/* <Typography fontSize={{ xs: '18px', sm: '25px' }} fontWeight={'700'}>{message}</Typography> */}
                                <Typography fontSize={{ xs: '18px', sm: '25px' }} fontWeight={'700'} textAlign={'center'}>{message}</Typography>
                                {/* <Typography fontSize={{ xs: '18px', sm: '25px' }} fontWeight={'700'} textAlign={'center'}>Some Reasons Your Linkdin Account Not Connected</Typography> */}
                            </Grid>
                            <Grid item xs={12}>
                                {responseState != 0 && isReconnect ? (
                                    <CommonButton buttonName='Back to home' fontSize='1rem' minWidth='40px' padding='4px 8px' onClick={() => navigate('/admin/client/details', {state:{
                                        id:userIds
                                    } })} />
                                ) : ("")}
                            </Grid>
                        </Stack>
                    </div>
                </Card>
            </Box>
        </>
    )
}

export default GoogleAds
