import React from 'react'
import CommonButton from '../../../components/common/Button'
import { AddIcon, CloseIcon, EditIcon, DeleteIcon } from '../../../assets/icons/Icons'
import { useStyles } from '../../../helper'
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Stack, Typography } from '@mui/material';
import { Notificationuser } from '../../../components/Constants';
import DeleteModal from '../../../components/common/modal/DeleteModal';
import AddAdmin from './AddAdmin';
import { getAdminList, updateClient } from "../../../Service/client.service"

const ManageAdmin = (props) => {
    const { setShow, openAdd, handleClose, setOpenAdd } = props;
    const classes = useStyles();
    const [manageAdminPopup, setmanageAdminPopup] = React.useState(false);
    const [adminLists, setAdminLists] = React.useState([]);
    const [editAdmintData, setEditAdmintData] = React.useState({});
    const [deleteAdminPopup, setDeleteAdminPopup] = React.useState(false)
    const [deleteAdminId, setDeleteAdminId] = React.useState(null)
    const handleCloseManageAdmin = () => {
        setmanageAdminPopup(false);
    }

    const handleOpenManageAdmin = () => {
        setmanageAdminPopup(true);
    }

    const handleCloseDeletePopup = () => {
        setDeleteAdminId(null)
        setDeleteAdminPopup(false)
    }

    const confirmDeleteAdmin = async () => {
        if (deleteAdminId) {
            const updatePayload = {
                is_deleted: true
            };
            const deleteRes = await updateClient(updatePayload, deleteAdminId);
            if (deleteRes.status == true) {
                setDeleteAdminId(null)
                setDeleteAdminPopup(false);
                getAdminLists();
                setShow({
                    show: true,
                    type: 'success',
                    message: deleteRes.message || 'Client deleted successfully'
                })
            } else {
                setDeleteAdminId(null)
                setShow({
                    show: true,
                    type: 'error',
                    message: deleteRes.message || 'Something went wrong'
                })
            }
        }
    }

    const handleDeleteAdmin = (id) => {
        if (id) {
            setDeleteAdminId(id)
            setDeleteAdminPopup(true)
        }
    }

    const getAdminLists = async () => {
        const adminsData = await getAdminList();
        if (adminsData.status = true) {
            setAdminLists(adminsData.data)
        } else {
            setAdminLists([])
        }
    }

    const handleEditAdmin = (row) => {
        if (row && row.id) {
            setEditAdmintData(row);
            setmanageAdminPopup(true);
        }
    }

    React.useEffect(() => {
        getAdminLists();
    }, [])
    return (
        <>
            <Dialog open={openAdd} onClose={handleClose} sx={{ '& .MuiDialog-paper': { width: '500px' } }}>
                <DialogTitle>Manage Admin
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'grey',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers >
                    <Box>
                        <Grid container spacing={3} alignItems='center' justifyContent='space-between'>
                            <Grid item fontSize={18} fontWeight={600} sx={{ color: 'var(--primary)' }}>Admin List</Grid>
                            <Grid item fontSize={15} fontWeight={500} sx={{ color: 'var(--primary)' }}>
                                <CommonButton buttonName={<AddIcon />} fontSize='1rem' minWidth='40px' padding='7px' onClick={handleOpenManageAdmin} />
                            </Grid>
                        </Grid>
                        {
                            adminLists.map((row) => {
                                return (
                                    <Box container spacing={3} className={classes.cardContainer} padding={2} marginTop={2} sx={{ cursor: 'pointer' }}>
                                        <Grid container justifyContent='space-between' alignItems='center' >
                                            <Grid item>
                                                <Grid container spacing={2} alignItems={'center'}>
                                                    <Grid item><img src={Notificationuser} /></Grid>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item xs={12}>{row.first_name} {row.last_name}</Grid>
                                                            <Grid item xs={12}>Admin</Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container spacing={1}>
                                                    <Grid item>
                                                        <CommonButton buttonName={<EditIcon />} fontSize='1rem' minWidth='40px' padding='7px' onClick={() => handleEditAdmin(row)} />
                                                    </Grid>
                                                    <Grid item>
                                                        {/* <DeleteModal head='Admin' para='this Admin' /> */}

                                                        <CommonButton buttonName={<DeleteIcon />} fontSize='1rem' minWidth='40px' padding='7px 8px' onClick={() => handleDeleteAdmin(row.id)} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                );
                            })
                        }
                    </Box>
                </DialogContent>
                <DialogActions>
                    <CommonButton onClick={handleClose} buttonName="Close" />
                </DialogActions>
            </Dialog>

            <Dialog open={manageAdminPopup} onClose={handleCloseManageAdmin} sx={{ '& .MuiDialog-paper': { width: '500px' } }}>
                <AddAdmin icon='Add User' handleCloseManageAdmin={handleCloseManageAdmin} setShow={setShow} editAdmintData={editAdmintData} setEditAdmintData={setEditAdmintData} getAdminLists={getAdminLists} />
            </Dialog>


            <Dialog open={deleteAdminPopup} onClose={handleCloseDeletePopup} sx={{ '& .MuiDialog-paper': { width: '500px' } }}>
                <DialogTitle>Delete Admin</DialogTitle>
                <DialogContent dividers >
                    <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                        Are You Sure you want to delete {props.para} ?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <CommonButton onClick={handleCloseDeletePopup} buttonName="No" />
                    <CommonButton onClick={() => confirmDeleteAdmin()} buttonName="Yes" />
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ManageAdmin