import React, { useState } from 'react'
import { CloseIcon, LinkIcon } from '../../../assets/icons/Icons'
import { Chip, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography, InputAdornment, Box, Stack } from '@mui/material';
import CommonButton from '../../../components/common/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CommonInput from '../../../components/common/Input';
import { getLocationList } from '../../../Service/keyword.service';
import LoadingButton from '@mui/lab/LoadingButton';

const SearchKeyword = (props) => {
    const { handleCloseModal, selectedLocations, setSelectedLocations, selectedKeywords, setSelectedKeywords, websiteUrl, setWebsiteUrl, handelSubmit, urlValidation, setUrlValidation, loading, setLoading } = props;
    const [locations, setLocations] = useState([]);


    const getSelectedItem = async (e) => {
        if (e.target.value.length >= 3) {
            let qry = `?location=${e.target.value}`;
            const res = await getLocationList(qry)
            if (res?.data?.status) {
                setLocations(res?.data?.data)
            } else {
                setLocations([])
            }
        }
    }

    const getSelectedLocation = (value) => {
        if (value) {
            selectedLocations.push({ name: value.place_name, id: value.resource_code });
            const neItems = [...selectedLocations];
            setSelectedLocations(neItems)
        }
    }

    const handleRemoveLocation = (index) => {
        selectedLocations.splice(index, 1);
        const neItems = [...selectedLocations];
        setSelectedLocations(neItems);
    }

    const handleDeleteKeywords = (index) => {
        selectedKeywords.splice(index, 1);
        const neItems = [...selectedKeywords];
        setSelectedKeywords(neItems);
    }

    const handelKeywords = (e) => {
        if (e.code === "Enter") {
            selectedKeywords.push(e.target.value);
            const neItems = [...selectedKeywords];
            setSelectedKeywords(neItems);
            e.target.value = ""
        }
    }

    const handleURLInput = (e) => {
        // var res = e.target.value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        var res = e.target.value.match(/[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi);
        if (res == null) {
            setUrlValidation(true)
        }
        else {
            setUrlValidation(false)
            setWebsiteUrl(e.target.value)
        }
    }

    const handleUrlChnage = (e) => {
        setWebsiteUrl(e.target.value)
    }

    const handleSearchCloseModel = () => {
        setSelectedLocations([]);
        setSelectedKeywords([]);
        setWebsiteUrl();
        handleCloseModal();
    }

    return (
        <>
            <DialogTitle>Keyword Search
                <IconButton
                    aria-label="close"
                    onClick={() => handleSearchCloseModel()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'grey',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" width={'100%'}>
                            <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                                Search Locations
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography className='myprofile_input' sx={{ width: '100%' }}>
                                <Autocomplete
                                    freeSolo
                                    id="free-solo-2-demo"
                                    disableClearable
                                    options={locations}
                                    onChangeCapture={getSelectedItem}
                                    onChange={(event, value) => getSelectedLocation(value)}
                                    getOptionLabel={(option) =>
                                        option ? option.place_name : ""
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="filled"
                                            placeholder="Search Locations"
                                            label=""
                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'search',
                                            }}
                                        />
                                    )}
                                />
                            </Typography>
                        </Stack>

                    </Grid>
                    <Grid item xs={12}>
                        <Stack flexDirection={'row'} flexWrap={'wrap'} gap={'5px'}>
                            {
                                selectedLocations.map((item, index) => {
                                    return (
                                        <Box sx={{ border: '1px solid #d8d8d8', width: 'max-content', padding: '0 0 0 10px', borderRadius: '4px' }}>{item.name}
                                            <CloseIcon sx={{ color: '#fff', background: '#d8d8d8', width: '26px', height: '26px', marginLeft: '10px' }} onClick={() => handleRemoveLocation(index)} /></Box>
                                    )
                                })
                            }
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" width={'100%'}>
                            <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                                Keyword List
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography className='myprofile_input' sx={{ width: '100%' }}>
                                <CommonInput fullWidth placeholder="Enter Keyword then press Enter" onKeyUpCapture={handelKeywords} type='text' />
                            </Typography>
                        </Stack>

                    </Grid>
                    <Grid item xs={12}>
                        <Stack flexDirection={'row'} flexWrap={'wrap'} gap={'5px'}>
                            {
                                selectedKeywords.map((item, index) => {
                                    return (
                                        <Chip label={item} variant="outlined" onDelete={() => handleDeleteKeywords(index)} />
                                    )
                                })
                            }
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" width={'100%'}>
                            <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                                Enter a site to filter unrelated keyword
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography className='myprofile_input' sx={{ width: '100%' }}>
                                <div className='p_relative'>
                                    {/* <CommonInput fullWidth value={websiteUrl ? websiteUrl : ""} onChange={handleUrlChnage} placeholder="Enter a site to filter unrelated keyword" type='url' onBlur={handleURLInput} /> */}
                                    <CommonInput fullWidth value={websiteUrl ? websiteUrl : ""} onChange={handleUrlChnage} placeholder="Enter a site to filter unrelated keyword" type='url' />
                                    <InputAdornment position="end" className="eye_btn">
                                        <IconButton
                                            edge="end"
                                        >
                                            <LinkIcon />
                                        </IconButton>
                                    </InputAdornment>
                                    {urlValidation ? (<Typography sx={{ color: "red" }}>Please enter valid URL</Typography>) : ""}
                                </div>
                            </Typography>
                        </Stack>

                    </Grid>
                </Grid >
            </DialogContent >
            <DialogActions>
                <CommonButton onClick={() => handleSearchCloseModel()} buttonName="Cancel" />
                {/* <CommonButton buttonName='apply' onClick={() => handelSubmit()} /> */}
                <LoadingButton
                    variant="contained"
                    size="large"
                    onClick={() => handelSubmit()}
                    loading={loading}
                >
                    <span>apply</span>
                </LoadingButton>
            </DialogActions>

        </>
    )
}

export default SearchKeyword