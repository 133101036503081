import React from 'react'
import { Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CommonButton from '../../../components/common/Button';
import { CheckCircleOutlineIcon } from '../../../assets/icons/Icons';

const UserSuccessMessage = () => {
    const navigate = useNavigate();

    return (
        <Container sx={{
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            maxWidth: { xs: '370px', sm: '550px' },
        }}>
            <Typography variant='primary'><CheckCircleOutlineIcon sx={{ fontSize: '120px', color:'var(--primary)',padding:'10px' }} /></Typography>
            <Typography align='center' fontSize={20}>Email Verification Successfully.</Typography>
            <Typography align='center' fontSize={20}>Change password Link has been sent in your mail...</Typography>
            <Typography align='center' sx={{ paddingTop: { xs: '10px', sm: '18px' } }}>
                <CommonButton buttonName='Okay' onClick={() => { navigate('/') }} padding='3px 20px' fontSize='20px' />
            </Typography>
        </Container>
    )
}

export default UserSuccessMessage