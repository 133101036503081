import React, { useState, useEffect } from "react";
import TableSearch from "../../../../../components/common/Table/TableSearch";
import {
  Grid,
  Stack,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Pagination,
  Typography,
  Link,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useStyles } from "../../../../../helper";
import CRMChart from "../CRMChart";
import {
  getAdsOfListSEM,
  getAdsOfListGSM,
  getAdsOfListYouTube,
} from "../../../../../Service/facebook.service";
import { FaArrowUp } from "react-icons/fa6";
import { FaArrowDown } from "react-icons/fa6";
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#1987540a",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));

const AdGroup = (props) => {
  const {
    lebel1,
    lebel2,
    xLabels,
    fromDate,
    toDate,
    setSearch,
    pagePerSize,
    totalRecords,
    totalPages,
    page,
    setPage,
    handleAdGroupClick,
    groupDataListSEM,
    adsDataList,
    setAdsDataList,
    listOfAdsAll,
    setListOfAdsAll,
    setAdTabName,
    googleChannel,
    pageAd,
    setPageAd,
    pagePerSizeAd,
    setPagePerSizeAd,
    totalRecordsAd,
    setTotalRecordsAd,
    totalPagesAd,
    youtubeFlag,
    setTotalPagesAd,
    setGroupShortBy,
    setGroupShortByField,
    handleClickOnCampion,
    campionRowData,
    groupShortBy,
    adShortBy,
    adShortByField,
    setAdRowData,
    adRowData,
  } = props;
  const classes = useStyles();

  const [sortGroupCTR, setSortGroupCTR] = useState(false);
  const [sortGroupCPC, setSortGroupCPC] = useState(false);
  const [sortGroupImp, setSortGroupImp] = useState(false);
  const [sortGroupClick, setSortGroupClick] = useState(false);
  const [sortGroupCost, setSortGroupCost] = useState(false);

  //
  const [conversions, setConversions] = useState(false);
  const [avCpv, setAvCpv] = useState(false);
  const [vVRate, setVVRate] = useState(false);
  const [views, setViews] = useState(false);

  const handleClickonGroup = async (clickData) => {
    if (clickData) {
      setAdRowData(clickData);
      setAdTabName(clickData.adgroup_name);
      const qry = `?start_date=${fromDate}&end_date=${toDate}&page=${pageAd}&filter_field=${adShortByField}&filter_type=1`;
      let getads = [];
      if (googleChannel == 0) {
        getads = await getAdsOfListSEM(qry, clickData.id);
      }
      if (googleChannel == 1) {
        getads = await getAdsOfListGSM(qry, clickData.id);
      }
      if (googleChannel == 2) {
        getads = await getAdsOfListYouTube(qry, clickData.id);
      }
      const { data } = getads;
      if (data.status == true) {
        setListOfAdsAll(data.data);
        setTotalRecordsAd(data?.count);
        setTotalPagesAd(Math.ceil(data?.count / pagePerSize));
        handleAdGroupClick();
      } else {
        setListOfAdsAll([]);
        setTotalRecordsAd(0);
        setTotalPagesAd(0);
      }
    }
  };

  const handleSortClick = (name) => {
    switch (name) {
      case "impressions":
        setSortGroupImp((current) => !current);
        setSortGroupCTR(false);
        setSortGroupCPC(false);
        setSortGroupClick(false);
        setSortGroupCost(false);
        setConversions(false);
        setAvCpv(false);
        setVVRate(false);
        setViews(false);
        setGroupShortByField(name);
        break;
      case "clicks":
        setSortGroupClick((current) => !current);
        setSortGroupImp(false);
        setSortGroupCTR(false);
        setSortGroupCPC(false);
        setSortGroupCost(false);
        setConversions(false);
        setAvCpv(false);
        setVVRate(false);
        setViews(false);
        setGroupShortByField(name);
        break;
      case "ctr":
        setSortGroupCTR((current) => !current);
        setSortGroupClick(false);
        setSortGroupImp(false);
        setSortGroupCPC(false);
        setSortGroupCost(false);
        setConversions(false);
        setAvCpv(false);
        setVVRate(false);
        setViews(false);
        setGroupShortByField(name);
        break;
      case "averageCpc":
        setSortGroupCPC((current) => !current);
        setSortGroupCTR(false);
        setSortGroupClick(false);
        setSortGroupImp(false);
        setSortGroupCost(false);
        setConversions(false);
        setAvCpv(false);
        setVVRate(false);
        setViews(false);
        setGroupShortByField(name);
        break;
      case "costMicros":
        setSortGroupCost((current) => !current);
        setSortGroupCPC(false);
        setSortGroupCTR(false);
        setSortGroupClick(false);
        setSortGroupImp(false);
        setConversions(false);
        setAvCpv(false);
        setVVRate(false);
        setViews(false);
        setGroupShortByField(name);
        break;
      case "conversions":
        setSortGroupCost(false);
        setSortGroupCPC(false);
        setSortGroupCTR(false);
        setSortGroupClick(false);
        setSortGroupImp(false);
        setConversions((current) => !current);
        setAvCpv(false);
        setVVRate(false);
        setViews(false);
        setGroupShortByField(name);
        break;
      case "averageCpv":
        setSortGroupCost(false);
        setSortGroupCPC(false);
        setSortGroupCTR(false);
        setSortGroupClick(false);
        setSortGroupImp(false);
        setConversions(false);
        setAvCpv((current) => !current);
        setVVRate(false);
        setViews(false);
        setGroupShortByField(name);
        break;
      case "videoViewRate":
        setSortGroupCost(false);
        setSortGroupCPC(false);
        setSortGroupCTR(false);
        setSortGroupClick(false);
        setSortGroupImp(false);
        setConversions(false);
        setAvCpv(false);
        setVVRate((current) => !current);
        setViews(false);
        setGroupShortByField(name);
        break;
      case "videoViews":
        setSortGroupCost(false);
        setSortGroupCPC(false);
        setSortGroupCTR(false);
        setSortGroupClick(false);
        setSortGroupImp(false);
        setConversions(false);
        setAvCpv(false);
        setVVRate(false);
        setViews((current) => !current);
        setGroupShortByField(name);
        break;
      default:
        setSortGroupImp((current) => !current);
        setSortGroupCTR(false);
        setSortGroupCPC(false);
        setSortGroupClick(false);
        setSortGroupCost(false);
        setConversions(false);
        setGroupShortByField(name);
        break;
    }
  };

  useEffect(() => {
    setGroupShortBy(!groupShortBy);
    handleClickOnCampion(campionRowData);
  }, [
    sortGroupImp,
    sortGroupCTR,
    sortGroupCPC,
    sortGroupClick,
    sortGroupCost,
    conversions,
    avCpv,
    vVRate,
    views
  ]);

  return (
    <>
      {/* <Grid className={classes.cardContainer} sx={{ marginTop: '10px' }}>
                <CRMChart lebel1={lebel1 ? lebel1 : ['']} lebel2={lebel2 ? lebel2 : ['']} xLabels={xLabels ? xLabels : ['']} />
            </Grid> */}
      <Stack
        justifyContent="end"
        width="100%"
        sx={{
          marginBottom: { xs: 1 },
          marginTop: { xs: 2 },
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: 1 },
          alignItems: { xs: "start", sm: "end" },
        }}
      >
        <TableSearch />
      </Stack>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: "700", color: "#000" }}>
                Ad Group
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "700", color: "#000" }}>
                Status
              </StyledTableCell>
              <StyledTableCell
                sx={{ fontWeight: "700", color: "#000", cursor: "pointer" }}
                onClick={() => handleSortClick("impressions")}
              >
                Impressions
                {sortGroupImp ? (
                  <FaArrowUp style={{ color: "#adadad" }} />
                ) : (
                  <FaArrowDown style={{ color: "#adadad" }} />
                )}
              </StyledTableCell>
              <StyledTableCell
                sx={{ fontWeight: "700", color: "#000", cursor: "pointer" }}
                onClick={() => handleSortClick("clicks")}
              >
                Clicks
                {sortGroupClick ? (
                  <FaArrowUp style={{ color: "#adadad" }} />
                ) : (
                  <FaArrowDown style={{ color: "#adadad" }} />
                )}
              </StyledTableCell>
              <StyledTableCell
                sx={{ fontWeight: "700", color: "#000", cursor: "pointer" }}
                onClick={() => handleSortClick("ctr")}
              >
                Click-Through-Rate(CTR)%
                {sortGroupCTR ? (
                  <FaArrowUp style={{ color: "#adadad" }} />
                ) : (
                  <FaArrowDown style={{ color: "#adadad" }} />
                )}
              </StyledTableCell>
              <StyledTableCell
                sx={{ fontWeight: "700", color: "#000", cursor: "pointer" }}
                onClick={() => handleSortClick("averageCpc")}
              >
                Cost-Per-Click(CPC)$
                {sortGroupCPC ? (
                  <FaArrowUp style={{ color: "#adadad" }} />
                ) : (
                  <FaArrowDown style={{ color: "#adadad" }} />
                )}
              </StyledTableCell>
              <StyledTableCell
                sx={{ fontWeight: "700", color: "#000", cursor: "pointer" }}
                onClick={() => handleSortClick("costMicros")}
              >
                Cost($)
                {sortGroupCost ? (
                  <FaArrowUp style={{ color: "#adadad" }} />
                ) : (
                  <FaArrowDown style={{ color: "#adadad" }} />
                )}
              </StyledTableCell>
              <StyledTableCell
                sx={{ fontWeight: "700", color: "#000", cursor: "pointer" }}
                onClick={() => handleSortClick("conversions")}
              >
                Conversions
                {conversions ? (
                  <FaArrowUp style={{ color: "#adadad" }} />
                ) : (
                  <FaArrowDown style={{ color: "#adadad" }} />
                )}
              </StyledTableCell>
              {youtubeFlag && (
                <>
                  <StyledTableCell
                    sx={{ fontWeight: "700", color: "#000", cursor: "pointer" }}
                    onClick={() => handleSortClick("averageCpv")}
                  >
                    AverageCpv
                    {avCpv ? (
                      <FaArrowUp style={{ color: "#adadad" }} />
                    ) : (
                      <FaArrowDown style={{ color: "#adadad" }} />
                    )}
                  </StyledTableCell>

                  <StyledTableCell
                    sx={{ fontWeight: "700", color: "#000", cursor: "pointer" }}
                    onClick={() => handleSortClick("videoViewRate")}
                  >
                    VideoViewRate
                    {vVRate ? (
                      <FaArrowUp style={{ color: "#adadad" }} />
                    ) : (
                      <FaArrowDown style={{ color: "#adadad" }} />
                    )}
                  </StyledTableCell>

                  <StyledTableCell
                    sx={{ fontWeight: "700", color: "#000", cursor: "pointer" }}
                    onClick={() => handleSortClick("videoViews")}
                  >
                    Views
                    {views ? (
                      <FaArrowUp style={{ color: "#adadad" }} />
                    ) : (
                      <FaArrowDown style={{ color: "#adadad" }} />
                    )}
                  </StyledTableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {groupDataListSEM?.map((row, index) => {
              var {
                id,
                adgroup_name,
                status,
                impressions,
                clicks,
                ctr,
                averageCpc,
                conversions,
                costMicros,
                averageCpv,
                videoViewRate,
                videoViews,
                hedged_cost,
                hedged_cpc
              } = row;
              return (
                <StyledTableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleClickonGroup(row)}
                >
                  {/* onClick={handleAdGroupClick} */}
                  <TableCell component="th" scope="row">
                    {adgroup_name}
                  </TableCell>
                  <TableCell>{status}</TableCell>
                  <TableCell>{impressions}</TableCell>
                  <TableCell>{clicks}</TableCell>
                  <TableCell>{ctr}</TableCell>
                  <TableCell>
                    {averageCpc ? averageCpc.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    }) : hedged_cpc.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </TableCell>
                  <TableCell>
                    {costMicros ? costMicros.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    }) : hedged_cost.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </TableCell>
                  <TableCell>{conversions || conversions.toFixed(2)}</TableCell>
                  {youtubeFlag && (
                    <>
                      <TableCell>{averageCpv}</TableCell>
                      <TableCell>{videoViewRate}</TableCell>
                      <TableCell>{videoViews}</TableCell>
                    </>
                  )}
                </StyledTableRow>
              );
            })}
            {groupDataListSEM === null ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="6"
                >
                  No records to display
                </TableCell>
              </TableRow>
            ) : (
              ""
            )}
            {/* <StyledTableRow >
                            <TableCell colSpan="2"></TableCell>
                            <TableCell >{sumOfClicks}</TableCell>
                            <TableCell >{sumOfClicks}</TableCell>
                            <TableCell colSpan="4">dsdsd</TableCell>
                        </StyledTableRow> */}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          Showing {groupDataListSEM && groupDataListSEM.length} of{" "}
          {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          //   onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
};

export default AdGroup;
