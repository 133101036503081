import React, { useState } from 'react'
import { useStyles, crmadsrows, crmadscolumns } from '../../../../helper'
import TableSearch from '../../../../components/common/Table/TableSearch';
import CRMChart from './CRMChart';
import {
  Grid, Stack, TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Pagination,
  Typography,
  Link,
  Box
} from '@mui/material';
import { styled } from "@mui/material/styles";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import AdGroup from './adgroup/AdGroup';
import Sem from './adgroup/sem/Sem';
import { getGroupOfList } from "../../../../Service/facebook.service";
import { FaArrowUp } from "react-icons/fa6";
import { FaArrowDown } from "react-icons/fa6";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#1987540a",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));


const CRMAds = (props) => {
  const {
    lebel1,
    lebel2,
    xLabels,
    fromDate,
    toDate,
    data,
    pagePerSize,
    totalRecords,
    totalPages,
    page,
    setPage,
    googleChannel,
    shortKey,
    setShortKey,
    sortBy,
    setSortBy
  } = props;
  const classes = useStyles();
  const [campaign, setCampaign] = useState(true);
  const [adGroup, setAdGroup] = useState(false);
  const [semList, setSemList] = useState(false);
  const [breAdGroup, setBreAdGroup] = useState(false);
  const [breSemList, setBreSemList] = useState(false);
  const [groupDataListSEM, setGroupDataListSEM] = useState([]);
  const [adsDataList, setAdsDataList] = useState([]);
  const [selectedCapionId, setSelectedCapionId] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [pageSub, setPageSub] = useState(1);
  const [pagePerSizeSub, setPagePerSizeSub] = useState(10);
  const [totalRecordsSub, setTotalRecordsSub] = useState(0);
  const [totalPagesSub, setTotalPagesSub] = useState(0);
  const [search, setSearch] = useState('');
  const [listOfAdsAll, setListOfAdsAll] = useState([]);
  const [adGroupName, setGroupTabName] = useState("")
  const [adAdName, setAdTabName] = useState("")

  const [pageAd, setPageAd] = useState(1);
  const [pagePerSizeAd, setPagePerSizeAd] = useState(10);
  const [totalRecordsAd, setTotalRecordsAd] = useState(0);
  const [totalPagesAd, setTotalPagesAd] = useState(0);

  const [sortBudget, setSortBudget] = useState(false);
  const [sortImp, setSortImp] = useState(false);
  const [sortClick, setSortClick] = useState(false);
  const [sortCost, setSortCost] = useState(false);
  // 
  const [sortCtr, setSortCtr] = useState(false);
  const [sortCpc, setSortCpc] = useState(false);
  const [sortConversions, setSortConversions] = useState(false);

  const [groupShortBy, setGroupShortBy] = useState(1)
  const [groupShortByField, setGroupShortByField] = useState("");
  const [campionRowData, setcampionRowData] = useState({})

  const [adShortBy, setAdShortBy] = useState(1)
  const [adShortByField, setAdShortByField] = useState("");
  const [adRowData, setAdRowData] = useState({})

  const handleCampaignClick = () => {
    setCampaign(false)
    setAdGroup(true)
    setSemList(false)
    setBreAdGroup(true)
    setBreSemList(false)
  }

  const handleAdGroupClick = () => {
    setCampaign(false)
    setAdGroup(false)
    setSemList(true)
    setBreSemList(true)
  }

  const handleCampaignBreClick = () => {
    setBreAdGroup(false)
    setAdGroup(false)
    setSemList(false)
    setCampaign(true)
  }

  const handleClickOnCampion = async (clickData) => {
    setSelectedCapionId(clickData.id);
    setcampionRowData(clickData)
    setGroupTabName(clickData.ad_name);
    const qry = `?start_date=${fromDate}&end_date=${toDate}&page=${page}&filter_field=${groupShortByField}&filter_type=${groupShortBy ? 1 : -1}`;
    const getGroupList = await getGroupOfList(qry, clickData.id)
    const { data } = getGroupList;
    if (data.status == true) {
      setGroupDataListSEM(data.data)
      setTotalRecordsSub(data?.count);
      setTotalPagesSub(Math.ceil(data?.count / pagePerSize));
      setCampaign(false)
      setAdGroup(true)
      setSemList(false)
      setBreAdGroup(true)
      setBreSemList(false)
    }
  }

  const handleChangePage = (event, value) => {
    setPage(value);
    // getClientList(value);
  };


  const handleSortClick = (name) => {
    switch (name) {
      case 'amountMicros':
        setSortBudget((current) => !current)
        setSortImp(false)
        setSortClick(false)
        setSortCost(false)
        setSortCtr(false);
        setSortCpc(false);
        setSortConversions(false);
        setSortBy(sortBudget ? 1 : -1)
        setShortKey(name);
        break;
      case 'impressions':
        setSortImp((current) => !current)
        setSortBudget(false)
        setSortClick(false)
        setSortCost(false)
        setSortBy(sortImp ? 1 : -1)
        setSortCtr(false);
        setSortCpc(false);
        setSortConversions(false);
        setShortKey(name);
        break;
      case 'clicks':
        setSortClick((current) => !current)
        setSortImp(false)
        setSortBudget(false)
        setSortCost(false)
        setSortCtr(false);
        setSortCpc(false);
        setSortConversions(false);
        setSortBy(sortClick ? 1 : -1)
        setShortKey(name);
        break;
      case 'costMicros':
        setSortCost((current) => !current)
        setSortClick(false)
        setSortImp(false)
        setSortBudget(false)
        setSortCtr(false);
        setSortCpc(false);
        setSortCtr(false);
        setSortConversions(false);
        setSortConversions(false);
        setSortBy(sortCost ? 1 : -1)
        setShortKey(name);
        break;
      case 'ctr':
        setSortClick(false)
        setSortImp(false)
        setSortBudget(false)
        setSortCost(false)
        setSortCtr((current) => !current);
        setSortCpc(false);
        setSortConversions(false);
        setSortBy(sortCtr ? 1 : -1)
        setShortKey(name);
        break;
      case 'averageCpc':
        setSortClick(false)
        setSortImp(false)
        setSortBudget(false)
        setSortCost(false)
        setSortCtr(false);
        setSortCpc((current) => !current);
        setSortConversions(false);
        setSortBy(sortCpc ? 1 : -1)
        setShortKey(name);
        break;
      case 'conversions':
        setSortClick(false)
        setSortImp(false)
        setSortBudget(false)
        setSortCost(false)
        setSortCtr(false);
        setSortCpc(false);
        setSortConversions((current) => !current);
        setSortBy(sortConversions ? 1 : -1)
        setShortKey(name);
        break;
      default:
        setSortClick((current) => !current)
        setSortImp(false)
        setSortBudget(false)
        setSortCost(false)
        setSortCtr(false);
        setSortCpc(false);
        setSortConversions(false);
        setSortBy(sortClick ? 1 : -1)
        setShortKey(name);
        break;
    }
  };

  return (
    <>
      <Stack justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: 1 }, marginTop: { xs: 2 }, flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 1 }, alignItems: { xs: 'start', sm: 'end' } }}>
        <Breadcrumbs aria-label="breadcrumb" separator="›">
          <Link underline="hover" color="inherit" onClick={handleCampaignBreClick} style={{ cursor: 'pointer' }}>
            Campaigns
          </Link>
          {breAdGroup &&
            <Link underline="hover" color="inherit" onClick={handleCampaignClick} style={{ cursor: 'pointer' }}>
              {adGroupName}
            </Link>}
          {breSemList &&
            <Link underline="hover" color="text.primary" style={{ cursor: 'pointer' }}>
              {adAdName}
            </Link>}
        </Breadcrumbs>
      </Stack>

      {
        campaign &&
        <>
          {/* <Grid className={classes.cardContainer} sx={{ marginTop: '10px' }}>
            <CRMChart lebel1={lebel1 ? lebel1 : ['']} lebel2={lebel2 ? lebel2 : ['']} xLabels={xLabels ? xLabels : ['']} />
          </Grid> */}
          <Stack justifyContent="end" width='100%' sx={{ marginBottom: { xs: 1 }, marginTop: { xs: 2 }, flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 1 }, alignItems: { xs: 'start', sm: 'end' } }}>
            <TableSearch onChange={(e) => {
              setSearch(e.target.value)
              setPage(1)
            }} />
          </Stack>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                    Campaign Name
                  </StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: '700', color: '#000', cursor: 'pointer' }} onClick={() => handleSortClick('amountMicros')}>
                    <Stack flexDirection={'row'} alignItems={'center'} gap={'3px'}>
                      Budget/Day
                      {sortBudget ?
                        <FaArrowUp style={{ color: '#adadad' }} />
                        :
                        <FaArrowDown style={{ color: '#adadad' }} />
                      }
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: '700', color: '#000', cursor: 'pointer' }} onClick={() => handleSortClick('impressions')}>
                    Impressions
                    {sortImp ?
                      <FaArrowUp style={{ color: '#adadad' }} />
                      :
                      <FaArrowDown style={{ color: '#adadad' }} />
                    }
                  </StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: '700', color: '#000', cursor: 'pointer' }} onClick={() => handleSortClick('clicks')}>
                    Clicks
                    {sortClick ?
                      <FaArrowUp style={{ color: '#adadad' }} />
                      :
                      <FaArrowDown style={{ color: '#adadad' }} />
                    }
                  </StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: '700', color: '#000', cursor: 'pointer' }} onClick={() => handleSortClick('ctr')}>
                    Click-Through-Rate(CTR)%
                    {sortCtr ?
                      <FaArrowUp style={{ color: '#adadad' }} />
                      :
                      <FaArrowDown style={{ color: '#adadad' }} />
                    }
                  </StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: '700', color: '#000', cursor: 'pointer' }} onClick={() => handleSortClick('averageCpc')}>
                    Cost-Per-Click(CPC)$
                    {sortCpc ?
                      <FaArrowUp style={{ color: '#adadad' }} />
                      :
                      <FaArrowDown style={{ color: '#adadad' }} />
                    }
                  </StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: '700', color: '#000', cursor: 'pointer' }} onClick={() => handleSortClick('conversions')}>
                    Conversions
                    {sortConversions ?
                      <FaArrowUp style={{ color: '#adadad' }} />
                      :
                      <FaArrowDown style={{ color: '#adadad' }} />
                    }
                  </StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: '700', color: '#000', cursor: 'pointer' }} onClick={() => handleSortClick('costMicros')}>
                    Cost($)
                    {sortCost ?
                      <FaArrowUp style={{ color: '#adadad' }} />
                      :
                      <FaArrowDown style={{ color: '#adadad' }} />
                    }
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row, index) => {
                  var {
                    ad_id,
                    ad_name,
                    conversions,
                    impressions,
                    ctr,
                    spend,
                    costMicros,
                    clicks,
                    amountMicros,
                    averageCpc,
                    hedged_cost,
                    hedged_cpc
                  } = row;

                  return (
                    <StyledTableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClickOnCampion(row)}
                    >
                      {/* onClick={handleCampaignClick} */}
                      <TableCell component="th" scope="row" >
                        {ad_name}
                      </TableCell>
                      <TableCell>
                        {amountMicros.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                      </TableCell>
                      <TableCell>{impressions}</TableCell>
                      <TableCell>{clicks}</TableCell>
                      <TableCell>
                        {ctr}%
                      </TableCell>
                      <TableCell>
                        {/* {Number(averageCpc).toLocaleString("en-US", { style: "currency", currency: "USD" }) || hedged_cpc} */}
                        {averageCpc ? Number(averageCpc).toLocaleString("en-US", { style: "currency", currency: "USD" }) : Number(hedged_cpc).toLocaleString("en-US", { style: "currency", currency: "USD" })}
                      </TableCell>
                      <TableCell>
                        {conversions}
                      </TableCell>
                      <TableCell>
                        {/* {costMicros?.toLocaleString("en-US", { style: "currency", currency: "USD" })} */}
                        {costMicros ? costMicros?.toLocaleString("en-US", { style: "currency", currency: "USD" }) : hedged_cost?.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
                {data?.length === 0 ? (
                  <TableRow>
                    <TableCell
                      sx={{
                        color: "silver",
                        textAlign: "center",
                        paddingTop: "90px",
                        borderBottom: "none",
                        fontSize: "22px",
                      }}
                      colSpan="7"
                    >
                      No records to display
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack
            sx={{ mt: 4, pb: 2 }}
            flexDirection="row"
            justifyContent="space-between"
          >
            <div>
              Showing{" "}
              {data && data.length}
              of {totalRecords} Results
            </div>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
            />
          </Stack>
        </>
      }
      {adGroup && <AdGroup
        lebel1={lebel1}
        ebel2={lebel2}
        youtubeFlag={false}
        xLabels={xLabels}
        fromDate={fromDate}
        toDate={toDate}
        pagePerSize={pagePerSizeSub}
        totalRecords={totalRecordsSub}
        totalPages={totalPagesSub}
        page={pageSub}
        setPage={setPageSub}
        handleAdGroupClick={handleAdGroupClick}
        groupDataListSEM={groupDataListSEM}
        adsDataList={adsDataList}
        setAdsDataList={setAdsDataList}
        listOfAdsAll={listOfAdsAll}
        setListOfAdsAll={setListOfAdsAll}
        setAdTabName={setAdTabName}
        googleChannel={googleChannel}
        pageAd={pageAd}
        setPageAd={setPageAd}
        pagePerSizeAd={pagePerSizeAd}
        setPagePerSizeAd={setPagePerSizeAd}
        totalRecordsAd={totalRecordsAd}
        setTotalRecordsAd={setTotalRecordsAd}
        totalPagesAd={totalPagesAd}
        setTotalPagesAd={setTotalPagesAd}
        setGroupShortBy={setGroupShortBy}
        setGroupShortByField={setGroupShortByField}
        handleClickOnCampion={handleClickOnCampion}
        campionRowData={campionRowData}
        groupShortBy={groupShortBy}
        adShortBy={adShortBy}
        adShortByField={adShortByField}
        setAdRowData={setAdRowData}
        adRowData={adRowData}
      />}
      {semList && <Sem
        lebel1={lebel1}
        lebel2={lebel2}
        xLabels={xLabels}
        fromDate={fromDate}
        toDate={toDate}
        listOfAdsAll={listOfAdsAll}
        setListOfAdsAll={setListOfAdsAll}
        pageAd={pageAd}
        setPageAd={setPageAd}
        pagePerSizeAd={pagePerSizeAd}
        setPagePerSizeAd={setPagePerSizeAd}
        totalRecordsAd={totalRecordsAd}
        setTotalRecordsAd={setTotalRecordsAd}
        totalPagesAd={totalPagesAd}
        setTotalPagesAd={setTotalPagesAd}
        adShortBy={adShortBy}
        adShortByField={adShortByField}
        setAdShortBy={setAdShortBy}
        setAdShortByField={setAdShortByField}
        setAdRowData={setAdRowData}
        adRowData={adRowData}
        googleChannel={googleChannel}
        handleAdGroupClick={handleAdGroupClick}
        pagePerSize={pagePerSizeSub}
      />}
    </>
  )
}

export default CRMAds
