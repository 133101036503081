import React from 'react'
import PageHeading from '../../../components/common/PageHeading'
import { Grid, Stack, Switch, ThemeProvider, Typography } from '@mui/material'
import { theme } from '../../../helper';
import ManageAdmin from './ManageAdmin';
import ManageClient from './ManageClient';
import Notification from '../../../components/common/Notification/Notification';
import CommonButton from '../../../components/common/Button'
import { AddIcon, CloseIcon, EditIcon } from '../../../assets/icons/Icons'
import { useStyles } from '../../../helper'
import { Dialog } from '@mui/material';



const Setting = () => {
    const classes = useStyles();
    const [show, setShow] = React.useState({
        show: false,
        type: '',
        message: ''
    });
    const [openAdd, setOpenAdd] = React.useState(false);
    const handleOpen = () => {
        setOpenAdd(true);
    };
    const handleClose = () => {
        setOpenAdd(false);
    };

    return (
        <>
            <PageHeading heading='Settings' />
            <ThemeProvider theme={theme}>
                <Grid container spacing={{ xs: 2, sm: 3 }} paddingTop={2}>
                    <Grid item xs={12}>
                        <Typography>Notification</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <CommonButton buttonName={
                                    <Grid container spacing={2} alignItems='center'>
                                        <Grid item ><AddIcon className={classes.settingIcon} /></Grid>
                                        <Grid item className={classes.settingBtn}>Manage Admin</Grid>
                                    </Grid>
                                } padding='5px 20px' onClick={handleOpen} />
                            </Grid>
                            {/* <Grid item>
                                <ManageClient />
                            </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack>
                            <Typography variant='h5'>Notification Settings</Typography>
                            <Typography>We may still send you important notifications about your account outside of your notification settings.</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={9} sm={5}><Typography variant='h6'>Comments</Typography></Grid>
                                    <Grid item> <Switch defaultChecked color="primary" /></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={9} sm={5}><Typography variant='h6'>Tags</Typography></Grid>
                                    <Grid item> <Switch color="primary" /></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={9} sm={5}><Typography variant='h6'>Reminders</Typography></Grid>
                                    <Grid item> <Switch color="primary" /></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={9} sm={5}><Typography variant='h6'>More Activity about you</Typography></Grid>
                                    <Grid item> <Switch defaultChecked color="primary" /></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ThemeProvider>

            <Dialog open={openAdd} onClose={handleClose} sx={{ '& .MuiDialog-paper': { width: '500px' } }}>
                <ManageAdmin setShow={setShow} openAdd={openAdd} handleClose={handleClose} setOpenAdd={setOpenAdd} />
            </Dialog>

            <Notification show={show} setShow={setShow} />
        </>
    )
}

export default Setting