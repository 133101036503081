import * as React from 'react';
import PageHeading from '../../../components/common/PageHeading';
import TableSearch from '../../../components/common/Table/TableSearch';
import { Grid, ListItemText, MenuItem, Select, Stack, Tooltip } from '@mui/material';
import UploadFile from './UploadFile';
import LeadTable from './LeadTable';
import DateRange from '../../../components/common/Table/DateRange';
import TableDropDown from '../../../components/common/Table/TableDropDown';
import { useState, useEffect } from 'react';
import Notification from '../../../components/common/Notification/Notification';
import { useSelector } from 'react-redux'
import { styled } from "@mui/material/styles";
import Skeleton from '@mui/material/Skeleton';
import { DeleteIcon, LockIcon } from '../../../assets/icons/Icons';
import { theme, useStyles } from '../../../helper';
import QualitySelect from '../../../pages/user/leads/QualitySelect';
import { leadList, updateStatus } from '../../../Service/lead.service'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Chip,
	Pagination,
	DialogContent,
	Typography,
	DialogActions
} from '@mui/material';
import CommonButton from '../../../components/common/Button';
import { Download } from '@mui/icons-material';
import { CSVLink, CSVDownload } from "react-csv";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: "#1987540a",
	},
	// hide last border
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
	color: "#0F0F0F80",
}));

const StyledChip = styled(Chip)(() => ({
	borderRadius: "10px",
	fontSize: "14px",
}));



const Leads = () => {
	const classes = useStyles();

	const [show, setShow] = useState({
		show: false,
		type: '',
		message: ''
	});
	const [channels, setChannels] = useState(null)
	const [leadListData, setLeadListData] = useState([])
	const [leadStatus, setLeadStatus] = useState([]);
	const [page, setPage] = useState(1);
	const [pagePerSize, setPagePerSize] = useState(5);
	const [totalRecords, setTotalRecords] = useState(0);
	const [totalPages, setTotalPages] = useState(0);
	const [search, setSearch] = useState("")
	const [refresh, setRefresh] = useState(false);
	const [clientId, setClientId] = useState("")

	const { client } = useSelector((state) => state?.root);

	useEffect(() => {
		if (client && client?.clientId) {
			getLeadList(client?.clientId)
			setClientId(client?.clientId)
		} else {
			setShow({
				show: true,
				type: 'error',
				message: "Please select client"
			})
		}
	}, [page, search, refresh, channels, client]);
	const handleChangePage = (event, value) => {
		setPage(value);
	};

	const getLeadList = async (id) => {
		try {
			let selectQry = ""
			if (channels == null) {
				selectQry = `?search=${search}&page=${page}`
			}
			else {
				selectQry = `?search=${search}&page=${page}&channel=${channels}`
			}
			const response = await leadList(id, selectQry)
			if (response && response.data && response.data.status == true) {
				setLeadListData(response.data?.data)
				setTotalRecords(response.data?.count)
				setTotalPages(Math.ceil(response.data?.count / pagePerSize));
				setLeadStatus(response?.data?.leads_status)
			} else {
				setTotalRecords(0);
				setTotalPages(0);
				setLeadListData(null);
			}
		} catch (error) {
			setShow({
				show: true,
				type: 'error',
				message: 'Something went wrong'
			})
		}
	}

	const data = [
		{
			"Date": "June 29, 2023",
			"Time": "9:29 AM",
			"Name": "Ms Lee",
			"Phone": 90909090,
			"Email": "lee9090@gmail.com",
			"Message": "Hi I want to enquire about...",
			"URL": "https://ocdigitalnetwork.com/",
			"IP": "110.110.110.11"
		},
		{
			"Date": "September 1, 2023",
			"Time": "10:05 AM",
			"Name": "MDS",
			"Phone": 9191051165,
			"Email": "msd0511@gmail.com",
			"Message": "i need more information about this prodect",
			"URL": "https://ocdigitalnetwork.com/",
			"IP": "110.110.110.11"
		}
	]


	const TableRowsLoader = ({ rowsNum }) => {
		return [...Array(rowsNum)].map((row, index) => (
			<TableRow key={index}>
				<TableCell component="th" scope="row">
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell>

			</TableRow>
		));
	};
	const handelSearch = (e) => {
		setSearch(e.target.value)
	}
	const csvFilePath = 'https://apidms.mobiginie.com/keywordplanner/location_list.csv';
	const downloadFile = () => {
		//download csv file code here
		const link = document.createElement('a');
		link.href = csvFilePath;
		link.download = 'your_file_name.csv'; // Specify the desired file name
		document.body.appendChild(link);

		// Trigger the click event to start the download
		link.click();

		// Clean up by removing the anchor element
		document.body.removeChild(link);
	}

	const handleChange = async (event, id) => {
		const payload = {
			leads_status: event.target.value
		}
		const resUpdateStaurs = await updateStatus(id, payload);
		if (resUpdateStaurs.status) {
			getLeadList(client?.clientId)
			setShow({
				show: true,
				type: 'success',
				message: resUpdateStaurs.message || 'Status updated successfully'
			})
		} else {
			setShow({
				show: true,
				type: 'error',
				message: resUpdateStaurs.message || 'Something went wrong'
			})
		}
	};

	return (
		<>
			<PageHeading heading='Leads' />
			<Notification show={show} setShow={setShow} />

			<Stack direction="row" alignItems="center" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: 1 }, flexDirection: { xs: 'column-reverse', sm: 'row' }, gap: '8px' }}>
				<TableSearch onChange={handelSearch} />
				<Grid container justifyContent='flex-end' alignItems='center' gap={{ xs: '5px', sm: '10px' }} width="inherit">
					<Grid item>
						<CSVLink
							data={data}
							target="_blank"
							style={{ textDecorationLine: "none" }}
							//STOP REDIRECTING TO NEW PAGE
							filename={"demo_file.csv"}

						>
							<CommonButton icon={<Download />}
								buttonName={`Demo file`} fontSize='1rem' minWidth='40px' padding='7px 8px' />
						</CSVLink>
					</Grid>
					<Grid item>
						<TableDropDown setChannels={setChannels} channels={channels} />
						{/* <DateRange /> */}
					</Grid>
					<Grid item>
						<UploadFile channels={channels} setShow={setShow} refresh={refresh} setRefresh={setRefresh} />
					</Grid>
				</Grid>
			</Stack>


			<TableContainer component={Paper} className={classes.tableContainer}>
				<Table stickyHeader aria-label="simple table">
					<TableHead>
						<TableRow>
							<StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
								Channel
							</StyledTableCell>
							<StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
								Name
							</StyledTableCell>
							<StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
								Email
							</StyledTableCell>
							<StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
								Phone
							</StyledTableCell>
							<StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
								Message
							</StyledTableCell>
							<StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
								Submission Date
							</StyledTableCell>
							<StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
								Submission Time
							</StyledTableCell>
							<StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
								Quality of Leads
							</StyledTableCell>
							<StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
								IP Address
							</StyledTableCell>
							<StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
								URL
							</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{leadListData?.map((row, index) => {
							var {
								id,
								channel,
								name,
								email,
								is_active,
								url, ip,
								quality,
								date,
								phone,
								message,
								leads_status,
								time
							} = row;
							email = email ? email : "-";
							return (
								<StyledTableRow
									key={index}
									sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
									// onClick={(e) => navigate(`/admin/client/details`, { state: { id: id } })}
									style={{ cursor: "pointer" }}
								>
									<TableCell component="th" scope="row">
										{channel}
									</TableCell>
									<TableCell>{name}</TableCell>
									<TableCell>{email}</TableCell>

									<TableCell>{phone}</TableCell>
									<TableCell>{message}</TableCell>
									<TableCell>{date}</TableCell>

									<TableCell>{time}</TableCell>
									<TableCell className='leadeStatus'>
										<Select
											value={leads_status}
											onChange={(e) => handleChange(e, id)}
											displayEmpty
											name="qualityoflead"
										>
											{leadStatus?.map((name) => (
												<MenuItem key={name.key} name={name.key} value={name.key}>
													<ListItemText name={name.value} primary={name.value} />
												</MenuItem>
											))}
										</Select>
									</TableCell>
									<TableCell>{ip}</TableCell>
									<TableCell><Tooltip title={url}>{url.slice(0, 27)}</Tooltip></TableCell>



									{/* <TableCell><QualitySelect /></TableCell> */}


								</StyledTableRow>
							);
						})}
						{leadListData === null ? (
							<TableRow>
								<TableCell
									sx={{
										color: "silver",
										textAlign: "center",
										paddingTop: "90px",
										borderBottom: "none",
										fontSize: "25px",
									}}
									colSpan="9"
								>
									No records to display
								</TableCell>
							</TableRow>

						) : (
							leadListData.length === 0 ? (<TableRowsLoader rowsNum={10} />) : ("")
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<Stack
				sx={{ mt: 4, pb: 2 }}
				flexDirection="row"
				justifyContent="space-between"
			>
				<div>
					Showing{" "}
					{leadListData?.length}{" "}
					of {totalRecords} Results
				</div>
				<Pagination
					count={totalPages}
					page={page}
					onChange={handleChangePage}
					variant="outlined"
					shape="rounded"
				/>
			</Stack>
			{/* <LeadTable /> */}
		</>
	)
}

export default Leads