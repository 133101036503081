import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { styled } from "@mui/material/styles";
import Skeleton from '@mui/material/Skeleton';
import { useStyles } from '../../../helper';
import { leadList } from '../../../Service/lead.service'
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Chip,
    Pagination,
    Stack,
    Tooltip
} from '@mui/material';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#1987540a",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    color: "#0F0F0F80",
}));

const StyledChip = styled(Chip)(() => ({
    borderRadius: "10px",
    fontSize: "14px",
}));


const LeadsList = () => {
    const classes = useStyles();
    const [page, setPage] = useState(1);
    const [pagePerSize, setPagePerSize] = useState(5);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [leadListData, setLeadListData] = useState([]);
    const { client } = useSelector((state) => state?.root);

    const getLeadList = async (id) => {
        try {
            let selectQry = `?page=${page}`
            const response = await leadList(id, selectQry)
            if (response && response.data && response.data.status == true) {
                setLeadListData(response.data?.data)
                setTotalRecords(response.data?.count)
                setTotalPages(Math.ceil(response.data?.count / pagePerSize));
            } else {
                setTotalRecords(0);
                setTotalPages(0);
                setLeadListData(null);
            }
        } catch (error) {
        }
    }

    const TableRowsLoader = ({ rowsNum }) => {
        return [...Array(rowsNum)].map((row, index) => (
            <TableRow key={index}>
                <TableCell component="th" scope="row">
                    <Skeleton animation="wave" variant="text" width={210} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton animation="wave" variant="text" width={210} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton animation="wave" variant="text" width={210} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton animation="wave" variant="text" width={210} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton animation="wave" variant="text" width={210} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton animation="wave" variant="text" width={210} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton animation="wave" variant="text" width={210} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton animation="wave" variant="text" width={210} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton animation="wave" variant="text" width={210} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton animation="wave" variant="text" width={210} height={20} />
                </TableCell>

            </TableRow>
        ));
    };

    useEffect(() => {
        if (client && client?.clientId) {
            getLeadList(client?.clientId)
        }
    }, [page, client]);

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    return (
        <>
            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                                Channel
                            </StyledTableCell>
                            <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                                Name
                            </StyledTableCell>
                            <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                                Email
                            </StyledTableCell>
                            <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                                Phone
                            </StyledTableCell>
                            <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                                Message
                            </StyledTableCell>
                            <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                                Submission Date
                            </StyledTableCell>
                            <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                                Submission Time
                            </StyledTableCell>
                            <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                                URL
                            </StyledTableCell>
                            <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                                IP Address
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {leadListData?.map((row, index) => {
                            var {
                                id,
                                channel,
                                name,
                                email,
                                url,
                                ip,
                                date,
                                phone,
                                message,
                                time
                            } = row;
                            email = email ? email : "-";
                            return (
                                <StyledTableRow
                                    key={index}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                    style={{ cursor: "pointer" }}
                                >
                                    <TableCell component="th" scope="row">
                                        {channel}
                                    </TableCell>
                                    <TableCell>{name}</TableCell>
                                    <TableCell>{email}</TableCell>
                                    <TableCell>{phone}</TableCell>
                                    <TableCell>{message}</TableCell>
                                    <TableCell>{date}</TableCell>
                                    <TableCell>{time}</TableCell>
                                    <TableCell><Tooltip title={url}>{url.slice(0, 27)}</Tooltip></TableCell>
                                    <TableCell>{ip}</TableCell>
                                </StyledTableRow>
                            );
                        })}
                        {leadListData === null ? (
                            <TableRow>
                                <TableCell
                                    sx={{
                                        color: "silver",
                                        textAlign: "center",
                                        paddingTop: "90px",
                                        borderBottom: "none",
                                        fontSize: "20px",
                                    }}
                                    colSpan="9"
                                >
                                    No records to display
                                </TableCell>
                            </TableRow>

                        ) : (
                            leadListData.length === 0 ? (<TableRowsLoader rowsNum={10} />) : ("")
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Stack
                sx={{ mt: 4, pb: 2 }}
                flexDirection="row"
                justifyContent="space-between"
            >
                <div>
                    Showing{" "}
                    {leadListData?.length}{" "}
                    of {totalRecords} Results
                </div>
                <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handleChangePage}
                    variant="outlined"
                    shape="rounded"
                />
            </Stack>
        </>
    )
}

export default LeadsList