import { get, post, patch, getpagination, getAllResponse } from "../web.request";

export const facebookAdsData = async (data) => {
    return await get(`/facebook_app/filter_facebook_ads/${data}`);
}
export const tiktokAdsData = async (data) => {
    return await get(`/tiktok_app/filter_tiktok_ads/${data}`);
}

export const linkedInAdsData = async (data) => {
    return await get(`/linkedin_app/filter_linkedin_ads/${data}`);
}

export const instaAdsData = async (data) => {
    return await get(`/instagram_app/filter_instagram_ads/${data}`);
}

export const googleGraphSEM = async (data) => {
    return await get(`/google_app/filter_google_sem_ads/${data}`);
}

export const googleGraphGDN = async (data) => {
    return await get(`/google_app/filter_google_gdn_ads/${data}`);
}

export const googleGraphYouTube = async (data) => {
    return await get(`/google_app/filter_google_youtube_ads/${data}`);
}

export const facebookAdsDataList = async (data) => {
    return await getAllResponse(`/facebook_app/filter_facebook_ads_withpage/${data}`);
}
export const tiktokAdsDataList = async (data) => {
    return await getAllResponse(`/tiktok_app/filter_tiktok_ads_withpage/${data}`);
}

export const likedInTableListData = async (data) => {
    return await getAllResponse(`/linkedin_app/filter_linkedin_ads_withpage/${data}`);
}

export const instagramTableListData = async (data) => {
    return await getAllResponse(`/instagram_app/filter_instagram_ads_withpage/${data}`);
}

export const googleTableListDataSEM = async (data) => {
    return await getAllResponse(`/google_app/filter_google_sem_ads_withpage/${data}`);
}

export const googleTableListDataGDN = async (data) => {
    return await getAllResponse(`/google_app/filter_google_gdn_ads_withpage/${data}`);
}

export const googleTableListDataYouTube = async (data) => {
    return await getAllResponse(`/google_app/filter_google_youtube_ads_withpage/${data}`);
}

export const getGroupOfList = async (query, id) => {
    return await getAllResponse(`/google_app/sem_ads_group_withpage/${id}${query}`)
}

export const getAdsOfListSEM = async (query, id) => {
    return await getAllResponse(`/google_app/sem_ads_withpage/${id}${query}`)
}

export const getGroupOfGDNList = async (query, id) => {
    return await getAllResponse(`/google_app/gdn_ads_group_withpage/${id}${query}`)
}

export const getGroupOfYouTubeList = async (query, id) => {
    return await getAllResponse(`/google_app/youtube_ads_group_withpage/${id}${query}`)
}

export const getAdsOfGGDNList = async (query, id) => {
    return await getAllResponse(`/google_app/gdn_ads_withpage/${id}${query}`)
}

export const getAdsOfListGSM = async (query, id) => {
    return await getAllResponse(`/google_app/gdn_ads_withpage/${id}${query}`)
}

export const getAdsOfListYouTube = async (query, id) => {
    return await getAllResponse(`/google_app/youtube_ads_withpage/${id}${query}`)
}