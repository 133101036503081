import React, { useState, useEffect } from 'react'
import PageHeading from '../../../components/common/PageHeading'
import { userreportcolumns, userreportrows } from '../../../helper/indexUser';
import { DataGrid } from '@mui/x-data-grid';
import { useStyles } from '../../../helper';
import TableSearch from '../../../components/common/Table/TableSearch';
import TableSort from '../../../components/common/Table/TableSort';
import {
  Grid, Stack, TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Pagination,
  DialogContent,
  Typography,
  DialogActions,
  Dialog, DialogTitle,
  TextField
} from '@mui/material';
import { styled } from "@mui/material/styles";
import { Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { MoreVertIcon, EditIcon, DeleteIcon, LinkIcon, SendIcon } from '../../../assets/icons/Icons';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Skeleton from '@mui/material/Skeleton';
import Notification from '../../../components/common/Notification/Notification';
import { useSelector } from 'react-redux';
import { previousReportList, sendReportToClient, deleteReport } from '../../../Service/reports.service';
import CommonButton from '../../../components/common/Button';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#1987540a",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));

const Report = () => {
  const classes = useStyles();

  const [previousReportData, setPreviousReportData] = useState([])
  const [page, setPage] = useState(1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [show, setShow] = useState({
    show: false,
    type: '',
    message: ''
  });
  const [sendReportId, setSendReportId] = useState(null);
  const [DeleteReportId, setDeleteReportId] = useState(null);
  const [reportUrl, setReportUrl] = useState(null);
  const [showNoData, setShowNoData] = useState(false);
  const [openSendPopup, setOpenSendPopup] = useState(false);
  const [search, setSearch] = useState("");

  const { user } = useSelector((state) => state?.root?.auth);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    getPreviousReports(user?.id, "");
  };

  const getPreviousReports = async (id, searchtext) => {
    let query = `?page=${page}&search=${searchtext || ""}`;
    setShowNoData(false)
    const res = await previousReportList(id, query);
    const { data } = res;
    if (data.status) {
      setTimeout(() => {
        setPreviousReportData(data?.data)
        setTotalRecords(data?.count);
        setTotalPages(Math.ceil(data?.count / pagePerSize));
      }, 1000);

    } else {
      setShowNoData(true)
      setPreviousReportData([])
      setTotalRecords(0);
      setTotalPages(0);
    }
  }

  const handelSendReport = async () => {
    setAnchorEl(null);
    if (sendReportId) {
      const payload = {
        "send": true
      }
      const sendRes = await sendReportToClient(payload, sendReportId);
      if (sendRes.status == true) {
        setOpenSendPopup(false);
        getPreviousReports(user?.id, "");
        setSendReportId(null);
        setShow({
          show: true,
          type: 'success',
          message: sendRes.message || 'Client deleted successfully'
        })
      } else {
        setOpenSendPopup(false);
        setShow({
          show: true,
          type: 'error',
          message: sendRes.message || 'Something went wrong'
        })
      }
    } else {
      setOpenSendPopup(false);
      setShow({
        show: true,
        type: 'error',
        message: 'Report not selected'
      })
    }
  }

  const handleDownloadReport = async () => {

    try {
      const response = await fetch(reportUrl);
      const blob = await response.blob();
      const objectURL = URL.createObjectURL(blob);
      const myArray = reportUrl.split("report_pdf/");
      const link = document.createElement("a");
      link.href = objectURL;
      link.download = myArray[1];
      link.click();

      URL.revokeObjectURL(objectURL);
      setAnchorEl(null);
    } catch (error) {
      setShow({
        show: true,
        type: 'error',
        message: 'No PDF file found'
      })
    }
  }

  const getSearchKeywords = (e) => {
    setSearch(e.target.value);
    if (e.target.value.length > 3) {
      getPreviousReports(user?.id, e.target.value);
    } else if (e.target.value.length == 0) {
      getPreviousReports(user?.id, "");
    }
  }

  const handleCopyToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(reportUrl)
        .then(() => {
          setAnchorEl(null);
          setShow({
            show: true,
            type: 'success',
            message: 'Link is copied to clipboard'
          })
        }
        )
        .catch(error => console.error('Unable to copy to clipboard', error));
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = reportUrl;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      setShow({
        show: true,
        type: 'success',
        message: 'Link is copied to clipboard'
      })
    }
  };

  useEffect(() => {
    if (user && user?.id) {
      getPreviousReports(user?.id, "");
    } else {
      setShow({
        show: true,
        type: 'error',
        message: 'Please Select Client'
      })
    }
  }, [page])

  const TableRowsLoader = ({ rowsNum }) => {
    return [...Array(rowsNum)].map((row, index) => (
      <TableRow key={index}>
        <TableCell component="th" scope="row">
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <>
      <PageHeading heading='Reports' />
      <Stack direction='row' alignItems="center" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: 1 }, marginTop: { xs: 2 }, flexDirection: { xs: 'column-reverse', sm: 'row' }, gap: '8px' }}>
        <TextField value={search} hiddenLabel placeholder='Search' xs={{ width: '100% !important' }} onChange={getSearchKeywords} />
        <Grid container justifyContent='flex-end' alignItems='center' gap={{ xs: '5px', sm: '10px' }} width="inherit">
          {/* <Grid item>
            <TableSort />
          </Grid> */}
          {/* <Grid item>
            <DateRange />
          </Grid> */}
        </Grid>
      </Stack>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Report Name
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Report Date
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Created At
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Status
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>

              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {previousReportData?.map((row, index) => {
              var {
                id,
                repor_name,
                creat_date,
                is_active,
                start_date,
                end_date,
                send
              } = row;
              // var status = getStatus(is_active);
              var reportDates = `${start_date} to ${end_date}`;
              var statusText = send ? "Sended" : "Generated";
              return (
                <StyledTableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell component="th" scope="row">
                    {repor_name}
                  </TableCell>
                  <TableCell>
                    {reportDates}
                  </TableCell>
                  <TableCell>
                    {creat_date}
                  </TableCell>
                  <TableCell>
                    {statusText}
                  </TableCell>
                  <TableCell onClick={(e) => { e.stopPropagation() }}>
                    <Grid container spacing={2} alignItems={'center'} flexWrap={'nowrap'}>

                      <Grid item>
                        {/* <ClientMoreBtn /> */}
                        <FormControl fullWidth>
                          <MoreVertIcon onClick={(e) => { handleClick(e); setSendReportId(row.id); setReportUrl(row.pdf_file); setDeleteReportId(row.id) }} sx={{ cursor: 'pointer' }} />
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                          >
                            <MenuItem onClick={() => handleCopyToClipboard()} >
                              <Grid container spacing={1} >
                                <Grid item><LinkIcon sx={{ width: '20px', height: '20px' }} /></Grid>
                                <Grid item>Link</Grid>
                              </Grid>
                            </MenuItem>
                            {/* <MenuItem onClick={() => { handleOpenSendModal() }}>
                              <Grid container spacing={1} >
                                <Grid item><SendIcon sx={{ width: '20px', height: '20px' }} /></Grid>
                                <Grid item>Send</Grid>
                              </Grid>
                            </MenuItem> */}
                            <MenuItem onClick={handleDownloadReport} >
                              <Grid container spacing={1} >
                                <Grid item><ArrowDownwardIcon sx={{ width: '20px', height: '20px' }} /></Grid>
                                <Grid item>Download</Grid>
                              </Grid>
                            </MenuItem>
                            {/* <MenuItem onClick={() => setOpenDeletePopup(true)}>
                              <Grid container spacing={1} >
                                <Grid item><DeleteIcon sx={{ width: '20px', height: '20px' }} /></Grid>
                                <Grid item>Delete</Grid>
                              </Grid>
                            </MenuItem> */}
                          </Menu>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </TableCell>
                </StyledTableRow>
              );
            })}

            {showNoData ? (

              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="6"
                >
                  No records to display
                </TableCell>
              </TableRow>
            ) : (
              previousReportData.length === 0 ? (
                <TableRowsLoader rowsNum={10} />

              ) : ("")
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>

      <Notification show={show} setShow={setShow} />
    </>
  )
}

export default Report