import React from 'react'
import { DialogActions, DialogContent, Grid, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import CommonButton from '../../../components/common/Button';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useFormik } from "formik";
import { changePasswordInitialValues } from '../../../helper/initialValues';
import { changePasswordValidationSchema } from '../../../helper/validation';
import CommonInput from '../../../components/common/Input';
import { changePassword } from '../../../Service/changePass.service';

const ChangePassword = (props) => {
    const { handleClose, changePassId, setShow, setChangePassId } = props;
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword);

    const formik = useFormik({
        initialValues: changePasswordInitialValues,
        onSubmit: async (value) => {
            const payload = {
                new_password: value.new_password,
            };
            if (value.new_password == value.confirmpass) {
                const res = await changePassword(payload, changePassId);
                if (res.status) {
                    handleClose();
                    setChangePassId(null)
                    setShow({
                        show: true,
                        type: 'success',
                        message: res.message || 'Change Password Successfully'
                    })
                } else {
                    setShow({
                        show: true,
                        type: 'error',
                        message: res.message || 'Something went wrong'
                    })
                }
            } else {
                setShow({
                    show: true,
                    type: 'error',
                    message: 'New password and confirm password must be same'
                })
            }

        },
        validationSchema: changePasswordValidationSchema,
    });
    return (
        <>
            <DialogContent dividers >
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" width={250}>
                            <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                                Enter Password
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography className='myprofile_input' sx={{ width: '100%' }}>
                                <div className='p_relative'>
                                    <CommonInput fullWidth placeholder="enter Password" type={showPassword ? 'text' : 'password'} name="new_password" formik={formik} maxLength={30} />
                                    <InputAdornment position="end" className="eye_btn">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                </div>
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item sm={12}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" width={250}>
                            <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                                Re-enter Password
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography className='myprofile_input' sx={{ width: '100%' }}>
                                <div className='p_relative'>
                                    <CommonInput fullWidth placeholder="re-enter Password" type={showConfirmPassword ? 'text' : 'password'} name="confirmpass" formik={formik} maxLength={30} />
                                    <InputAdornment position="end" className="eye_btn">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowConfirmPassword}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                </div>
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <CommonButton onClick={handleClose} buttonName="Cancel" />
                <CommonButton onClick={formik.handleSubmit} buttonName='Save' />
            </DialogActions>
        </>
    )
}

export default ChangePassword