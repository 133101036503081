import { CLIENT_ADD } from "../action-types/clientActionType";


const initialState = {
    clientId: '',
}

export const clientReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLIENT_ADD:
            return {
                ...state,
                clientId: action.payload
            };
        default:
            return state;
    }
}
