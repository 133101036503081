import * as Yup from 'yup';

export const loginValidationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string()
        .required('Password is required'),
    // .matches(
    //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    //     'Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.'
    // ),
});

export const profileValidationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
});

export const addClientValidationSchema = Yup.object().shape({
    fName: Yup.string().required('First Name is required'),
    lName: Yup.string().required('Last Name is required'),
    company_name: Yup.string().required('Company Name is required'),
    contract_join_date: Yup.string().required('Start Date is required'),
    contract_end_date: Yup.string().required('End Date is required'),
    // email: Yup.string().email('Invalid email address').required('Email is required'),
    email: Yup.string().trim().required("Email is required").email("Invalid email").matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email"),
    password: Yup.string()
        .required('Password is required')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            'Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.'
        ),
});

export const addAdminValidationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string().trim().required("Email is required").email("Invalid email").matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email"),
    password: Yup.string()
        .required('Password is required')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            'Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.'
        ),
});

export const updateClientValidationSchema = Yup.object().shape({
    fName: Yup.string().required('First Name is required'),
    lName: Yup.string().required('Last Name is required')
});

export const updateAdminValidationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required')
});

export const changePasswordValidationSchema = Yup.object().shape({
    new_password: Yup.string()
        .required('Password is required')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            'Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.'
        ),
    confirmpass: Yup.string()
        .required('Password is required')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            'Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.'
        ),
});