import { createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import MultipleSelect from '../pages/admin/reporting/MultipleSelect';
import MoreBtn from '../pages/admin/accessmanagement/MoreBtn';
import ClientMoreBtn from '../pages/admin/clientmanagement/ClientMoreBtn';
import { EditIcon, MoreVertIcon } from '../assets/icons/Icons';
import QualitySelect from '../pages/user/leads/QualitySelect';
import ReportMoreBtn from '../pages/admin/reporting/ReportMoreBtn';
import AddRole from '../pages/admin/accessmanagement/AddRole';
import ChangePassword from '../pages/admin/accessmanagement/Changepassword';

export const useStyles = makeStyles(({ theme }) => ({
    tableContainer: {
        maxHeight: "auto",
        boxShadow: '2px 3px 10px rgba(0, 0, 0, 0.15) !important',
        borderRadius: '15px !important',
        border: '2px solid #FFF !important',
        background: 'var(--cardBG)'
    },
    cardContainer: {
        boxShadow: '2px 3px 10px rgba(0, 0, 0, 0.15) !important',
        borderRadius: '15px !important',
        border: '2px solid #FFF !important',
        // padding:'30px',
        minWidth: '100%',
        height: '100%',
        // marginBottom: '20px'
        background: 'var(--cardBG)',
        position: 'relative',
    },
    chartContainer: {
        boxShadow: '2px 3px 10px rgba(0, 0, 0, 0.15) !important',
        borderRadius: '15px !important',
        border: '2px solid #FFF !important',
        minWidth: '100%',
        height: '100%',
        position: 'relative',
        background: 'var(--cardBG)'
    },
    smallHead: {
        color: '#797D8C',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '15px',
        paddingBottom: '8px'
    },
    goalHead: {
        color: '#000',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '14px'
    },
    counterNumber: {
        color: '#000',
        fontSize: '28px',
        lineHeight: '28px',
        fontWeight: '700',
    },
    counterImg: {
        display: 'flex',
        justifyContent: 'flex-end',
        minWidth: '15px',
        height: '15px'
    },
    percentage: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        color: ' #6AD2A0',
        fontSize: '12px',
        fontWeight: '400',
    },
    chartHead: {
        fontSize: '20px',
        fontWeight: '600',
    },
    settingBtn: {
        fontSize: '20px',
        fontWeight: '600',
    },
    settingIcon: {
        minWidth: '30px !important',
        height: '30px !important',
        color: 'var(--primary)',
        padding: '3px',
        background: '#dfebeb',
        borderRadius: '50%',
        // marginTop: '5px !important'
    },
    select: {
        right: '30px !important',
    }
}));
export const theme = createTheme({
    palette: {
        primary: {
            main: '#0B7770',
        },
    },
    components: {
        MuiLoadingButton: {
            styleOverrides: {
                root: {
                    textTransform: 'capitalize',
                    padding: '6px 20px'
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    color: 'var(--secondary)',
                    zIndex: '-1'
                },
                outlined: {
                    padding: '0 !important',
                    border: '1px solid var(--white)',
                },
                root: {
                    borderRadius: '5px',
                    // background: 'var(--white)',
                    // color: 'var(--secondary)',
                    padding: '6px 12px',
                    boxShadow: '2px 3px 10px 0px rgba(0, 0, 0, 0.15)',
                    width: '120px',
                },
                nativeInput: {
                    minWidth: '120px !important'
                }
            },
        },
        // MuiChartsLegend: {
        //     styleOverrides: {
        //         label: {
        //             fontSize: '12px'
        //         }
        //     }
        // },
        MuiDataGrid: {
            styleOverrides: {
                main: {
                    minWidth: '100%',
                },
                columnHeaders: {
                    minWidth: '100% !important',
                },
                columnHeadersInner: {
                    minWidth: '100% !important'
                },
                columnHeaderRow: {
                    minWidth: '100% !important',
                    justifyContent: 'space-between'
                },
                columnHeaderTitle: {
                    fontSize: '16px !important',
                    '@media (max-minWidth: 800px)': {
                        fontSize: '14px !important',
                    },
                    fontWeight: 'bold',
                    minWidth: '100% !important',
                    textAlign: 'center',
                },
                columnSeparator: {
                    display: 'none',
                },
                columnHeaderTitleContainer: {
                    justifyContent: 'center',
                },
                iconButtonContainer: {
                    display: 'none',
                },
                // virtualScrollerContent: {
                //     minWidth: '100% !important'
                // },
                // cell: {
                //     '&:last-child': {
                //         display: 'none',
                //     },
                // },
                // virtualScrollerRenderZone: {
                //     minWidth: 'inherit'
                // },
                columns: {
                    textAlign: 'center !important',
                    // minWidth: '100%',
                    border: '1px solid transparent'
                },
                row: {
                    whiteSpace: 'nowrap',
                    // minWidth: '100%',
                    justifyContent: 'space-between',
                    fontSize: '16px !important',
                    '@media (max-minWidth: 800px)': {
                        fontSize: '14px !important',
                    },
                    fontWeight: '400 !important',
                    color: 'var(--para) !important',
                }
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    input: {
                        borderRadius: '6px',
                        border: '1px solid var(--white)',
                        background: 'var(--cardBG)',
                        boxShadow: '2px 3px 10px rgba(0, 0, 0, 0.15)',
                        padding: '9px 15px',
                        fontSize: '13px',
                        minWidth: '300px',
                        '@media (max-minWidth: 600px)': {
                            minWidth: '100%'
                        },
                    },
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    minWidth: '300px',
                    '@media (max-minWidth: 600px)': {
                        minWidth: '100%'
                    },
                }
            }
        },
        // MuiChartsLegend: {
        //     styleOverrides: {
        //         root: {
        //             display: 'none',
        //         }
        //     }
        // },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    minWidth: '250px'
                }
            }
        },
        MuiBox: {
            styleOverrides: {
                root: {
                    padding: '0 !important'
                }
            }
        }
    },
});

export const leadsrows = [
    {
        id: 1,
        channel: 'Facebook',
        name: 'Maria Jems.',
        email: 'Mariya657@ex.com',
        phone: '12535 25412',
        message: '*****',
        subDate: '17/04/22',
        subTime: '9:45 AM',
        url: 'https://lead1',
        ip: '192.0.1',
        quality: <QualitySelect />,
    },
];
export const leadscolumns = [
    { field: 'channel', headerName: 'Channel', minWidth: 120, flex: 1 },
    { field: 'name', headerName: 'Name', minWidth: 150, flex: 1 },
    { field: 'email', headerName: 'Email', minWidth: 170, flex: 1 },
    { field: 'phone', headerName: 'Phone', minWidth: 140, flex: 1 },
    { field: 'message', headerName: 'Message', minWidth: 170, flex: 1 },
    { field: 'subDate', headerName: 'Submission Date', minWidth: 170, flex: 1 },
    { field: 'subTime', headerName: 'Submission Time', minWidth: 170, flex: 1 },
    { field: 'url', headerName: 'URL', minWidth: 150, flex: 1 },
    { field: 'ip', headerName: 'IP Address', minWidth: 150, flex: 1 },
    { field: 'quality', headerName: 'Quality of Leads', renderCell: () => <QualitySelect />, minWidth: 150, flex: 1 },
];

export const clientrows = [
    {
        id: 1,
        clientname: 'Maria Jems.',
        email: 'Mariya657@ex.com',
        status: 'Lost Client',
        chgpass: <ChangePassword onClick={(e) => e.stopPropagation()} />,
        action: <ClientMoreBtn onClick={(e) => e.stopPropagation()} />,
    },
];
export const clientcolumns = [
    { field: 'first_name', headerName: 'Client Name', minWidth: 170, flex: 1 },
    { field: 'email', headerName: 'Email/Username', minWidth: 170, flex: 1 },
    { field: 'created_at', headerName: 'Join Date', minWidth: 170, flex: 1 },
    { field: 'is_active', headerName: 'Status', minWidth: 150, flex: 1 },
    { field: 'chgpass', headerName: '', minWidth: 30, renderCell: () => <ChangePassword onClick={(e) => e.stopPropagation()} />, flex: 0.2 },
    { field: 'action', headerName: '', minWidth: 40, disableClickEventBubbling: true, renderCell: () => <ClientMoreBtn onClick={(e) => e.stopPropagation()} />, flex: 1 },

];

export const plannerrows = [
    {
        id: 1,
        keyword: 'Custom',
        avgmonthly: '21,256',
        competition: 'High',
        impression: '-',
        bidlow: '$0.25',
        bidhigh: '79%',
        status: '-',
    },
];
export const plannercolumns = [
    { field: 'keyword', headerName: 'Keyword', minWidth: 150, flex: 1 },
    { field: 'avgmonthly', headerName: 'Avg. Monthly Search', minWidth: 200, flex: 1 },
    { field: 'competition', headerName: 'Competition', minWidth: 170, flex: 1 },
    { field: 'impression', headerName: 'Ad Impression share', minWidth: 200, flex: 1 },
    { field: 'bidlow', headerName: 'Top of page bid(low)', minWidth: 220, flex: 1 },
    { field: 'bidhigh', headerName: 'Top of page bid(high)', minWidth: 220, flex: 1 },
    { field: 'status', headerName: 'Acc. Status', minWidth: 170, flex: 1 },
];

export const reportingrows = [
    {
        id: 1,
        channel: 'Facebook',
        sessions: '152',
        sessionsduration: '00:00:14',
        newsessions: '10%',
        cpc: '5%',
        rate: '10%',
        conversion: '15%',
        cost: '$12',
    },
];
export const reportingcolumns = [
    { field: 'channel', headerName: 'Channel', minWidth: 120, flex: 1 },
    { field: 'sessions', headerName: 'Sessions', minWidth: 120, flex: 1 },
    { field: 'sessionsduration', headerName: 'Session Duration', minWidth: 170, flex: 1 },
    { field: 'newsessions', headerName: 'New Session', minWidth: 170, flex: 1 },
    { field: 'cpc', headerName: 'CPC', minWidth: 100, flex: 1 },
    { field: 'rate', headerName: 'Rate', minWidth: 100, flex: 1 },
    { field: 'conversion', headerName: 'Conversion', minWidth: 140, flex: 1 },
    { field: 'cost', headerName: 'Cost per Ad', minWidth: 170, flex: 1 },
];

export const previousreportrows = [
    {
        id: 1,
        name: 'xyz',
        date: '10/10/2023',
        client: 'abc',
        month: '1',
        status: 'Generated ',
        action: <ReportMoreBtn />,
    },
];
export const previousreportcolumns = [
    { field: 'name', headerName: 'Report Name', minWidth: 150, flex: 1 },
    { field: 'date', headerName: 'Date', minWidth: 120, flex: 1 },
    { field: 'client', headerName: 'Client', minWidth: 120, flex: 1 },
    { field: 'month', headerName: 'Month', minWidth: 150, flex: 1 },
    { field: 'status', headerName: 'Status', minWidth: 150, flex: 1 },
    { field: 'action', headerName: '', minWidth: 30, renderCell: () => <ReportMoreBtn />, flex: 0.2 },
];

export const rolerows = [
    {
        id: 1,
        username: 'Maria Jems.',
        email: 'Mariya657@ex.com',
        jdate: '17/04/23',
        access: <MultipleSelect />,
        chgpass: <ChangePassword />,
        action: <MoreBtn />,
    },
];
export const rolecolumns = [
    { field: 'username', headerName: 'User Name', minWidth: 170, flex: 1 },
    { field: 'email', headerName: 'Email', minWidth: 170, flex: 1 },
    { field: 'jdate', headerName: 'Join Date', minWidth: 170, flex: 1 },
    { field: 'access', headerName: 'Access Level', renderCell: () => <MultipleSelect />, minWidth: 170, flex: 1 },
    { field: 'chgpass', headerName: '', minWidth: 30, renderCell: () => <ChangePassword />, flex: 0.2 },
    { field: 'action', headerName: '', minWidth: 30, renderCell: () => <MoreBtn />, flex: 0.2 },
];

export const roleListrows = [
    {
        id: 1,
        rolename: 'Admin',
        action: <AddRole />,
    },
];
export const roleListcolumns = [
    { field: 'rolename', headerName: 'Role Name', flex: 1 },
    { field: 'action', headerName: '', flex: 1, renderCell: () => <AddRole /> },
];

export const budgetrows = [
    {
        id: 1,
        channel: 'Facebook',
        budget: '$400',
        spent: '$500',
        cpc: '$350',
        hedging: '$30',
        rcpc: '$50',
        // lost: '-',
        remaining: '$20',
    },
];
export const budgetcolumns = [
    { field: 'channel', headerName: 'Channel', minWidth: 120, flex: 1 },
    { field: 'budget', headerName: 'Reported Budget', minWidth: 150, flex: 1 },
    { field: 'spent', headerName: 'Actual Spent', minWidth: 120, flex: 1 },
    { field: 'cpc', headerName: 'Actual CPC', minWidth: 150, flex: 1 },
    { field: 'hedging', headerName: 'Hedging Percentage', minWidth: 180, flex: 1 },
    { field: 'rcpc', headerName: 'Reported CPC', minWidth: 150, flex: 1 },
    // { field: 'lost', headerName: 'Lost', minWidth: 120, flex: 1 },
    { field: 'remaining', headerName: 'Remaining', minWidth: 140, flex: 1 },
];

export const facebookadsrows = [
    {
        id: 1,
        campaignname: 'abc',
        impressions: '345',
        leads: '9.09',
        reach: '34%',
        click: '7',
        amount: '$12',
    },
];
export const facebookadscolumns = [
    { field: 'ad_name', headerName: 'Campaign Name', minWidth: 150, flex: 1 },
    { field: 'impressions', headerName: 'Impressions', minWidth: 120, flex: 1 },
    { field: 'reach', headerName: 'Reach', minWidth: 120, flex: 1 },
    { field: 'clicks', headerName: 'Link Clicks', minWidth: 150, flex: 1 },
    { field: 'spend', headerName: 'Amount Spent($)', minWidth: 150, flex: 1 },
];

export const instagramadsrows = [
    {
        id: 1,
        campaignname: 'abc',
        impressions: '345',
        leads: '9.09',
        reach: '34%',
        click: '7',
        amount: '$12',
    },
];
export const instagramadscolumns = [
    { field: 'campaignname', headerName: 'Campaign Name', minWidth: 150, flex: 1 },
    { field: 'impressions', headerName: 'Impressions', minWidth: 120, flex: 1 },
    { field: 'reach', headerName: 'Reach', minWidth: 120, flex: 1 },
    { field: 'leads', headerName: 'Leads', minWidth: 170, flex: 1 },
    { field: 'click', headerName: 'Link Clicks', minWidth: 150, flex: 1 },
    { field: 'amount', headerName: 'Amount Spent($)', minWidth: 150, flex: 1 },
];

export const tiktokadsrows = [
    {
        id: 1,
        campaignname: 'abc',
        impressions: '345',
        click: '7',
        ctr: '9.09',
        cpc: '34%',
        conversions: '$12',
        totalcost: '$12',
    },
];
export const tiktokadscolumns = [
    { field: 'campaignname', headerName: 'Campaign Name', minWidth: 150, flex: 1 },
    { field: 'impressions', headerName: 'Impressions', minWidth: 120, flex: 1 },
    { field: 'click', headerName: 'Clicks', minWidth: 100, flex: 1 },
    { field: 'ctr', headerName: 'Click-Through-Rate(CTR)%', minWidth: 220, flex: 1 },
    { field: 'cpc', headerName: 'Cost-Per-Click(CPC)$', minWidth: 220, flex: 1 },
    { field: 'conversions', headerName: 'Conversions', minWidth: 120, flex: 1 },
    { field: 'totalcost', headerName: 'Total Cost($)', minWidth: 150, flex: 1 },
];

export const linkedinadsrows = [
    {
        id: 1,
        campaignname: 'abc',
        impressions: '345',
        click: '7',
        ctr: '9.09',
        conversions: '34%',
        cost: '$12',
    },
];
export const linkedinadscolumns = [
    { field: 'campaignname', headerName: 'Campaign Name', minWidth: 150, flex: 1 },
    { field: 'impressions', headerName: 'Impressions', minWidth: 120, flex: 1 },
    { field: 'click', headerName: 'Clicks', minWidth: 100, flex: 1 },
    { field: 'ctr', headerName: 'Click-Through-Rate(CTR)%', minWidth: 220, flex: 1 },
    { field: 'conversions', headerName: 'Conversions', minWidth: 120, flex: 1 },
    { field: 'cost', headerName: 'Cost($)', minWidth: 150, flex: 1 },
];

export const crmadsrows = [
    {
        id: 1,
        campaignname: 'abc',
        impressions: '345',
        click: '7',
        ctr: '9.09',
        cpc: '34%',
        conversions: '$12',
        cost: '$12',
    },
];
export const crmadscolumns = [
    { field: 'campaignname', headerName: 'Campaign Name', minWidth: 150, flex: 1 },
    { field: 'impressions', headerName: 'Impressions', minWidth: 120, flex: 1 },
    { field: 'click', headerName: 'Clicks', minWidth: 100, flex: 1 },
    { field: 'ctr', headerName: 'Click-Through-Rate(CTR)%', minWidth: 220, flex: 1 },
    { field: 'cpc', headerName: 'Cost-Per-Click(CPC)$', minWidth: 220, flex: 1 },
    { field: 'conversions', headerName: 'Conversions', minWidth: 120, flex: 1 },
    { field: 'cost', headerName: 'Cost($)', minWidth: 150, flex: 1 },
];

export const gdnadsrows = [
    {
        id: 1,
        campaignname: 'abc',
        impressions: '345',
        click: '7',
        ctr: '9.09',
        cpc: '34%',
        conversions: '$12',
        cost: '$12',
    },
];
export const gdnadscolumns = [
    { field: 'campaignname', headerName: 'Campaign Name', minWidth: 150, flex: 1 },
    { field: 'impressions', headerName: 'Impressions', minWidth: 120, flex: 1 },
    { field: 'click', headerName: 'Clicks', minWidth: 100, flex: 1 },
    { field: 'ctr', headerName: 'Click-Through-Rate(CTR)%', minWidth: 220, flex: 1 },
    { field: 'cpc', headerName: 'Cost-Per-Click(CPC)$', minWidth: 220, flex: 1 },
    { field: 'conversions', headerName: 'Conversions', minWidth: 120, flex: 1 },
    { field: 'cost', headerName: 'Cost($)', minWidth: 150, flex: 1 },
];

export const youtubeadsrows = [
    {
        id: 1,
        campaignname: 'abc',
        views: '345',
        cpv: '345',
        rate: '345',
        impressions: '345',
        click: '7',
        ctr: '9.09',
        cpc: '34%',
        conversions: '$12',
        cost: '$12',
    },
];
export const youtubeadscolumns = [
    { field: 'campaignname', headerName: 'Campaign Name', minWidth: 150, flex: 1 },
    { field: 'views', headerName: 'Views', minWidth: 100, flex: 1 },
    { field: 'cpv', headerName: 'Cost-Per-View(CPV)$', minWidth: 220, flex: 1 },
    { field: 'rate', headerName: 'View Rate(%)', minWidth: 150, flex: 1 },
    { field: 'impressions', headerName: 'Impressions', minWidth: 120, flex: 1 },
    { field: 'click', headerName: 'Clicks', minWidth: 100, flex: 1 },
    { field: 'ctr', headerName: 'Click-Through-Rate(CTR)%', minWidth: 220, flex: 1 },
    { field: 'cpc', headerName: 'Cost-Per-Click(CPC)$', minWidth: 220, flex: 1 },
    { field: 'conversions', headerName: 'Conversions', minWidth: 150, flex: 1 },
    { field: 'cost', headerName: 'Cost($)', minWidth: 100, flex: 1 },
];

