import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import PageHeading from '../../../components/common/PageHeading';
import { theme, useStyles } from '../../../helper';
import {
	Grid, Stack, TableContainer,
	Paper,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Chip,
	Pagination,
	DialogContent,
	Typography,
	DialogActions
} from '@mui/material';
import { styled } from "@mui/material/styles";
import AddClient from './AddClient';
import { useNavigate } from 'react-router-dom';
import { clientList, syncClient, updateClient, deleteClient } from '../../../Service/client.service';
import moment from "moment";
import ChangePassword from '../accessmanagement/Changepassword';
import { Dialog, DialogTitle } from '@mui/material';
import { DeleteIcon, LockIcon } from '../../../assets/icons/Icons';
import Notification from '../../../components/common/Notification/Notification';
import { MoreVertIcon, EditIcon, AddIcon } from '../../../assets/icons/Icons';
import { Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import CommonButton from '../../../components/common/Button';
import { TextField } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';

const getStatus = (value) => {
	const status = {
		false: {
			title: "Pending",
			styles: {
				ml: 2,
				backgroundColor: "rgb(235 193 14 / 24%)",
				color: "orange",
			},
		},
		true: {
			title: "Active",
			styles: {
				ml: 2,
				backgroundColor: "rgba(18, 183, 106, 0.05)",
				color: "#12B76A",
			},
		},

	};
	return status[value];
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: "#1987540a",
	},
	// hide last border
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
	color: "#0F0F0F80",
}));

const StyledChip = styled(Chip)(() => ({
	borderRadius: "10px",
	fontSize: "14px",
}));


const Clientmanagement = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const [clientListData, setClientListData] = useState([]);
	const [page, setPage] = useState(1);
	const [pagePerSize, setPagePerSize] = useState(10);
	const [totalRecords, setTotalRecords] = useState(0);
	const [totalPages, setTotalPages] = useState(0);
	const [changePassId, setChangePassId] = useState(null);
	const [openAdd, setOpenAdd] = React.useState(false);
	const [addEditClientPopup, setAddEditClientPopup] = React.useState(false);
	const [editClientData, setEditClientData] = React.useState({});
	const [editId, setEditId] = React.useState({})
	const [deleteAdminId, setDeleteAdminId] = React.useState(null)
	const [search, setSearch] = useState("");
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState({
		show: false,
		type: '',
		message: ''
	});

	const { client } = useSelector((state) => state?.root);

	const [anchorEl, setAnchorEl] = useState(null);
	const [deleteAdminPopup, setDeleteAdminPopup] = React.useState(false)


	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleOpen = () => {
		setOpenAdd(true);
	};
	const handleClose = () => {
		setOpenAdd(false);
	};
	const handleOpenAddClient = () => {
		setAddEditClientPopup(true);
	};
	const handleCloseAddClient = () => {
		setAddEditClientPopup(false);
	};

	const handleChangePage = (event, value) => {
		setPage(value);
		getClientList(value, "");
	};

	const handlePasswordChange = async (data) => {
		if (data?.id) {
			handleOpen();
			setChangePassId(data.id)
		}
	}
	const handleCloseDeletePopup = () => {
		setDeleteAdminId(null)
		setDeleteAdminPopup(false)
	}

	const confirmDeleteAdmin = async () => {
		if (deleteAdminId) {
			const deleteRes = await deleteClient(deleteAdminId);
			if (deleteRes.status == true) {
				setDeleteAdminId(null)
				setDeleteAdminPopup(false);
				handleChangePage(null, page)
				setShow({
					show: true,
					type: 'success',
					message: deleteRes.message || 'Client deleted successfully'
				})
			} else {
				setDeleteAdminId(null)
				setShow({
					show: true,
					type: 'error',
					message: deleteRes.message || 'Something went wrong'
				})
			}
		}
	}

	const handleDeleteAdmin = (id) => {
		if (id) {
			setDeleteAdminId(id)
			setDeleteAdminPopup(true)
		}
	}


	const handleEditClient = async (data) => {
		setEditId(data.id)
		setEditClientData(data);
		handleOpenAddClient();
		handleMenuClose();
		// if (editId) {
		// 	setEditClientData(data);
		// 	handleOpenAddClient();
		// 	handleMenuClose();
		// } else {
		// 	setShow({
		// 		show: true,
		// 		type: 'error',
		// 		message: 'Something goes wrong'
		// 	})
		// }
	}

	const getClientList = async (page, search) => {
		let query = `?page=${page}&limit=${pagePerSize}&search=${search}`;

		// setTimeout(async () => {
		const res = await clientList(query);
		const { data } = res;
		if (data.status) {
			setTimeout(() => {
				setClientListData(data?.data)
				setTotalRecords(data?.count);
				setTotalPages(Math.ceil(data?.count / pagePerSize));
			}, 1000);

		} else {
			setClientListData(null)
			setTotalRecords(0);
			setTotalPages(0);
		}
		// }, 500);
	}

	const getSearchKeywords = (e) => {
		setSearch(e.target.value);
		if (e.target.value.length > 3) {
			getClientList(1, e.target.value);
		} else if (e.target.value.length == 0) {
			getClientList(1, "");
		}
	}

	const handleSyncSubmit = async (e) => {
		setLoading(true)

		const payload = {
			client_id: client?.clientId,
			password: "Dms@$2023!"
		}
		const res = await syncClient(payload);
		if (res) {
			setLoading(false);
			getClientList(1, "");
		}

	}


	const TableRowsLoader = ({ rowsNum }) => {
		return [...Array(rowsNum)].map((row, index) => (
			<TableRow key={index}>
				<TableCell component="th" scope="row">
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell>
			</TableRow>
		));
	};

	useEffect(() => {
		getClientList(1, "");
	}, [])

	return (
		<>
			<PageHeading heading='Clients Management' para='All Clients Details' />
			<ThemeProvider theme={theme}>
				<Stack direction="row" alignItems="center" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: 1 }, flexDirection: { xs: 'column-reverse', sm: 'row' }, gap: '8px' }}>
					{/* <TableSearch onChange={(e) => setSearch(e.target.value)} /> */}
					<TextField value={search} hiddenLabel placeholder='Search' xs={{ width: '100% !important' }} onChange={getSearchKeywords} />
					<Grid container justifyContent='flex-end' alignItems='center' gap={{ xs: '5px', sm: '10px' }} width="inherit">
						<Grid item>
							{/* <CommonButton buttonName='Sync Account' fontSize='1rem' minWidth='40px' padding='5px 10px' onClick={handleSyncSubmit} /> */}
							{
								(client && client?.clientId) &&
								<LoadingButton
									variant="contained"
									size="large"
									onClick={handleSyncSubmit}
									loading={loading}
								>
									<span>Sync Account</span>
								</LoadingButton>
							}
						</Grid>
						<Grid item>
							<CommonButton buttonName={<AddIcon />} fontSize='1rem' minWidth='40px' padding='7px' onClick={handleOpenAddClient} />
						</Grid>
					</Grid>
				</Stack>

				<TableContainer component={Paper} className={classes.tableContainer}>
					<Table stickyHeader aria-label="simple table">
						<TableHead>
							<TableRow>
								<StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
									Client Name
								</StyledTableCell>
								<StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
									Email/Username
								</StyledTableCell>
								<StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
									Join Date
								</StyledTableCell>
								<StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
									Status
								</StyledTableCell>
								<StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>

								</StyledTableCell>
							</TableRow>
						</TableHead>
						{/* <Skeleton variant="rectangular" width={210} height={60} /> */}
						<TableBody>
							{/* {clientListData.length === 0 ? (<TableRowsLoader rowsNum={10} />) : (clientListData?.map((row, index) => { */}
							{clientListData?.map((row, index) => {
								var {
									id,
									first_name,
									last_name,
									email,
									is_active,
								} = row;
								var name = first_name === " " ? "-" : first_name + " " + last_name;
								email = email ? email : "-";
								var status = getStatus(is_active);
								return (
									<StyledTableRow
										key={index}
										sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
										onClick={(e) => navigate(`/admin/client/details`, { state: { id: id } })}
										style={{ cursor: "pointer" }}
									>
										<TableCell component="th" scope="row">
											{name}
										</TableCell>
										<TableCell>{email}</TableCell>
										<TableCell>{moment(row.created_at).format("LLL")}</TableCell>
										{/* <TableCell>{row.created_at}</TableCell> */}
										<TableCell>
											<StyledChip label={status?.title} sx={status?.styles} />
										</TableCell>
										<TableCell onClick={(e) => { e.stopPropagation(); setEditId(row) }}>
											<Grid container spacing={2} alignItems={'center'} flexWrap={'nowrap'}>
												<Grid item>
													<LockIcon sx={{ cursor: 'pointer' }} onClick={() => handlePasswordChange(row)} />
												</Grid>
												<Grid item>
													<EditIcon sx={{ cursor: 'pointer' }} onClick={() => handleEditClient(row)} />
												</Grid>
												<Grid item>
													<DeleteIcon sx={{ cursor: 'pointer' }} onClick={() => handleDeleteAdmin(row.id)} />
												</Grid>
											</Grid>
										</TableCell>
									</StyledTableRow>
								);
							})}
							{clientListData === null ? (
								<TableRow>
									<TableCell
										sx={{
											color: "silver",
											textAlign: "center",
											paddingTop: "90px",
											borderBottom: "none",
											fontSize: "30px",
										}}
										colSpan="6"
									>
										No records to display
									</TableCell>
								</TableRow>

							) : (
								clientListData.length === 0 ? (<TableRowsLoader rowsNum={10} />) : ("")
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<Stack
					sx={{ mt: 4, pb: 2 }}
					flexDirection="row"
					justifyContent="space-between"
				>
					<div>
						Showing{" "}
						{page * pagePerSize > totalRecords
							? totalRecords
							: page * pagePerSize}{" "}
						of {totalRecords} Results
					</div>
					<Pagination
						count={totalPages}
						page={page}
						onChange={handleChangePage}
						variant="outlined"
						shape="rounded"
					/>
				</Stack>

				{/* Change password model */}

				<Dialog open={openAdd} onClose={handleClose} sx={{ '& .MuiDialog-paper': { width: '500px' } }
				}>
					<DialogTitle>Change Password</DialogTitle>
					<ChangePassword handleClose={handleClose} changePassId={changePassId} setShow={setShow} setChangePassId={setChangePassId} />
				</Dialog>


				{/* Add edit client model */}

				<Dialog open={addEditClientPopup} onClose={handleCloseAddClient} maxWidth='md' >
					<AddClient getClientList={getClientList} editClientData={editClientData} handleCloseAddClient={handleCloseAddClient} setShow={setShow} setEditClientData={setEditClientData} handleMenuClose={handleMenuClose} />
				</Dialog>

				<Notification show={show} setShow={setShow} />
			</ThemeProvider >
			<Dialog open={deleteAdminPopup} onClose={handleCloseDeletePopup} sx={{ '& .MuiDialog-paper': { width: '500px' } }}>
				<DialogTitle>Delete Client</DialogTitle>
				<DialogContent dividers >
					<Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
						Are You Sure you want to delete client ?
					</Typography>
				</DialogContent>
				<DialogActions>
					<CommonButton onClick={handleCloseDeletePopup} buttonName="No" />
					<CommonButton onClick={() => confirmDeleteAdmin()} buttonName="Yes" />
				</DialogActions>
			</Dialog>

		</>
	)
}

export default Clientmanagement