import React from 'react'
import { MenuItem, Select, ThemeProvider, createTheme } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const CommonSelect = (props) => {
  const theme1 = createTheme({
    components: {
      // Name of the component
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: 'var(--white)',
            zIndex: '1'
          },
          outlined: {
            padding: '0 !important',
            border: `1px solid transparant`,
          },
          root: {
            width: `${props.width || '150px'}`,
            borderRadius: '6px',
            background: `${props.background || 'var(--primary)'}`,
            color: `${props.color || 'var(--white)'}`,
            padding: `${props.padding || '7px 12px'}`,
            boxShadow: `${props.boxShadow || '2px 3px 10px 0px rgba(0, 0, 0, 0.15)'}`,
            border: `1.5px solid ${props.borderColor || ' transparant'}`
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme1}>
      <Select
        value={props.value}
        onChange={props.onChange}
        displayEmpty
        Select
      // endAdornment={<KeyboardArrowDownIcon />}
      // fullWidth={props.fullWidth}
      >
        <MenuItem value="" key="0" selected>{props.option}</MenuItem>
        {props?.options?.map((item, index) => {
          return (
            <MenuItem key={item.id || item.group_id} value={item.id || item.group_id}>{item.lable || item.Group_name}</MenuItem>
          )
        })}
      </Select>
    </ThemeProvider>
  )
}

export default CommonSelect