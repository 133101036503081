import React, { useEffect } from 'react'
import { Checkbox, ListItemText, MenuItem, Select, ThemeProvider, createTheme } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FacebookAds from '../../../pages/admin/adschannel/facebook/FacebookAds';
import InstagramAds from '../../../pages/admin/adschannel/instagram/InstagramAds';
import GoogleAds from '../../../pages/admin/adschannel/google/GoogleAds';
import TiktokAds from '../../../pages/admin/adschannel/tiktok/TiktokAds';
import LinkedinAds from '../../../pages/admin/adschannel/linkedin/LinkedinAds';

const names = [
    // 'All',
    'Facebook',
    'Instagram',
    'Google',
    'TikTok',
    'LinkedIn',
];

const TableSort = (props) => {
    const { onData, lebel1, lebel2, xLabels } = props
    const theme4 = createTheme({
        components: {
            // Name of the component
            MuiSelect: {
                styleOverrides: {
                    icon: {
                        color: 'var(--secondary)',
                        zIndex: '1'
                    },
                    outlined: {
                        padding: '0 !important',
                        border: `1px solid ${props.border || 'var(--white)'}`,
                    },
                    root: {
                        borderRadius: '5px',
                        background: `${props.background || 'var(--white)'}`,
                        color: `${props.color || 'var(--secondary)'}`,
                        padding: '3px 7px',
                        boxShadow: '2px 3px 10px 0px rgba(0, 0, 0, 0.15)',
                        width: '120px',
                    },
                },
            },
            MuiList: {
                styleOverrides: {
                    root: {
                        padding: '0'
                    },
                },
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        padding: '4px 8px 4px 0'
                    },
                },
            }
        },
    });

    const [channels, setChannels] = React.useState(['Google']);
    const handleChange = (event) => {
        if (event.target.value === 'Facebook') {
            onData(event.target.value);
            // componentToRender = <FacebookAds label1={lebel1} label2={lebel2} xLabels={xLabels} />;
        } else if (event.target.value === 'Instagram') {
            onData(event.target.value);
            // componentToRender = <InstagramAds label1={lebel1} label2={lebel2} xLabels={xLabels} />;
        } else if (event.target.value === 'Google') {
            onData(event.target.value);
            // componentToRender = <GoogleAds label1={lebel1} label2={lebel2} xLabels={xLabels} />;
        } else if (event.target.value === 'TikTok') {
            onData(event.target.value);
            // componentToRender = <TiktokAds label1={lebel1} label2={lebel2} xLabels={xLabels} />;
        } else if (event.target.value === 'LinkedIn') {
            onData(event.target.value);
            // componentToRender = <LinkedinAds label1={lebel1} label2={lebel2} xLabels={xLabels} />;
        }
        setChannels(event.target.value);

        // onData(componentToRender);
    };
    useEffect(() => {

    }, [channels])
    return (
        <ThemeProvider theme={theme4}>
            <Select
                value={channels}
                onChange={handleChange}
                displayEmpty
            // endAdornment={<KeyboardArrowDownIcon />}
            >
                {names.map((name) => (
                    <MenuItem key={name} value={name}>
                        <ListItemText primary={name} />
                    </MenuItem>
                ))}
            </Select>
        </ThemeProvider>
    )
}

export default TableSort