import { Card, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useStyles } from '../../../helper'
import PageHeading from '../../../components/common/PageHeading';
import CommonButton from '../../../components/common/Button';
import { FaTiktok, FaFacebookF, FaGoogle, FaInstagram, FaLinkedinIn } from '../../../assets/icons/Icons';
import ChangePassword from '../accessmanagement/Changepassword';
import { useLocation } from 'react-router-dom';
import { clientDetails } from '../../../Service/client.service';
import { instagram } from '../../../Service/social.service';
import moment from "moment";
import { ArrowBackIosNewIcon } from '../../../assets/icons/Icons';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogTitle } from '@mui/material';
import Notification from '../../../components/common/Notification/Notification';

const ClientDetails = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const { state } = useLocation();
    const [clientId, setClientId] = React.useState(null);
    const [clientData, setClientData] = React.useState({});
    const [changePassId, setChangePassId] = React.useState(null);
    const [openAdd, setOpenAdd] = React.useState(false);
    const [show, setShow] = React.useState({
        show: false,
        type: '',
        message: ''
    });

    const getClientData = async (id) => {
        const res = await clientDetails(id);
        if (res.status) {
            setClientData(res.data)
        } else {
            setClientData({})
        }
    }

    const handleOpen = () => {
        setOpenAdd(true);
    };
    const handleClose = () => {
        setOpenAdd(false);
    };

    const connectInstaAccount = async () => {
        if (clientData && clientData.id) {
            const payload = {
                user: clientData.id
            }
            const resInsta = await instagram(payload);
            getClientData(clientData.id);
        } else {
            setShow({
                show: true,
                type: 'error',
                message: 'Failed to connect instagram account'
            })
        }
    }

    useEffect(() => {
        if (state && state?.id) {
            setClientId(state?.id);
            getClientData(state?.id);
            setChangePassId(state?.id)
        }
    }, [])

    const linkedInUrl = `https://www.linkedin.com/oauth/v2/authorization?client_id=86auhfczozsces&client_secret=y9UKwjhBe8Vb6ykk&redirect_uri=http://dmportal.mobiginie.com/linkedin&scope=r_ads_reporting,r_organization_social,rw_organization_admin,w_member_social,r_ads,w_organization_social,rw_ads,r_basicprofile,r_organization_admin,r_1st_connections_size&response_type=code&state=`;

    return (
        <>
            <Stack flexDirection={'row'} marginBottom={'10px'} gap={'10px'} alignItems={'center'}>
                <CommonButton buttonName={<ArrowBackIosNewIcon sx={{ width: '20px', height: '20px' }} />} onClick={() => navigate(-1)} fontSize='1rem' minWidth='unset' padding='2px 5px 2px 3px' height={'30px'} />
                <PageHeading heading='Profile' />
            </Stack>
            <Grid container spacing={{ xs: '10px', sm: 3 }}>
                <Grid item lg={6} md={6} xs={12}>
                    <Card className={classes.cardContainer}>
                        <Grid container padding={{ xs: 2, sm: 3 }} spacing={2} height={'100%'}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography fontWeight={'700'} fontSize={'20px'}>Client Details</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack flexDirection={'row'} gap={2}>
                                            <Stack flexDirection={'row'} justifyContent={'space-between'} width={{ xs: '80px', lg: '170px' }}>
                                                <Typography width={{ xs: '80px', lg: '170px' }} fontWeight={'700'}>Name</Typography>
                                                <Typography fontWeight={'700'}>:</Typography>
                                            </Stack>
                                            <Typography>{clientData.first_name} {clientData.last_name}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack flexDirection={'row'} gap={2}>
                                            <Stack flexDirection={'row'} justifyContent={'space-between'} width={{ xs: '80px', lg: '170px' }}>
                                                <Typography width={{ xs: '80px', lg: '170px' }} fontWeight={'700'}>Company</Typography>
                                                <Typography fontWeight={'700'}>:</Typography>
                                            </Stack>
                                            <Typography>{clientData.company_name}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack flexDirection={'row'} gap={2}>
                                            <Stack flexDirection={'row'} justifyContent={'space-between'} width={{ xs: '80px', lg: '170px' }}>
                                                <Typography width={{ xs: '80px', lg: '170px' }} fontWeight={'700'}>Email</Typography>
                                                <Typography fontWeight={'700'}>:</Typography>
                                            </Stack>
                                            <Typography>{clientData.email}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack flexDirection={'row'} gap={2}>
                                            <Stack flexDirection={'row'} justifyContent={'space-between'} width={{ xs: '80px', lg: '170px' }}>
                                                <Typography width={{ xs: '80px', lg: '170px' }} fontWeight={'700'}>Date</Typography>
                                                <Typography fontWeight={'700'}>:</Typography>
                                            </Stack>
                                            <Typography>{moment(clientData.created_at).format("LL")}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack flexDirection={'row'} gap={2}>
                                            <Stack flexDirection={'row'} justifyContent={'space-between'} width={{ xs: '80px', lg: '170px' }}>
                                                <Typography width={{ xs: '80px', lg: '170px' }} fontWeight={'700'}>Contract date</Typography>
                                                <Typography fontWeight={'700'}>:</Typography>
                                            </Stack>
                                            <Typography>{clientData.contract_join_date} - {clientData.contract_end_date}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack flexDirection={'row'} gap={2}>
                                            <Stack flexDirection={'row'} justifyContent={'space-between'} width={{ xs: '80px', lg: '170px' }}>
                                                <Typography width={{ xs: '80px', lg: '170px' }} fontWeight={'700'}>Contract Duration</Typography>
                                                <Typography fontWeight={'700'}>:</Typography>
                                            </Stack>
                                            <Typography>{clientData.contract_year} Year {clientData.contract_month} Month {clientData.contract_day} Days</Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} display={'flex'} alignItems={'flex-end'}>
                                {/* <ChangePassword buttonName /> */}
                                <CommonButton buttonName='Change Password' fontSize='1rem' minWidth='40px' padding='4px 8px' onClick={() => handleOpen()} />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                    <Card className={classes.cardContainer}>
                        <Grid container padding={{ xs: 2, sm: 3 }} spacing={2}>
                            <Grid item xs={12}>
                                <Grid container alignItems={'center'}>
                                    <Grid item xs={12}>
                                        <Typography fontWeight={'700'} fontSize={'20px'}>Channel Details</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container alignItems={'center'} spacing={2}>
                                    <Grid item xs={5}>
                                        <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100px'}>
                                            <Typography fontWeight={'700'}>Facebook</Typography>
                                            <Typography fontWeight={'700'}>:</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={4} className='channel_button'>
                                        {clientData.facebook_account ? (
                                            <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                                                <CommonButton disabled tooltip titles='Your facebook account is linked' buttonName={<FaFacebookF />} borderRadius='50%' padding='10px' minWidth='unset' />
                                                <Typography sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}><div className='channel_connect_icon'></div>Connected</Typography>
                                            </Stack>
                                        ) : (
                                            <Stack flexDirection={'row'} gap={1} alignItems={'center'} onClick={() => localStorage.setItem('isReconnect', false)}>
                                                <a href={`https://www.facebook.com/v16.0/dialog/oauth?response_type=token&display=popup&client_id=1669158020199657&redirect_uri=https://dms.mobiginie.com/facebook&scope=manage_fundraisers,read_insights,pages_manage_cta,pages_show_list,ads_management,ads_read,business_management,instagram_basic,instagram_manage_insights,leads_retrieval,page_events,pages_read_engagement,pages_manage_metadata,pages_read_user_content,pages_manage_ads,pages_manage_posts,pages_manage_engagement&state=${clientData.id}`}>
                                                    <CommonButton tooltip titles='Click here to connect your facebook Account' buttonName={<FaFacebookF />} background='var(--gray)' borderRadius='50%' padding='10px' minWidth='unset' />
                                                </a>
                                                <Typography>Connect</Typography>
                                            </Stack>
                                        )}
                                    </Grid>
                                    <Grid item xs={3} className='channel_button'>
                                        {clientData.facebook_token_expired && (
                                            <Stack flexDirection={'row'} gap={1} alignItems={'center'} onClick={() => localStorage.setItem('isReconnect', true)}>
                                                <a href={`https://www.facebook.com/v16.0/dialog/oauth?response_type=token&display=popup&client_id=1669158020199657&redirect_uri=https://dms.mobiginie.com/facebook&scope=manage_fundraisers,read_insights,pages_manage_cta,pages_show_list,ads_management,ads_read,business_management,instagram_basic,instagram_manage_insights,leads_retrieval,page_events,pages_read_engagement,pages_manage_metadata,pages_read_user_content,pages_manage_ads,pages_manage_posts,pages_manage_engagement&state=${clientData.id}`}>
                                                    <CommonButton tooltip titles='Click here to re connect your facebook Account' buttonName={<FaFacebookF />} background='var(--gray)' borderRadius='50%' padding='10px' minWidth='unset' />
                                                </a>
                                                <Typography>Re Connect</Typography>
                                            </Stack>
                                        )}

                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container alignItems={'center'} spacing={2}>
                                    <Grid item xs={5}>
                                        <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100px'}>
                                            <Typography fontWeight={'700'}>Instagram</Typography>
                                            <Typography fontWeight={'700'}>:</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={7} className='channel_button'>
                                        {clientData.instagram_account ? (
                                            <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                                                <CommonButton disabled tooltip titles='Your Instagram account is linked' buttonName={<FaInstagram />} borderRadius='50%' padding='10px' minWidth='unset' />
                                                <Typography sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}><div className='channel_connect_icon'></div>Connected</Typography>
                                            </Stack>
                                        ) : (
                                            <Stack flexDirection={'row'} gap={1} alignItems={'center'} onClick={() => localStorage.setItem('isReconnect', false)}>
                                                <CommonButton tooltip titles='Click here to connect your Instagram Account' buttonName={<FaInstagram />} background='var(--gray)' borderRadius='50%' padding='10px' minWidth='unset' onClick={() => connectInstaAccount()} />
                                                <Typography>Connect</Typography>
                                            </Stack>
                                        )}

                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container alignItems={'center'} spacing={2}>
                                    <Grid item xs={5}>
                                        <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100px'}>
                                            <Typography fontWeight={'700'}>Google</Typography>
                                            <Typography fontWeight={'700'}>:</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={4} className='channel_button'>
                                        {clientData.google_account ? (
                                            <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                                                <CommonButton disabled tooltip titles='Your Google account is linked' buttonName={<FaGoogle />} borderRadius='50%' padding='10px' minWidth='unset' />
                                                <Typography sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}><div className='channel_connect_icon'></div>Connected</Typography>
                                            </Stack>
                                        ) : (
                                            <Stack flexDirection={'row'} gap={1} alignItems={'center'} onClick={() => localStorage.setItem('isReconnect', false)}>
                                                <a href={`https://accounts.google.com/o/oauth2/v2/auth?client_id=960456023020-47rd5n7kn9t6p56gpbnvnr657t57f55a.apps.googleusercontent.com&redirect_uri=https://dms.mobiginie.com/googlead&response_type=code&scope=https://www.googleapis.com/auth/adwords&access_type=offline&state=${clientData.id}&include_granted_scopes=true&prompt=consent`}>
                                                    <CommonButton tooltip titles='Click here to connect your Google Account' buttonName={<FaGoogle />} background='var(--gray)' borderRadius='50%' padding='10px' minWidth='unset' />
                                                </a>
                                                <Typography>Connect</Typography>
                                            </Stack>
                                        )}

                                    </Grid>
                                    <Grid item xs={3} className='channel_button'>
                                        {clientData.google_token_expired && (

                                            <Stack flexDirection={'row'} gap={1} alignItems={'center'} onClick={() => localStorage.setItem('isReconnect', true)}
                                            >
                                                <a href={`https://accounts.google.com/o/oauth2/v2/auth?client_id=960456023020-47rd5n7kn9t6p56gpbnvnr657t57f55a.apps.googleusercontent.com&redirect_uri=https://dms.mobiginie.com/googlead&response_type=code&scope=https://www.googleapis.com/auth/adwords&access_type=offline&state=${clientData.id}&include_granted_scopes=true&prompt=consent`}>
                                                    <CommonButton tooltip titles='Click here to connect your Google Account' buttonName={<FaGoogle />} background='var(--gray)' borderRadius='50%' padding='10px' minWidth='unset' />
                                                </a>
                                                <Typography>Re Connect</Typography>
                                            </Stack>
                                        )}

                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container alignItems={'center'} spacing={2}>
                                    <Grid item xs={5}>
                                        <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100px'}>
                                            <Typography fontWeight={'700'}>LinkedIn</Typography>
                                            <Typography fontWeight={'700'}>:</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={4} className='channel_button'>
                                        {clientData.linkedin_account ? (


                                            <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                                                <CommonButton disabled tooltip titles='Your LinkedIn account is linked' buttonName={<FaLinkedinIn />} borderRadius='50%' padding='10px' minWidth='unset' />
                                                <Typography sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}><div className='channel_connect_icon'></div>Connected</Typography>
                                            </Stack>
                                        ) : (
                                            <Stack flexDirection={'row'} gap={1} alignItems={'center'} onClick={() => localStorage.setItem('isReconnect', false)}>
                                                <a href={`https://www.linkedin.com/oauth/v2/authorization?client_id=86auhfczozsces&client_secret=y9UKwjhBe8Vb6ykk&redirect_uri=https://dms.mobiginie.com/linkedin&scope=r_ads_reporting,r_organization_social,rw_organization_admin,w_member_social,r_ads,w_organization_social,rw_ads,r_basicprofile,r_organization_admin,r_1st_connections_size&response_type=code&state=${clientData.id}`} >
                                                    <CommonButton tooltip titles='Click here to connect your LinkedIn Account' buttonName={<FaLinkedinIn />} background='var(--gray)' borderRadius='50%' padding='10px' minWidth='unset' />
                                                </a>
                                                <Typography>Connect</Typography>

                                            </Stack>
                                        )}

                                    </Grid>
                                    <Grid item xs={3} className='channel_button'>
                                        {clientData.linkedin_token_expired && (


                                            <Stack flexDirection={'row'} gap={1} alignItems={'center'} onClick={() => localStorage.setItem('isReconnect', true)}>
                                                <a href={`https://www.linkedin.com/oauth/v2/authorization?client_id=86auhfczozsces&client_secret=y9UKwjhBe8Vb6ykk&redirect_uri=https://dms.mobiginie.com/linkedin&scope=r_ads_reporting,r_organization_social,rw_organization_admin,w_member_social,r_ads,w_organization_social,rw_ads,r_basicprofile,r_organization_admin,r_1st_connections_size&response_type=code&state=${clientData.id}`} >
                                                    <CommonButton tooltip titles='Click here to re connect your LinkedIn Account' buttonName={<FaLinkedinIn />} background='var(--gray)' borderRadius='50%' padding='10px' minWidth='unset' />
                                                </a>
                                                <Typography>Re Connect</Typography>

                                            </Stack>
                                        )}

                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container alignItems={'center'} spacing={2}>
                                    <Grid item xs={5}>
                                        <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100px'}>
                                            <Typography fontWeight={'700'}>Tiktok</Typography>
                                            <Typography fontWeight={'700'}>:</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={4} className='channel_button'>
                                        {clientData.tiktok_account ? (
                                            <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                                                <CommonButton disabled tooltip titles='Your Tiktok account is linked' buttonName={<FaTiktok />} borderRadius='50%' padding='10px' minWidth='unset' />
                                                <Typography sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}><div className='channel_connect_icon'></div>Connected</Typography>
                                            </Stack>
                                        ) : (
                                            <Stack flexDirection={'row'} gap={1} alignItems={'center'} onClick={() => localStorage.setItem('isReconnect', false)}>
                                                <a href={`https://business-api.tiktok.com/portal/auth?app_id=7257044655584313346&redirect_uri=https%3A%2F%2Fdms.mobiginie.com%2Ftiktok&rid=m33xyaj1fp&state=${clientData.id}`} >
                                                    <CommonButton tooltip titles='Click here to connect your Tiktok Account' buttonName={<FaTiktok />} background='var(--gray)' borderRadius='50%' padding='10px' minWidth='unset' />
                                                </a>
                                                <Typography>Connect</Typography>
                                            </Stack>
                                        )}

                                    </Grid>
                                    <Grid item xs={3} className='channel_button'>
                                        {clientData.tiktok_token_expired && (

                                            <Stack flexDirection={'row'} gap={1} alignItems={'center'} onClick={() => localStorage.setItem('isReconnect', true)}>
                                                <a href={`https://business-api.tiktok.com/portal/auth?app_id=7257044655584313346&redirect_uri=https%3A%2F%2Fdms.mobiginie.com%2Ftiktok&rid=m33xyaj1fp&state=${clientData.id}`} >
                                                    <CommonButton tooltip titles='Click here to re connect your Tiktok Account' buttonName={<FaTiktok />} background='var(--gray)' borderRadius='50%' padding='10px' minWidth='unset' />
                                                </a>
                                                <Typography>Re Connect</Typography>
                                            </Stack>
                                        )}

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>


            {/* Change password model */}

            < Dialog open={openAdd} onClose={handleClose} sx={{ '& .MuiDialog-paper': { width: '500px' } }}>
                <DialogTitle>Change Password</DialogTitle>
                <ChangePassword handleClose={handleClose} changePassId={changePassId} setShow={setShow} setChangePassId={setChangePassId} />
            </Dialog >


            <Notification show={show} setShow={setShow} />
        </>

    )
}

export default ClientDetails